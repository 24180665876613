/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import store from "app/store";
import { hideToast } from "components/Toast/toast.slice";
import { InviteUserWrapper } from "./styled";

export interface IUser {
  id: number;
  name: string;
  email: string;
  tenant: string;
  client: string;
  accessRead: boolean;
  accessWrite: boolean;
}

const UIschema = yup.object().shape({
  name: yup.string().required("User Name is Required"),
  email: yup.string().required("Email is Required"),
  tenant: yup.string().required("Tenant Name is Required"),
  client: yup.string(),
});

const InviteUser = () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(UIschema),
  });
  const [userData, setUserData] = useState<IUser>({
    id: 0,
    name: "",
    email: "",
    client: "",
    tenant: "",
    accessRead: false,
    accessWrite: false,
  });
  const [readAccess, setReadAccess] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const [dropDownOne, setDropDownOne] = useState(false);
  const [dropDownTwo, setDropDownTwo] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleChange = (event: any) => {
    const { name, value, checked } = event.target;
    if (checked && event.target.id === "write") {
      setUserData({ ...userData, accessWrite: checked, accessRead: checked });
    } else if (
      checked !== undefined &&
      !checked &&
      event.target.id === "write"
    ) {
      setUserData({ ...userData, accessWrite: checked, accessRead: true });
    } else if (checked && event.target.id === "read") {
      setUserData({ ...userData, accessRead: checked });
    } else if (
      checked !== undefined &&
      !checked &&
      event.target.id === "read"
    ) {
      setUserData({ ...userData, accessRead: checked });
    } else {
      setUserData({ ...userData, [name]: value });
    }
  };

  const shouldEnableSubmitButton = (data: IUser) => {
    const { name, tenant, client, accessRead, accessWrite, email } = data;
    if (name && tenant && email && (accessRead || accessWrite)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (writeAccess) {
      setChecked(true);
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    if (!writeAccess && readAccess) {
      setChecked(false);
      setDisabled(false);
    }
    setButtonDisabled(shouldEnableSubmitButton(userData));
  }, [userData, writeAccess, readAccess, errors]);

  return (
    <InviteUserWrapper className="d-flex flex-column container">
      <div className="container mb-4 ml-4">
        <div className="offset-1 ml-4 d-flex flex-column flex-grow-1 pt-3 mt-4">
          <span className="invite-text col-md-8">Invite User</span>
          <form className=" col-md-8">
            <div className="form-group row required">
              <label htmlFor="emailid" className="col-md-4 col-form-label">
                Email ID
              </label>
              <div className="col-md-8">
                <input
                  placeholder=""
                  type="text"
                  className="form-control"
                  defaultValue=""
                  id="emailid"
                  name="email"
                  ref={register}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group row required">
              <label htmlFor="name" className="col-md-4 col-form-label">
                Name
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  defaultValue=""
                  ref={register}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="form-group row required">
              <label
                htmlFor="selectTenant"
                className="col-md-4 col-form-label aui-label"
              >
                Select Tenant
              </label>

              <div className="col-md-8">
                <select
                  className="form-control dropdown"
                  id="selectTenant"
                  defaultValue=""
                  ref={register}
                  onChange={handleChange}
                  onClick={() => {
                    setDropDownOne(!dropDownOne);
                  }}
                  onBlur={() => {
                    setDropDownOne(false);
                  }}
                  name="tenant"
                  required
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option>AHA</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                  <option>Option 4</option>
                </select>
                <i
                  className={
                    dropDownOne
                      ? "aha-icon-arrow-down forModal rotate"
                      : "aha-icon-arrow-down forModal"
                  }
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="selectClient" className="col-md-4 col-form-label">
                Select Client
              </label>
              <div className="col-md-8  ">
                <select
                  className="form-control dropdown"
                  defaultValue=""
                  ref={register}
                  onChange={handleChange}
                  onClick={() => {
                    setDropDownTwo(!dropDownTwo);
                  }}
                  onBlur={() => {
                    setDropDownTwo(false);
                  }}
                  name="client"
                  id="selectClient"
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option>AHA</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                  <option>Option 4</option>
                </select>
                <i
                  className={
                    dropDownTwo
                      ? "aha-icon-arrow-down forModal rotate"
                      : "aha-icon-arrow-down forModal"
                  }
                />
              </div>
            </div>
            <div className="form-group row required">
              <label htmlFor="access" className="col-md-4 col-form-label">
                Access
              </label>
              <div className="col-md-4 d-flex justify-content-left pt-2 ">
                <div className="form-group form-check pr-4">
                  <input
                    className="input-disabled-bg"
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={(e) => {
                      handleChange(e);
                      setDisabled(false);
                      setChecked(false);
                      setReadAccess(false);
                      writeAccess && setDisabled(e.target.checked);
                      setChecked(e.target.checked);
                    }}
                    id="read"
                    ref={register}
                    name="accessRead"
                  />
                  <label htmlFor="read">Read</label>
                </div>
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setWriteAccess(e.target.checked);
                      handleChange(e);
                    }}
                    ref={register}
                    id="write"
                    name="accessWrite"
                  />
                  <label htmlFor="write">Write</label>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-sm btn-round btn-secondary mr-4 form-btn ml-auto "
              >
                Cancel
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="btn btn-sm btn-round btn-primary form-btn"
              >
                Invite
              </button>
            </div>
          </form>
        </div>
      </div>
    </InviteUserWrapper>
  );
};

export default InviteUser;
