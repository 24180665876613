/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "styles/global.scss";
import Routes from "routes";
import PermissionProvider from "services/PermissionManager/PermissionProvider";
import { fetchPermission } from "services/PermissionManager/Types";
import { defineCustomElements } from "@american-heart/aui/loader";
import "../registerWebComponents";

function App() {
  //  Defines all the web component to the global window object
  defineCustomElements(window);
  return (
    <PermissionProvider fetchPermission={fetchPermission()}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </PermissionProvider>
  );
}

export default App;
