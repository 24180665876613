import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "app/useAppSelector";
import { getAhaSsoToken } from "app/hooks/auth/auth.slice";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import {
  getErrorMessage,
  setSideMenuToggleStatus,
  signOut,
} from "common/utils";
import { adhLogin } from "app/hooks/auth/authManager";
import {
  accountNavData,
  ecoSystemNavData,
  userDetails,
} from "common/UIMockData/snapshotMockData";
import { HeaderWrapper } from "./styled";
import { setRoleSwitchObj } from "./header.slice";

interface ISnapshotComp {
  userDetails: {
    userName: string;
  };
  ecoSystemNavData: {
    items: Array<string>;
  };
  accountNavigationData: any;
}

const SnapshotHeader = ({
  showMyAccountMenu = true,
  buttonClick,
  setButtonClick,
}: any) => {
  const ahaSsoUserState = useSelector((state: any) => {
    return state.user.ahaSsoUser;
  });

  const [hovered, setHovered] = useState(false);
  const isViewRole = (roleName: string) =>
    ["Super User", "End User"].includes(roleName);

  const [userName, setUserName] = useState("");

  const ahaSsoToken = useAppSelector(getAhaSsoToken) as any;

  const toggleCloseIcon = (evt: any) => {
    const currCls = evt.currentTarget.firstElementChild.classList[0];
    if (currCls === "aha-icon-cross") {
      evt.currentTarget.firstElementChild.classList.remove("aha-icon-cross");
      evt.currentTarget.firstElementChild.classList.add("aha-icon-hamburger");
    } else {
      evt.currentTarget.firstElementChild.classList.remove(
        "aha-icon-hamburger"
      );
      evt.currentTarget.firstElementChild.classList.add("aha-icon-cross");
    }
  };
  const stateObject = useSelector((state: any) => {
    let roleName;
    let loggedInUserState;
    if (state?.user?.user) {
      roleName = state.user.user.roleName;
      loggedInUserState = state.user.user;
    }
    return {
      headerText: state.header.headerTitle,
      roleName,
      loggedInUserState,
    };
  });

  let prevScrollPos = window.pageYOffset;

  const { headerText, roleName, loggedInUserState = {} } = stateObject;

  const { roleName: roleNameLoggedInUser, roleCode: roleCodeLoggedInUser } =
    loggedInUserState;
  const { otherRoles } = loggedInUserState;

  const dropDownToggler = (e: any) => {
    e.currentTarget.nextSibling.classList.toggle("rotate");
  };

  const history = useHistory();
  const reduxRoleSwitchObj = useSelector((state: any) => {
    return state.header.roleSwitchObj;
  });

  const mapDynamicPropsWebComp = (firstName: string, lastName: string) => {
    const snapcomponent: ISnapshotComp =
      document.querySelector<any>("snapshot-comp");
    snapcomponent.userDetails = {
      userName: `${firstName} ${lastName}`,
    };
    /* snapcomponent.ecoSystemNavData = ecoSystemNavData; */
    snapcomponent.accountNavigationData = accountNavData;
  };

  const snapshotAccNavEventHandler = (e: any) => {
    e.preventDefault();

    const navigateRoute = e.detail;
    if (navigateRoute === "/ssologout") {
      signOut();
    } else {
      history.push(navigateRoute);
    }
  };

  useEffect(() => {
    document.addEventListener(
      "accountNavigationLinkAction",
      snapshotAccNavEventHandler,
      { once: true } // to ensure event listener calls once and avoid multiple history.push
    );
    return () =>
      window.removeEventListener(
        "accountNavigationLinkAction",
        snapshotAccNavEventHandler
      );
  }, []);

  const generateRoleName = (otherRoleObj: any) => {
    const { clientName } = otherRoleObj;
    if (clientName?.trim() === "") {
      return "";
    }

    if (clientName?.trim() !== "") return `${clientName} -`;

    return null;
  };

  const roleSwitchHandler = (selectedRoleFromDropdown: any, demoVal: any) => {
    /*  dispatch(setPageLoadingStatus({ isPageLoading: true })); */
    const [roleCode, clientId, roleNameSelected, clientName] =
      selectedRoleFromDropdown.split("/");
    adhLogin(ahaSsoToken, roleCode, clientId)
      .then((response: any) => {
        if (response) {
          store.dispatch(
            showToast({
              type: "success",
              title: " ",
              message: `Successfully Changed Role `,
            })
          );

          store.dispatch(
            setRoleSwitchObj({
              roleSwitchObj: {
                isRoleSwitchDone: true,
                roleSwitchDetails: {
                  selectedRoleCode: roleCode,
                  selectedRoleClientId: clientId,
                  selectedRoleName: roleNameSelected,
                  selectedRoleClientName: clientName,
                },
              },
            })
          );

          history.push("/dashboard");
        }
      })
      .catch((error) => {
        /* dispatch(setPageLoadingStatus({ isPageLoading: false })); */
        store.dispatch(
          showToast({
            title: "Error Occured.",
            message: getErrorMessage(error),
          })
        );
        store.dispatch(
          setRoleSwitchObj({
            // reset roleSwitch obj in redux store in case of any error
            roleSwitchObj: {
              isRoleSwitchDone: false,
              roleSwitchDetails: {
                selectedRoleCode: "",
                selectedRoleClientId: "",
                selectedRoleName: "",
                selectedRoleClientName: "",
              },
            },
          })
        );
      })
      .finally(() => {});
  };

  const dropDownDefaultState = (e: any) => {
    if (e.currentTarget.nextSibling.classList.contains("rotate") === true) {
      e.currentTarget.nextSibling.classList.remove("rotate");
    }
  };

  const generateUserRoleSwitchDropdown = () => {
    // No Role Switch Dropdown for super admin
    if (
      roleCodeLoggedInUser === "SUPER_ADMIN" ||
      typeof otherRoles === "undefined"
    )
      return (
        <li className="d-flex align-items-center nav-item-roleName">
          {roleName && isViewRole(roleName) ? (
            <>
              <i className="aha-icon-no-edit-pencil mr-2 edit-icon" />
              <p className="tooltiptext mb-0">View only</p>
              <p className="mt-0 mb-0">{roleName}</p>
            </>
          ) : (
            <p className="mt-0 mb-0 user">{roleName}</p>
          )}
        </li>
      );
    return (
      <li className="d-flex align-items-center nav-item-roleName roleSwitcherWidth">
        {roleName && isViewRole(roleName) ? (
          <>
            <i className="aha-icon-no-edit-pencil mr-2 edit-icon" />
            <p className="tooltiptext mb-0">View only</p>
          </>
        ) : null}
        <select
          className="form-control"
          aria-label="role Switch Dropdown"
          id="roleSwitchDropdown"
          defaultValue="selected"
          onChange={(e) =>
            roleSwitchHandler(
              e.target.value,
              e.target.getAttribute("data-name")
            )
          }
          onClick={(e) => {
            dropDownToggler(e);
          }}
          onBlur={(e) => {
            dropDownDefaultState(e);
          }}
        >
          <option value="selected" disabled>
            {reduxRoleSwitchObj?.isRoleSwitchDone
              ? `${generateRoleName({
                  clientName:
                    reduxRoleSwitchObj.roleSwitchDetails.selectedRoleClientName,
                })}
                 ${reduxRoleSwitchObj.roleSwitchDetails.selectedRoleName}`
              : roleNameLoggedInUser}
          </option>
          {otherRoles?.map((item: any) => {
            return (
              <option
                key={Math.random()}
                value={`${item.roleCode}/${item.clientId}/${item.roleName}/${item.clientName}`}
              >
                {generateRoleName(item)}
                {item.roleName}
              </option>
            );
          })}
        </select>
        <i className="aha-icon-arrow-down forModal" />
      </li>
    );
  };

  const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
  useEffect(() => {
    const el = document.getElementsByClassName("header-fixed");
    window.addEventListener("scroll", () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPos > currentScrollPos) {
        Array.from(el).forEach((item) => {
          item.classList.remove("top-70");
          item.classList.add("top-0");
        });
      } else {
        Array.from(el).forEach((item) => {
          item.classList.remove("top-0");
          item.classList.add("top-70");
        });
      }
      prevScrollPos = currentScrollPos;
    });
  }, [prevScrollPos]);

  useEffect(() => {
    if (ahaSsoUserState) {
      const { firstName, lastName } = ahaSsoUserState;

      if (firstName && lastName) {
        setUserName(`${firstName} ${lastName}`);
        mapDynamicPropsWebComp(firstName, lastName);
      }
    }
  }, []);

  return (
    <HeaderWrapper
      id="aha-header"
      className="aui-main-header aui-pri-header header-fixed pb-2 pt-2"
    >
      <nav
        aria-label="TopHeaderMenu"
        className="navbar navbar-expand-lg justify-content-between mx-auto aui-header-content aui-pri-header-t"
      >
        <button
          className="navbar-toggler ml-2 px-0 signout-link"
          type="button"
          aria-controls="toggleNav"
          aria-expanded={buttonClick}
          aria-label="Toggle Navigation"
          onClick={(event) => {
            setButtonClick(!buttonClick);
            setSideMenuToggleStatus(!buttonClick);
          }}
        >
          <i className="aha-icon-hamburger" />
        </button>

        {window.outerWidth < 992 && showMyAccountMenu && (
          <div className="d-flex pl-4">
            <span className="AHAlogo">
              <img
                aria-label="A H A logo"
                src="/images/Icon-AHA-logo-icon.svg"
                alt="A H A Logo"
                className="headerAHALogoMob"
              />
            </span>
          </div>
        )}

        {/*  {isHTML(headerText)
          ? parse(`${headerText}`)
          : parse(`<p className="header-title">${headerText}</p>`)} */}

        {/* custom element */}

        {showMyAccountMenu && (
          <div
            className="justify-content-lg-end collapse navbar-collapse aui-pri-nav"
            id="toggleNav"
          >
            <ul className="navbar-nav username-spacing flex-lg-row flex-column">
              {generateUserRoleSwitchDropdown()}

              <li className="d-flex">{/* <snapshot-comp /> */}</li>
            </ul>
          </div>
        )}

        {!showMyAccountMenu && (
          <>
            <div className="d-flex pl-4">
              <span className="AHAlogo">
                <img
                  className="logo-image"
                  src="../images/aha-logo.svg"
                  alt="AHA home"
                />
              </span>
            </div>
            <div className="d-flex flex-column p-3">
              <div
                onClick={signOut}
                className="dropdown-item "
                role="button"
                onKeyUp={signOut}
                tabIndex={0}
                aria-label="Sign Out"
              >
                <i className="aha-icon-logout" /> Sign Out
              </div>
            </div>
          </>
        )}
      </nav>
    </HeaderWrapper>
  );
};
export default SnapshotHeader;
