import styled from "styled-components";

export const ViewClientStyles = styled.div`
  .editbtn,
  .deactivatebtn,
  .deletebtn {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    font-family: Montserrat;
    color: #222328;
    background: none;
    border: none;
    text-decoration: underline;
  }
  .pr-custom-5 {
    padding-right: 1.5rem;
    @media screen and (min-width: 320px) and (max-width: 575px) {
      padding-right: 0;
    }
  }
  .p-custom-5 {
    padding: 40px;
  }
  .modalwidth {
    width: 583px;
  }
  .modal-dialog.editclient-modal.modalwidth {
    @media screen and (min-width: 992px) {
      top: 45%;
      transform: translateY(-50%);
    }
  }
  .btnGrp-editTanant {
    justify-content: flex-end;
    @media screen and (min-width: 576px) and (max-width: 767px) {
      justify-content: center;
    }
    @media screen and (min-width: 320px) and (max-width: 575px) {
      flex-grow: 1;
      align-items: flex-end;
      .btn-secondary {
        display: none;
      }
      .btn-primary {
        width: 100%;
      }
    }
  }
  .cnf-btn-non-focus {
    box-shadow: none !important;
    outline: none !important;
  }
  modal-backdrop-hide {
    opacity: 1;
  }
  /* [aria-label="Edit Client"]
    .modal-dialog.modalwidth.editclient-modal
    .modal-content {
    height: inherit;
  } */
  .modal.show .modal-dialog {
    @media screen and (min-width: 320px) and (max-width: 575px) {
      max-width: 100vw;
      align-items: flex-start;
      min-height: 100%;
      margin: 0px;
    }
  }
  .contents {
    justify-content: flex-end;
  }
  .vertical-spacing {
    margin-bottom: 0rem;
  }
  .modal-title {
    font-size: 18px;
    @media screen and (min-width: 320px) and (max-width: 576px) {
      font-size: 16px;
      padding-left: 10px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 575px) {
    .height-68 {
      height: 90vh !important;
    }
    .details-text {
      font-size: 14px !important;
      line-height: 23px !important;
    }
    .editbtn {
      font-size: 14px !important;
    }
    .label-text {
      font-size: 14px !important;
      line-height: 27px !important;
    }
    .contents {
      justify-content: space-between;
    }
    .vertical-spacing {
      margin-bottom: 0.5rem;
    }
    .client-heading {
      font-size: 22px !important;
      line-height: 27px !important;
      padding-bottom: 0.625rem !important;
      padding-left: 0.9375rem !important;
    }
    .modal.show .modal-dialog .modal-content {
      border: 0px;
    }
    .rotateInverse {
      transform: rotateZ(-180deg);
    }
    .btnGrp-editTanant .btn-primary {
      position: fixed;
      bottom: 0rem;
      right: 0;
      margin: 12px 15px;
      width: 92%;
      border-radius: 50px;
      font-size: 16px;
      line-height: 1.5;
      padding: 0.5rem 1.375rem;
    }
    .btn-background {
      height: 66px;
      background: #fff;
      position: fixed;
      bottom: 0rem;
      width: 100%;
      right: 0rem;
      box-shadow: 0px -4px 6px #00000029;
    }
    .show-colon {
      display: none !important;
    }
  }
  .show-colon {
    display: inline-flex;
  }

  .line {
    border-bottom: 1px solid #bcc3ca;
  }

  .client-heading {
    font-size: 30px;
    line-height: 37px;
    padding-bottom: 0.75rem;
    font-weight: 400;
  }
  .primary-color {
    color: #c10e21;
  }
  @media screen and (min-width: 758px) and (max-width: 991px) {
    .pl-lg-20 {
      padding-left: 10px !important;
    }
  }
  @media screen and (min-width: 576px) and (max-width: 758px) {
    .client-heading {
      font-size: 22px !important;
      line-height: 27px !important;
    }
    .details-text {
      font-size: 14px !important;
      line-height: 23px !important;
    }
    .editbtn {
      font-size: 14px !important;
    }
    .height-68 {
      height: 52vh !important;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .client-heading {
      font-size: 24px !important;
      line-height: 29px !important;
    }
    .details-text {
      font-size: 16px !important;
      line-height: 25px !important;
    }
    .height-68 {
      height: 67 vh !important;
    }
  }
  .details-text {
    font-size: 16px;
    line-height: 27px;
    word-break: break-all;
  }
  .label-text {
    font-size: 16px;
    line-height: 27px;
  }
  .form-error-msg {
    color: #c10e21;
    font-weight: 500;
    font-size: 15px;
    margin-top: 5px;
  }
  .editbtn {
    font-size: 16px;
  }
  @media screen and (min-width: 992px) {
    .pl-lg-20 {
      padding-left: 20px !important;
    }
  }
  .height-68 {
    height: 73.7vh;
  }
  .editclient-modal .modal-content {
    @media screen and (min-width: 320px) and (max-width: 575px) {
      height: 100vh;
    }
  }

  .aui-modal.show {
    display: inline-block;
  }
  .editbtn span:hover {
    color: #c10e21 !important;
  }
  .instruction {
    color: #555;
    font-size: 14px;
    font-weight: 500;
  }
  @media screen and (max-width: 575px) {
    .instruction {
      font-size: 12px;
      margin-bottom: 0px;
      margin-top: 1rem;
    }
  }
  .close {
    position: absolute;
    right: -4px;
    top: -5px;
  }
  .close:not(:disabled):not(.disabled):hover,
  .close:not(:disabled):not(.disabled):focus {
    opacity: 1;
  }

  .btn.btn-primary:disabled {
    color: #fff;
    background-color: #aaaaaa;
    border-color: #aaaaaa;
  }
`;
