import styled from "styled-components";

const EmailDetailsWrapper = styled.div`
  .min-height {
    min-height: 50vh;
    @media screen and (min-width: 576px) and (max-width: 819px) {
      min-height: 60vh;
    }
    @media screen and (min-width: 820px) and (max-width: 991px) {
      min-height: 70vh;
    }
  }
  .line {
    border-bottom: 1px solid #bcc3ca;
  }
  .contents {
    justify-content: flex-end;
  }
  .pr-custom-5 {
    padding-right: 1.5rem;
  }
  .editbtn,
  .deletebtn {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    font-family: Montserrat;
    color: #222328;
    background: none;
    border: none;
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }

  .detailValue {
    display: inline-block !important;
    width: calc(100% - 15px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media screen and (min-width: 320px) and (max-width: 575px) {
    .show-colon {
      display: none !important;
    }
    .label-text {
      font-size: 14px !important;
      line-height: 27px !important;
    }
    .editbtn {
      font-size: 14px !important;
    }
  }

  @media screen and (min-width: 768px) {
    .padLeftStyles {
      padding-left: 1.25rem;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .vertical-spacing.label-text {
      flex: 0 0 24.66667%;
      max-width: 24.66667%;
    }
  }
`;

export default EmailDetailsWrapper;
