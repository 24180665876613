import styled from "styled-components";

export const InviteUserWrapper = styled.div`
  option[value=""][disabled] {
    display: none;
  }
  .aui-search-dropdown {
    position: relative;
    min-width: 6.25rem;
    max-width: 100%;
  }
  input,
  select {
    border: 1px solid #bcc3ca !important;
  }
  .invite-text {
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0;
    text-align: left;
    color: #222328;
    font-weight: 600;
    font-family: Montserrat;
    padding-bottom: 1.875rem;
  }
  .form-btn {
    border-radius: 50px;
    border: 2px solid #b1292a;
  }
  .btn.btn-primary:disabled {
    box-shadow: none;
    background-color: #aaaaaa;
    border: 2px solid #aaaaaa;
    color: #ffffff !important;
  }
  .btn {
    font-size: 16px;
    line-height: 19px;
    padding: 12px 28px;
  }

  label {
    line-height: 27px;
    pointer-events: none;
  }
  .form-check input[type="checkbox"]:checked + label:before {
    background-color: #fff;
    border-color: #c10e21;
  }
  .form-check input[type="checkbox"]:disabled + label:before {
    border-color: #707070;
    background-color: #fff;
  }

  .form-check input[type="checkbox"]:disabled + label:after {
    border-color: #707070;
    background-color: #fff;
  }
  .form-check label:after {
    border: 2px solid #c10e21;
    border-top: none;
    border-right: none;
    content: "";
    height: 0.375rem;
    opacity: 0;
    position: absolute;
    top: 0.375rem;
    left: 0.3125rem;
    transform: rotate(-45deg);
    width: 0.75rem;
  }
  select + i {
    float: right;
    margin-top: -26px;
    position: relative;
    margin-right: 7px;
    pointer-events: none;
    background-color: #fff;
    padding-right: 5px;
  }
  .forModal {
    font-size: 45%;
  }
  select.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: none;
    background-repeat: no-repeat;
  }
  .rotate {
    transform: rotate(180deg);
  }
`;
