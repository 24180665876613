/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import CONSTANTS from "common/constants";
import NoDataComp from "components/NoData";
import Pagination from "components/Pagination";
import React, { useEffect, useState } from "react";
import { ListServiceProviderEmailAPI } from "services/api/notification.api";
import PaginationSettings from "../List/PaginationSettings";
import ServiceProviderWrapper from "./styled";
import { defaultValues, filtersLabel } from "../List/utils";
import { notificationTypebasedOnId } from "../utils";

const tableHeaderNames = [
  { name: "Service Providers", id: 1 },
  { name: "Type", id: 2 },
];

const ServiceProvider: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [type, setType] = useState<string>("SMS,EMAIL,HTTP");
  const [pageSize, setPageSize] = useState<number>(20);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [prevPageSize, setPrevPageSize] = useState<number>(20);
  const [pagination, setPagination] = useState(defaultValues);
  const [serviceProviders, setServiceProviders] = useState<any>([]);
  const onChangePageNumber = (pageNo: number) => {
    document.querySelector<any>(".page-sort")?.classList.remove("visible_cls");
    document
      .querySelector<any>(".keydown-recordfilter")
      ?.classList.remove("rotateArrow");
    setPageNumber(pageNo);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    if (pageSize !== prevPageSize) {
      setPrevPageSize(pageSize);
      setPageNumber(1);
    }
  }, [pageSize]);

  useEffect(() => {
    setLoading(true);

    ListServiceProviderEmailAPI({
      pageSize,
      pageNumber,
    })
      .then((response: any) => {
        if (response && response.data.serviceProviders === null) {
          setLoading(false);
          setPagination(defaultValues);
          setServiceProviders(response.data.serviceProviders);
        } else {
          setLoading(false);
          setServiceProviders(response.data.serviceProviders);
          setPagination({ ...response._pagination });
        }
      })
      .catch();
  }, [prevPageSize, pageNumber, type, refresh]);

  useEffect(() => {
    filtersLabel.forEach((item: any) => {
      item.isSelected = false;
    });
  }, []);

  return (
    <ServiceProviderWrapper>
      <div className="container">
        <h1
          data-testid="test-heading"
          className="notifications pt-3 pb-3 d-block"
        >
          Service Providers
        </h1>
        <div className="mt-3 mb-4 pt-2 pb-2 showItemsSpacing">
          <PaginationSettings
            setPageSize={setPageSize}
            pageSize={pageSize}
            totalCounts={pagination.totalCount}
          />
        </div>

        <div />
        {loading ? (
          <table
            className="aui-responsive-table aui-table-cols aui-table-loader"
            role="alert"
            aria-live="assertive"
            aria-label="Table is Loading"
          />
        ) : (
          <>
            <table className="aui-responsive-status-table">
              {serviceProviders.length !== 0 ? (
                <thead>
                  <tr>
                    {tableHeaderNames &&
                      tableHeaderNames.map((colHeader) => {
                        return (
                          <th key={colHeader.id} scope="col">
                            <div className="aui-th">{colHeader.name}</div>
                          </th>
                        );
                      })}
                  </tr>
                </thead>
              ) : null}

              <tbody>
                {serviceProviders &&
                  serviceProviders.map((serviceProvider: any) => (
                    <tr
                      className="aui-table-status-normal"
                      key={serviceProvider.id}
                    >
                      <td data-title="Service Providers">
                        <div className="aui-td">
                          <p className="mb-0 name-col">
                            {serviceProvider.name}
                          </p>
                        </div>
                      </td>
                      <td data-title="Type">
                        <div className="aui-td">
                          <ul className="d-flex type-col p-0 m-0">
                            <li className="type-col disabled-icon">
                              {notificationTypebasedOnId(
                                serviceProvider.notificationTypeId
                              )}
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="row mt-3 mt-sm-5 justify-content-end">
              <Pagination
                {...pagination}
                setPageNumber={onChangePageNumber}
                paginationSetLimit={CONSTANTS.PAGINATION_LIMIT}
              />
            </div>
          </>
        )}
        {serviceProviders.length === 0 && !loading ? (
          <NoDataComp DataList={serviceProviders} />
        ) : (
          ""
        )}
      </div>
    </ServiceProviderWrapper>
  );
};

export default ServiceProvider;
