/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  addEmailConfigService,
  GetServiceProviderEmailAPI,
  patchEmailConfigService,
} from "services/api/notification.api";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import PermissionContext from "services/PermissionManager/PermissionContext";
import DynamicForm from "components/DynamicForm";
import { capitalizeFirstLetter } from "common/utils";
import CONSTANTS from "common/constants";
import EmailConfigureWrapper from "./styled";
import { getConfigPayload, getServiceProvider } from "./utils";

export interface IEmailConfig {
  provider: string;
  domain: string;
  privateKey: string;
}

const ServiceProviderConfiguration: React.FunctionComponent<any> = ({
  setTabViewState,
  tabView,
  apiStatus,
  setApiStatus,
  GetClientConfigEmail,
  emailClientConfig,
  setLoading,
}: any) => {
  const [showInputFields, setshowInputFields] = useState<boolean>(false);

  const [dropDownOne, setDropDownOne] = useState(false);
  const [dynamicFormModel, setDynamicFormModel] = useState<any>([]);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [disabledButton, setDisabledButton] = useState(true);

  const [dynamicFormData, setDynamicFormData] = useState<any>();

  const { register, handleSubmit, errors, reset } = useForm();

  const shouldEnableSubmitButton = (data: IEmailConfig) => {
    const { provider, domain, privateKey } = data;
    if (provider && domain && privateKey) {
      return false;
    }
    return true;
  };
  const [emailconfigData, setEmailconfigData] = useState<IEmailConfig>({
    provider: "",
    domain: "",
    privateKey: "",
  });

  const [emailServiceProviderData, setEmailServiceProviderData] = useState<any>(
    []
  );
  const { loggedInUserObjectContext } = useContext(PermissionContext);

  const submitForm = () => {
    const CreateServProviderVal = document.querySelector<any>(
      "#createServiceProvider"
    ).value;

    const formDataApiCall = {
      clientId: parseInt(loggedInUserObjectContext.clientId, 10),
      config: { ...dynamicFormData },
      serviceProviderId: Number(CreateServProviderVal),
    };
    setLoading(true);
    addEmailConfigService(formDataApiCall)
      .then((response: any) => {
        if (response.status === 200) {
          store.dispatch(
            showToast({
              type: "success",
              title: "Success",
              message: "Email Configured Successfully",
            })
          );
          setApiStatus("config");
        }
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            title: "Error Occured.",
            message: error.response.data.error.message,
          })
        );
        setTabViewState(tabView.configView);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const submitEditForm = (formData: any) => {
    const { id } = emailClientConfig[0];
    const servProviderVal =
      document.querySelector<any>("#servideProvider").value;

    const formDataApiCall = {
      clientId: parseInt(loggedInUserObjectContext.clientId, 10),
      config: formData,
      serviceProviderId: Number(servProviderVal),
    };
    setLoading(true);
    patchEmailConfigService(id, formDataApiCall)
      .then((response: any) => {
        if (response.status === 200) {
          store.dispatch(
            showToast({
              type: "success",
              title: "Success",
              message: "Email Configured Successfully",
            })
          );
        }
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            title: "Error Occured.",
            message: error.response.data.error.message,
          })
        );
      })
      .finally(() => {
        setApiStatus("config");
        setLoading(false);
      });
  };

  const GetServiceProviderEmail = () => {
    GetServiceProviderEmailAPI()
      .then((response: any) => {
        if (response && response.data?.serviceProviders === null) {
          setEmailServiceProviderData([]);
        } else {
          setEmailServiceProviderData(response.data.serviceProviders);
        }
        return { ...response._pagination };
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occured",
            message: error?.response?.data?.error?.message,
          })
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    setButtonDisabled(shouldEnableSubmitButton(emailconfigData));
    apiStatus === "edit" &&
      (document.querySelector<any>("#servideProvider").value =
        emailClientConfig[0]?.serviceProviderId);
  }, [emailconfigData]);

  useEffect(() => {
    // api Call for Service Provider
    GetServiceProviderEmail();
  }, []);

  useEffect(() => {
    if (apiStatus === "edit") {
      GetClientConfigEmail();
      setEmailconfigData({
        ...emailconfigData,
        provider: emailClientConfig[0]?.config.provider,
        domain: emailClientConfig[0]?.config.domain,
        privateKey: emailClientConfig[0]?.config.privateKey,
      });
    }
  }, [apiStatus]);

  const [prependLabelValue, setPrependLabelValue] = useState<string>("");
  const [editSelect, setEditSelect] = useState(
    emailClientConfig && emailClientConfig[0]?.serviceProviderId
  );
  const [createSelect, setCreateSelect] = useState("");

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "provider" && value !== "") {
      setDynamicFormModel(
        emailServiceProviderData.filter(
          (item: any) => item.id === Number(value)
        )[0]
      );
      setPrependLabelValue(event.target.selectedOptions[0].text);
      setshowInputFields(true);
      if (event.target.id === "createServiceProvider") {
        setCreateSelect(value);
      } else {
        setEditSelect(value);
      }
    }
    setEmailconfigData({ ...emailconfigData, [name]: value });
  };

  const onChangeForm = (data: any) => {
    let valueFlag = true;
    data.forEach((item: any) => {
      if (!item.value && item.props.required) {
        valueFlag = false;
      }
    });
    if (valueFlag) {
      setDisabledButton(false);
    }
    if (!valueFlag) {
      setDisabledButton(true);
    }
    setDynamicFormData(getConfigPayload(data, prependLabelValue));
  };

  return (
    <EmailConfigureWrapper className="mb-sm-9 pb-sm-4">
      <div className="d-flex align-items-center justify-content-start alignContent mt-5">
        <div className="col-md-12 col-lg-8">
          <p className="instruction">{CONSTANTS.MODAL_INSTRUCTION_TEXT}</p>
          {apiStatus === "post" && (
            // For Post component
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="form-group row required">
                <label
                  data-testid="service-provider-label"
                  htmlFor="createServiceProvider"
                  className="col-md-4 col-form-label aui-label"
                >
                  Service Provider
                </label>

                <div className="col-md-8">
                  <select
                    className="form-control dropdown"
                    id="createServiceProvider"
                    value={createSelect}
                    onChange={handleChange}
                    onClick={() => {
                      setDropDownOne(!dropDownOne);
                    }}
                    onBlur={() => {
                      setDropDownOne(false);
                    }}
                    name="provider"
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>

                    {emailServiceProviderData.map((item: any) => {
                      return (
                        <option
                          key={`createConfig_${item.code}_${item.id}`}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <i
                    className={
                      dropDownOne
                        ? "aha-icon-arrow-down forModal rotate"
                        : "aha-icon-arrow-down forModal"
                    }
                  />
                </div>
              </div>
              {showInputFields &&
              dynamicFormModel &&
              dynamicFormModel.jsonForm ? (
                <DynamicForm
                  model={dynamicFormModel.jsonForm}
                  onChange={onChangeForm}
                />
              ) : null}

              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-round btn-secondary mr-4 btnwidth cancel-btn"
                  onClick={() => {
                    setTabViewState(tabView.NC);
                    setApiStatus("");
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={disabledButton}
                  type="submit"
                  className="btn btn-round btn-primary btnwidth save-btn"
                >
                  Save
                </button>
              </div>
            </form>
          )}
          {apiStatus === "edit" && (
            // For Edit Component
            <form onSubmit={handleSubmit(submitEditForm)}>
              <div className="form-group row required">
                <label
                  htmlFor="servideProvider"
                  className="col-md-4 col-form-label aui-label"
                  aria-label="Service Provider"
                >
                  Service Provider
                </label>

                <div className="col-md-8">
                  <select
                    className="form-control dropdown"
                    id="servideProvider"
                    value={editSelect}
                    onChange={handleChange}
                    onClick={() => {
                      setDropDownOne(!dropDownOne);
                    }}
                    onBlur={() => {
                      setDropDownOne(false);
                    }}
                    name="provider"
                    aria-required="true"
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>

                    {emailServiceProviderData.map((item: any) => {
                      return (
                        <option
                          key={`editConfig_${item.code}_${item.id}`}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <i
                    className={
                      dropDownOne
                        ? "aha-icon-arrow-down forModal rotate"
                        : "aha-icon-arrow-down forModal"
                    }
                  />
                </div>
              </div>
              <div className="form-group row required">
                <label
                  htmlFor="domain"
                  className="col-md-4 col-form-label"
                  aria-label={`${capitalizeFirstLetter(
                    emailClientConfig[0]?.serviceProviderName
                  )} Domain`}
                >
                  {`${capitalizeFirstLetter(
                    emailClientConfig[0]?.serviceProviderName
                  )} `}
                  Domain
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    name="domain"
                    id="domain"
                    className="form-control"
                    defaultValue={emailClientConfig[0]?.config.domain}
                    ref={register}
                    onChange={handleChange}
                    aria-required="true"
                    required
                  />
                </div>
              </div>
              <div className="form-group row required">
                <label
                  htmlFor="privateKey"
                  className="col-md-4 col-form-label"
                  aria-label={`${capitalizeFirstLetter(
                    emailClientConfig[0]?.serviceProviderName
                  )} API Key`}
                >
                  {`${capitalizeFirstLetter(
                    emailClientConfig[0]?.serviceProviderName
                  )} `}
                  API Key
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    name="privateKey"
                    id="privateKey"
                    className="form-control"
                    defaultValue={emailClientConfig[0]?.config.privateKey}
                    ref={register}
                    onChange={handleChange}
                    aria-required="true"
                    required
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-round btn-secondary mr-4 btnwidth cancel-btn"
                  onClick={() => {
                    setTabViewState(tabView.detailsView);
                    setApiStatus("");
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={buttonDisabled}
                  type="submit"
                  className="btn btn-round btn-primary btnwidth save-btn"
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </EmailConfigureWrapper>
  );
};

export default ServiceProviderConfiguration;
