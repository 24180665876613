import store from "app/store";
import {
  getErrorMessage,
  getMainIcon,
  capitalizeFirstLetter,
} from "common/utils";

import Modal from "components/Modal";
import { showToast } from "components/Toast/toast.slice";
import {
  getMessage,
  getStatusAction,
  updateModalContent,
} from "pages/Notifications/utils";
import React, { useState } from "react";
import { deleteEmailConfigService } from "services/api/notification.api";
import EmailDetailsWrapper from "./styled";

const EmailDetails: React.FunctionComponent<any> = ({
  emailClientConfig,
  apiStatus,
  setApiStatus,
}: any) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [notificationStatus, setNotificationStatus] = useState("");
  const [notificationId, setNotificationId] = useState(0);
  const [modalContent, setModalContent] = useState("");
  const [roleName, setRoleName] = useState<any>([]);
  const [message, setMessage] = useState("");
  const [mainIcon, setMainIcon] = useState("Lock");

  const toggle = () => {
    document.querySelector(".modal-backdrop")?.classList.toggle("show");
    document.querySelector("body")?.classList.toggle("modal-open");
    return setIsShown(!isShown);
  };
  const ApiCaller = (id: number, status: string) => {
    if (apiStatus === "deleteApi") {
      deleteEmailConfigService(id)
        .then(() => {
          store.dispatch(
            showToast({
              type: "success",
              title: "Success",
              message: "Notification has been deleted successfully",
            })
          );
          setApiStatus("delete");
        })
        .catch((errors: any) => {
          store.dispatch(
            showToast({
              title: "Error",
              message: getErrorMessage(errors),
            })
          );
          setApiStatus("config");
        })
        .finally(() => {});
    }
  };
  return (
    <EmailDetailsWrapper className="mb-3 mb-sm-9 pb-sm-4">
      <div className="d-flex  align-items-center justify-content-start mt-md-5">
        <div className="w-100">
          <div className="form-group row required mb-lg-4  offset-lg-1 pl-lg-20 padLeftStyles">
            <div className="vertical-spacing col-lg-2 col-md-3 col-sm-4 label-text">
              Service Provider
            </div>
            <div className="col-lg-7 col-md-9 col-sm-7 font-500 details-text detailValue">
              <span className="pr-2 show-colon ">:</span>
              <span>
                {`${capitalizeFirstLetter(
                  emailClientConfig[0]?.serviceProviderName
                )}`}
              </span>
            </div>
          </div>
          <div className="form-group row required mb-lg-4  offset-lg-1 pl-lg-20 padLeftStyles">
            <div className="vertical-spacing col-lg-2 col-md-3 col-sm-4 label-text">
              {`${capitalizeFirstLetter(
                emailClientConfig[0]?.serviceProviderName
              )} `}
              Domain
            </div>
            <div className="col-lg-7 col-md-9 col-sm-7 font-500 details-text detailValue">
              <span className="pr-2 show-colon ">:</span>
              <span>{emailClientConfig[0]?.config.domain}</span>
            </div>
          </div>
          <div className="form-group row required mb-0  offset-lg-1 pl-lg-20 padLeftStyles">
            <div className="vertical-spacing col-lg-2 col-md-3 col-sm-4 pb-2 label-text">
              {`${capitalizeFirstLetter(
                emailClientConfig[0]?.serviceProviderName
              )} `}
              API Key
            </div>
            <div className="col-lg-7 col-md-9 col-sm-7 pb-2 font-500 details-text d-flex detailValue">
              <span className="pr-2 show-colon ">:</span>
              <span>{emailClientConfig[0]?.config.privateKey}</span>
            </div>
          </div>
          <div className="row  offset-lg-1">
            <div className="col-lg-10 col-md-12 col-sm-12 justify-content-center ">
              <div className="line mt-3 p-sm-15" />
            </div>
            <div className="col-lg-1" />
          </div>

          <div className="row  offset-lg-1">
            <div className=" col-lg-10 col-md-12 col-sm-12 justify-content-center">
              <div className="d-flex contents pt-4">
                <div className="pr-custom-5">
                  <span className="font-600">
                    <button
                      type="button"
                      className="mb-2 editbtn"
                      onClick={() => {
                        setApiStatus("edit");
                      }}
                    >
                      <img
                        className="pr-2"
                        src="/images/Edit.svg"
                        alt="Edit Button"
                      />
                      Edit
                    </button>
                  </span>
                </div>

                <div>
                  <span className="font-600 editbtn">
                    <button
                      type="button"
                      className="mb-2 editbtn"
                      onClick={() => {
                        setApiStatus("deleteApi");
                        setMainIcon(getMainIcon(getStatusAction("")));
                        setRoleName([]);
                        setNotificationId(emailClientConfig[0].id);
                        setModalContent(
                          updateModalContent(
                            getStatusAction(""),
                            "Notification"
                          )
                        );
                        setMessage(getMessage(getStatusAction("")));
                        toggle();
                      }}
                    >
                      <img
                        className="pr-2"
                        src="/images/Delete.svg"
                        alt="Delete Button"
                      />
                      Delete
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-1" />
          </div>
        </div>
      </div>
      <Modal
        isShown={isShown}
        hide={toggle}
        id={notificationId}
        action={ApiCaller}
        status={notificationStatus}
        modalContent={modalContent}
        roles={roleName}
        message={message}
        mainIcon={mainIcon}
      />
    </EmailDetailsWrapper>
  );
};

export default EmailDetails;
