/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import CONSTANTS from "common/constants";
import { toggleModalStyles } from "common/utils";
import NoDataComp from "components/NoData";
import Pagination from "components/Pagination";
import React, { useEffect, useState } from "react";
import { NotificationsListService } from "services/api/notification.api";
import PaginationSettings from "./PaginationSettings";
import SettingsModal from "./SettingsModal";
import NotificationsListWrapper from "./styled";
import {
  defaultValues,
  filtersLabel,
  highlightFilter,
  togglePopover,
} from "./utils";

const tableHeaderNames = [
  { name: "Client Name", id: 1 },
  { name: "Type", id: 2 },
  { name: "Action", id: 3 },
];

const NotificationsList: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [type, setType] = useState<string>("SMS,EMAIL,HTTP");
  const [pageSize, setPageSize] = useState<number>(20);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pagination, setPagination] = useState(defaultValues);
  const [notifications, setNotifications] = useState<any>([]);
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<any>({});
  const [popoverEvent, setPopoverEvent] = useState<any>();
  const onChangePageNumber = (pageNo: number) => {
    setPageNumber(pageNo);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    setLoading(true);

    NotificationsListService({
      pageSize,
      pageNumber,
      type,
    })
      .then((response: any) => {
        if (response && response.data.clients === null) {
          setLoading(false);
          setPagination(defaultValues);
          setNotifications(response.data.clients);
        } else {
          setLoading(false);
          setNotifications(response.data.clients);
          setPagination({ ...response._pagination });
        }
      })
      .catch();
  }, [pageSize, pageNumber, type, refresh]);

  useEffect(() => {
    filtersLabel.forEach((item: any) => {
      item.isSelected = false;
    });
  }, []);

  const setMultiSelectFunc = () => {
    const filerOutput = filtersLabel
      .filter((item: any) => item.isSelected)
      .map((filterItem: any) => filterItem.name.toUpperCase());

    setType(filerOutput.join(","));
    setPageNumber(1);
  };
  return (
    <NotificationsListWrapper>
      <div className="container">
        <h1
          data-testid="test-heading"
          className="notifications pt-3 pb-3 d-block"
        >
          Notifications
        </h1>
        {/* Filter HTML */}
        <div className="d-flex mt-3 mb-3 align-items-center tagSpacing">
          {filtersLabel.map((item, index) => (
            <div className="pr-3" key={item.id}>
              <button
                aria-label="multiselect"
                type="button"
                className={`button-text ml-0 ${
                  item.isSelected ? "selected-filter" : "default-color"
                }`}
                onClick={(event: any) => {
                  filtersLabel[index].isSelected =
                    !filtersLabel[index].isSelected;

                  setMultiSelectFunc();

                  highlightFilter(event, filtersLabel[index].isSelected);
                }}
              >
                {item.name}
              </button>
            </div>
          ))}
        </div>
        <div className="mt-4 mb-4 pt-2 pb-2 showItemsSpacing">
          <PaginationSettings
            setPageSize={setPageSize}
            pageSize={pageSize}
            totalCounts={pagination.totalCount}
          />
        </div>

        <div />
        {loading ? (
          <table
            className="aui-responsive-table aui-table-cols aui-table-loader"
            role="alert"
            aria-live="assertive"
            aria-label="Table is Loading"
          />
        ) : (
          <>
            <table className="aui-responsive-status-table">
              {notifications?.length !== 0 ? (
                <thead>
                  <tr>
                    {tableHeaderNames &&
                      tableHeaderNames.map((colHeader) => {
                        return (
                          <th key={colHeader.id} scope="col">
                            <div className="aui-th">{colHeader.name}</div>
                          </th>
                        );
                      })}
                  </tr>
                </thead>
              ) : null}

              <tbody>
                {notifications &&
                  notifications?.map((notification: any) => (
                    <tr
                      className={
                        notification.is_active
                          ? "aui-table-status-red"
                          : "aui-table-status-grey"
                      }
                      key={notification.id}
                    >
                      <td data-title="Client Name">
                        <div className="aui-td">
                          <p className="mb-0 name-col">{notification.name}</p>
                        </div>
                      </td>
                      <td data-title="Type">
                        <div className="aui-td">
                          <ul className="d-flex type-col p-0 m-0">
                            {notification.types?.map((item: any) =>
                              item.status === CONSTANTS.ROLE_STATUS.INACTIVE ? (
                                <li
                                  className="disabled-icon tooltip-role"
                                  key={item.id}
                                >
                                  {item.type}
                                  <i className="helpTextStyles aha-icon-stop pl-1" />
                                  <span className="tooltiptext ">Inactive</span>
                                </li>
                              ) : (
                                <li className="type-col" key={item.id}>
                                  {item.type}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </td>
                      <td
                        data-title="Action"
                        className="justify-content-center"
                      >
                        <div className="aui-td justify-content-center position-relative client-action">
                          {notification.is_active !== 0 && (
                            <button
                              type="button"
                              data-toggle="collapse"
                              aria-label="button"
                              className="ellipses noBtnStyle btnEllipses"
                              onClick={(e) => {
                                togglePopover(e);
                                setPopoverEvent(e);
                              }}
                            >
                              <i className="aha-icon-meat-balls" />
                            </button>
                          )}

                          <div className="d-none connection-td-wrapper">
                            <div className="d-flex flex-column align-items-start popoverWrapper">
                              <button
                                aria-label="settingbutton"
                                type="button"
                                className="noBtnStyle"
                                onClick={() => {
                                  setSelectedClient(notification);
                                  setToggleModal(true);
                                  toggleModalStyles();
                                }}
                              >
                                Settings
                              </button>
                            </div>
                          </div>
                          <span className="tooltiptext collapse viewMoreKebab">
                            View More
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="row mt-3 mt-sm-5 justify-content-end">
              {pagination.totalPages > 1 &&
                notifications.length > 0 &&
                !loading && (
                  <Pagination
                    {...pagination}
                    setPageNumber={onChangePageNumber}
                    paginationSetLimit={CONSTANTS.PAGINATION_LIMIT}
                  />
                )}
            </div>
          </>
        )}
        {notifications?.length === 0 && !loading ? (
          <NoDataComp DataList={notifications} />
        ) : (
          ""
        )}

        <SettingsModal
          setRefresh={setRefresh}
          refresh={refresh}
          selectedClient={selectedClient}
          setToggleModal={setToggleModal}
          toggleModal={toggleModal}
          popoverEvent={popoverEvent}
        />
      </div>
    </NotificationsListWrapper>
  );
};

export default NotificationsList;
