export const getConfigPayload = (data: any, provider: string) => {
  const config: any = {};
  if (provider.toLowerCase() === "mailgun") {
    config.domain = data[0].value;
    config.privateKey = data[1].value;
  }

  return config;
};

export const getServiceProvider = (data: any) => {
  return data && data[0]?.config.provider;
};
