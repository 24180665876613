const updateModalContent = (action: string, credentials: string) => {
  return `Are you sure you want to ${action.toLocaleLowerCase()} ${credentials}?`;
};
const getStatusAction = (status: string) => {
  switch (status.toLocaleLowerCase()) {
    case "active":
      return "Deactivate";
    case "inactive":
      return "Activate";
    default:
      return "Delete";
  }
};

const getMessage = (action: string) => {
  let msg = ``;
  action = action.toLocaleLowerCase();
  if (action === "delete") {
    msg = `You won’t be able to revert this`;
  } else {
    msg = ``;
  }
  return msg;
};
const resetAddCredetialsForm = () => {
  const modalWrapperId = document.querySelector<any>("#modal1");
  const checkBoxAll = modalWrapperId?.querySelectorAll(
    'input[type="checkbox"]'
  );
  const radioBtnAll = modalWrapperId?.querySelectorAll('input[type="radio"]');
  const textFieldAll = modalWrapperId?.querySelectorAll('input[type="text"]');
  const selectDropdownAll = modalWrapperId?.querySelectorAll("select");

  [...radioBtnAll, ...checkBoxAll].forEach((item: any) => {
    item.checked = false;
  });
  Array.from(textFieldAll).forEach((item: any) => {
    item.value = "";
  });
  Array.from(selectDropdownAll).forEach((item: any) => {
    item.value = "select";
  });
  modalWrapperId.querySelector("[type=submit]").disabled = true;
};
export {
  updateModalContent,
  getStatusAction,
  getMessage,
  resetAddCredetialsForm,
};
