import store from "app/store";
import React, { useEffect, useRef } from "react";
import { useWindowDimensionOnPageResize } from "pages/_layouts/componentUtil";
import {
  setEntityNameFilter,
  setSourceFilter,
  setStatusFilter,
} from "../common/TransactionalLogs.slice";
import { TransactionalLogsModalStyled } from "./styled";

const TransactionalLogsFilterModal: React.FunctionComponent<any> = (
  { entityNameArr, sourceArr, statusArr }: any,
  ref
) => {
  const entityNameRef: any = useRef(null);
  const sourceRef: any = useRef();
  const statusRef: any = useRef();

  const zoomedWindowWidth = useWindowDimensionOnPageResize();
  // FILTER FUNCTIONALITY
  const generateOptions = (options: any) => {
    return options.map((option: any) => {
      return {
        label: `${option}`,
        value: `${option}`,
      };
    });
  };

  const getValueFromMultiSelect = async (event: any) => {
    if (event?.target?.multiselectid === "entity-dropdown") {
      const entityMultiSelValue =
        await entityNameRef.current?.getCurrentValue();
      store.dispatch(
        setEntityNameFilter(
          entityMultiSelValue.map((item: any) => item.value).join(",")
        )
      );
    }
    if (event?.target?.multiselectid === "source-dropdown") {
      const sourceMultiSelValue = await sourceRef.current?.getCurrentValue();
      store.dispatch(
        setSourceFilter(
          sourceMultiSelValue.map((item: any) => item.value).join(",")
        )
      );
    }
    if (event?.target?.multiselectid === "status-dropdown") {
      const statusMultiSelValue = await statusRef.current?.getCurrentValue();
      store.dispatch(
        setStatusFilter(
          statusMultiSelValue.map((item: any) => item.value).join(",")
        )
      );
    }
  };

  // AUI 2.0 MULTISELECT
  useEffect(() => {
    entityNameRef?.current?.addEventListener(
      "auiMultiselectChange",
      getValueFromMultiSelect
    );

    sourceRef?.current?.addEventListener(
      "auiMultiselectChange",
      getValueFromMultiSelect
    );

    statusRef?.current?.addEventListener(
      "auiMultiselectChange",
      getValueFromMultiSelect
    );

    return () => {
      entityNameRef?.current?.removeEventListener(
        "auiMultiselectChange",
        getValueFromMultiSelect
      );
      sourceRef?.current?.removeEventListener(
        "auiMultiselectChange",
        getValueFromMultiSelect
      );
      statusRef?.current?.removeEventListener(
        "auiMultiselectChange",
        getValueFromMultiSelect
      );
    };
  }, []);
  return (
    <TransactionalLogsModalStyled>
      {/* outerwidth check is made to avoid modal window overlay issue on other pages for screens other than mobile view */}
      {+zoomedWindowWidth < 576 && (
        <aui-uploaddocumentmodal
          class="modal fade auimodal auiModalTest"
          id="aui-uploaddocumentmodal"
          tabindex="-1"
          aria-labelledby="aui-uploaddocumentmodalLabel"
          // aria-hidden={toggleModal ? "true" : "false"}
          modalsize="auimodal-sm"
          heading
          headtag="h2"
          bordercolors="none"
          closebuttonlink
        >
          <div className="modal-dialog d-block h-100 p-0 m-0">
            <div className="modal-content d-flex h-100">
              <button
                type="button"
                className="close aui-modalclose position-absolute p-1 aui-modalclosecls"
                data-dismiss="modal"
                aria-label="Close"
              >
                <aui-icon aria-hidden="true" icon="closeIcon" />
              </button>
              <div>
                <div className="modal-header pb-0 pl-0 pt-0 pr-0 mb-4">
                  <aui-modal-headtag
                    id="exampleModalLabel"
                    heading
                    headtag="h2"
                    bordercolors="none"
                  >
                    Modal title
                  </aui-modal-headtag>
                </div>
                <aui-uploaddocumentmodal-content class="modal-body d-block">
                  <form className="w-100 mt-3">
                    <div className="d-flex justify-content-between flex-column">
                      {/* ADD Date range here */}
                      {/* Search Input Field Starts Here */}
                      {/* <SearchInputFieldComp
                    isTableLoading={isTableLoading}
                    setPageNumber={setPageNumberState}
                    setSearchParam={setSearchParam}
                    searchFieldRef={searchFieldRef}
                    setIsActive={setIsActive}
                    setFilterSecShow={setFilterSecShow}
                    setFstColumnGrid={setFstColumnGrid}
                  /> */}
                      <aui-multiselect
                        options={`${JSON.stringify(
                          generateOptions(entityNameArr)
                        )}`}
                        iconbackground
                        placeholder="Select any option"
                        multiselectid="entity-dropdown-modal"
                        label="Select Entity Name"
                        labelgrid="col-sm-3"
                        multiselectgrid="col-sm-9"
                        direction="column"
                        ref={entityNameRef}
                      />
                      <aui-multiselect
                        options={`${JSON.stringify(
                          generateOptions(sourceArr)
                        )}`}
                        iconbackground
                        placeholder="Select any option"
                        multiselectid="source-dropdown-modal"
                        label="Select Source"
                        labelgrid="col-sm-3"
                        multiselectgrid="col-sm-9"
                        direction="column"
                        ref={sourceRef}
                      />
                      <aui-multiselect
                        options={`${JSON.stringify(
                          generateOptions(statusArr)
                        )}`}
                        iconbackground
                        placeholder="Select any option"
                        multiselectid="status-dropdown-modal"
                        label="Select Status"
                        labelgrid="col-sm-3"
                        multiselectgrid="col-sm-9"
                        direction="column"
                        ref={statusRef}
                      />
                    </div>
                  </form>
                </aui-uploaddocumentmodal-content>
              </div>
            </div>
          </div>
        </aui-uploaddocumentmodal>
      )}
    </TransactionalLogsModalStyled>
  );
};

export default TransactionalLogsFilterModal;
