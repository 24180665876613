const WINDOW = window as any;
const CONSTANTS = {
  PENDING: "PENDING",
  REGISTRED: "APPROVED",
  INTERNATIONAL: "INTERNATIONAL",
  DOMESTIC: "DOMESTIC",
  LOCAL_STORE_KEY: "#%$##%$#",
  WEB_STORE_KEY: `oidc.user:${process.env.REACT_APP_SSO_authority}:${process.env.REACT_APP_SSO_client_id}`,
  WINDOW,
  PAGE_ROUTES: {
    DASHBOARD: "/dashboard",
    LOCATION: "/location",
    VIEWTENANT: "/tenants",
    LOGIN: "/",
    EDIT_TENANT: "/tenants/update",
    TENANTS: "/tenants",
    CLIENTS: "/clients",
    USERS: "/users",
    INVITE_USER: "/users/invite",
    CREDENTIALS: "/credentials",
    PROFILE: "/profile",
    ACCESSDENIED: "/accessdenied",
    TRANSACTIONLOGS: "/transactionLogs",
  },
  REGEX: {
    ALPHANUMERIC_SPECIALCHAR: "^[a-zA-Z0-9!@#$&()-.,]*$",
    NAME_ONLYCHAR: /^[a-zA-Z\\ ]+$/,
    ATLEAST_TWO_ALPHANUMERIC: /^[a-zA-Z0-9@#$&()-., ]{2,}$/,
    ATLEAST_ONE_ALPHANUMERIC: /.*[a-zA-Z0-9].*/,
    EMAIL: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
  },
  KEY_ENTER: 13,
  KEY_SPACEBAR: 32,
  KEY_ESCAPE: 27,
  KEY_DOWN: 40,
  KEY_UP: 38,
  ROLE_STATUS: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    PENDING: "PENDING",
    SLICE_COUNT: 4,
  },
  TENANT_STATUS: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  },
  CLIENT_STATUS: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  },
  USER_STATUS: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  },
  CREDENTIAL_STATUS: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  },
  API_SERVICE_ENDPOINT: {
    ACCOUNT_V1: "/account/v1",
    HUB_V1: "/hub/v1",
    NOTIFICATION_V1: "/notification/v1",
  },
  PAGINATION_LIMIT: 5,
  MAX_RECORDS: 500,
  SIDE_MENU_ICONS: {
    DASHBOARD: "dashboard",
    TENANTS: "tenants",
    CLIENTS: "clients",
    USERS: "users",
    CREDENTIALS: "padlock-thick",
    DATAHUB: "data",
    SETTINGS: "settings-thick",
    DOCUMENTATION: "documentation",
    NOTIFICATION: "bell",
  },
  USER_ROLES: {
    SUPER_ADMIN: "SUPER_ADMIN",
    SUPER_USER: "SUPER_USER",
    CLIENT_ADMIN: "CLIENT_ADMIN",
    CLIENT_USER: "CLIENT_USER",
  },
  DASHBOARD_TRANSLOG_RECORDS: 4,
  MODAL_INSTRUCTION_TEXT: "Field marked with an asterisk (*) are mandatory",
  NOTIFICATION_TYPE_ID: {
    EMAIL: 1,
    SMS: 2,
    HTTP: 3,
  },
  ROLE_SWITCH_SEL_VALUE: "adhSelectedUserRole",
};

export default CONSTANTS;
