import "wicg-inert";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import config from "config";

import CONSTANTS from "common/constants";
import { useWindowDimensionOnPageResize } from "pages/_layouts/componentUtil";
import {
  getSideMenuToggleStatus,
  setSideMenuToggleStatus,
  signOut,
} from "common/utils";
import { Button, SideMenuWrapper, WidthWrapperSec } from "./Styled";

interface IMenu {
  title: string;
  path: string;
  url: string;
  icon: string;
  description: string;
  child: Array<IMenu>;
}
export const AHAMenu = ({ buttonClick, setButtonClick }: any) => {
  const [menu, setMenu] = useState<IMenu[]>([]);
  const [showBox, setShowBox] = useState(false);
  const [hoverButton, setHoverButton] = useState(false);
  const [hoverMenu, setHoverMenu] = useState(false);
  const [redIcon, setRedIcon] = useState("");
  const [size, setSize] = useState("100%");
  const [iconSelected, setIconSelected] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();

  const location = useLocation();
  const getRedirectionRoute = (routeData: any) => {
    if (routeData.url) {
      return { pathname: routeData.url };
    }
    if (routeData.path === "/") return "/location";

    return routeData.path;
  };
  useEffect(() => {
    const data = localStorage.getItem("Menus");
    if (data) {
      setMenu(JSON.parse(data));
    }
    if (window.outerWidth > 991) {
      if (getSideMenuToggleStatus()) {
        setButtonClick(true);
      } else {
        setButtonClick(false);
      }
    }
  }, []);
  useEffect(() => {
    if (menu) {
      menu.forEach((data) => {
        if (location.pathname === data.path) {
          if (!data.child) {
            setIconSelected(true);
            localStorage.setItem("selectedIcon", data.title);
          }
        }
      });
    }
  }, [menu]);

  const zoomedWindowWidth = useWindowDimensionOnPageResize();

  const iconNames = (icon: string) => {
    if (icon === "Dashboard") return CONSTANTS.SIDE_MENU_ICONS.DASHBOARD;
    if (icon === "Tenants") return CONSTANTS.SIDE_MENU_ICONS.TENANTS;
    if (icon === "Clients") return CONSTANTS.SIDE_MENU_ICONS.CLIENTS;
    if (icon === "Users") return `${CONSTANTS.SIDE_MENU_ICONS.USERS} users`;
    if (icon === "Credentials") return CONSTANTS.SIDE_MENU_ICONS.CREDENTIALS;
    if (icon === "Data Hub") return CONSTANTS.SIDE_MENU_ICONS.DATAHUB;
    if (icon === "Notifications") return CONSTANTS.SIDE_MENU_ICONS.NOTIFICATION;
    if (icon === "Settings") return CONSTANTS.SIDE_MENU_ICONS.SETTINGS;
    return CONSTANTS.SIDE_MENU_ICONS.DOCUMENTATION;
  };
  const iconColorChanger = (icon: string) => {
    const selIcon = localStorage.getItem("selectedIcon");
    if (redIcon === icon || selIcon === icon) {
      return `${iconNames(icon)} redIcon`;
    }
    return iconNames(icon);
  };
  const newPageIconShower = (menuItem: IMenu) => {
    if (menuItem.url) {
      return "newPageWrapper";
    }
    return "d-none";
  };
  const subMenuSetter = (titleName: string) => {
    localStorage.setItem("subMenu", titleName);
  };
  const subMenuExpander = (titleName: string) => {
    if (iconSelected) {
      return false;
    }
    const subMenuTitle = localStorage.getItem("subMenu");
    if (subMenuTitle === titleName) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (hoverMenu && !buttonClick && !hoverButton) {
      setShowBox(true);
      window.outerWidth > 991 ? setSize("303px") : setSize("auto");
    } else if (!hoverMenu && !buttonClick) {
      setShowBox(false);
    }
  }, [hoverMenu, hoverButton]);
  useEffect(() => {
    if (showBox) {
      window.outerWidth > 991 ? setSize("303px") : setSize("auto");
    } else {
      setSize("100%");
    }
  }, [showBox]);

  const toggleModalBg = (openStatusFlag: string) => {
    if (openStatusFlag?.trim() === "openSideMenu") {
      document.querySelector("#backDropModal")?.classList.add("show");
      document.querySelector("body")?.classList.add("modal-open");
    } else if (openStatusFlag?.trim() === "closeSideMenu") {
      document.querySelector("#backDropModal")?.classList.remove("show");
      document.querySelector("body")?.classList.remove("modal-open");
    }
  };

  useEffect(() => {
    const el: any = document.querySelector(".sideMenuMobWrapper");
    if (parseFloat((window.outerWidth / window.innerWidth).toFixed(2)) === 2) {
      if (buttonClick) {
        el.inert = false;
      } else {
        el.inert = true;
      }
    }

    if (parseFloat((window.outerWidth / window.innerWidth).toFixed(2)) === 1) {
      el.inert = false;
    }

    // if (buttonClick)
    //   document.querySelector<any>(".sideMenuMobArrowBtn")?.focus();
    // else {
    //   document.querySelectorAll<any>(".navbar-toggler")[0]?.focus();
    // }
    if (buttonClick) {
      document.querySelector<any>(".sideMenuMobArrowBtn")?.focus();
    }
  }, [buttonClick, zoomedWindowWidth]);

  useEffect(() => {
    if (buttonClick) {
      setShowBox(true);
      window.outerWidth < 992 && toggleModalBg("openSideMenu");
    } else {
      setHoverButton(false);
      setHoverMenu(false);
      setShowBox(false);
      window.outerWidth < 992 && toggleModalBg("closeSideMenu");
    }
  }, [buttonClick]);
  const mainMenu = (data: any, index: number) => {
    return (
      <li
        key={index}
        role="menuitem"
        onMouseOver={() => {
          setRedIcon(data.title);
        }}
        onKeyUp={(event: any) => {
          if (event.key === "Escape") {
            setButtonClick(false);
            setSideMenuToggleStatus(false);
            document.querySelectorAll<any>(".navbar-toggler")[0]?.focus();
          }
        }}
        onClick={() => {
          localStorage.setItem("selectedIcon", data.title);
        }}
        onMouseOut={() => {
          setRedIcon("");
        }}
        onFocus={() => undefined}
        onBlur={() => undefined}
      >
        <NavLink
          to={data.path === "/" ? "/location" : data.path}
          className="nav-sidebar"
          aria-label={data.title}
          activeClassName="nav-sidebar-active"
          onClick={() => {
            subMenuSetter("");
          }}
        >
          <span>
            <i className={`aha-icon-${iconColorChanger(data.title)}`} />
          </span>
          <p className={showBox ? "fadeIn" : "d-none"}>{data.title}</p>
        </NavLink>
      </li>
    );
  };
  const subMenu = (menuTitle: any, subs: Array<IMenu>, lockNum: number) => {
    if (
      menuTitle.child[0]?.path?.trim().toLowerCase() === "/settings" &&
      window.outerWidth < 992
    )
      return null;
    return (
      <li
        key={lockNum}
        id={`sidenav-accordion-dropdown${lockNum}`}
        role="menuitem"
        onMouseOver={() => {
          setRedIcon(menuTitle.title);
        }}
        onMouseOut={() => {
          setRedIcon("");
        }}
        onFocus={() => undefined}
        onBlur={() => undefined}
        onKeyUp={(event: any) => {
          if (event.key === "Escape") {
            setButtonClick(false);
            setSideMenuToggleStatus(false);
            document.querySelectorAll<any>(".navbar-toggler")[0]?.focus();
          }
        }}
      >
        <button
          type="button"
          aria-label={menuTitle.title}
          className={
            subMenuExpander(menuTitle.title)
              ? "btn btn-text aui-sidenav-acc-header sideMenuBorder"
              : "btn btn-text aui-sidenav-acc-header"
          }
          id={`sidenavHeading${lockNum}`}
          data-toggle="collapse"
          data-target={`#sidenavcollapse${lockNum}`}
          aria-expanded={subMenuExpander(menuTitle.title)}
          aria-controls={`sidenavHeading${lockNum}`}
          onClick={() => {
            subMenuSetter(menuTitle.title);
            localStorage.setItem("selectedIcon", menuTitle.title);
          }}
        >
          <span>
            <i
              className={`aha-icon-${iconColorChanger(
                menuTitle.title
              )} imageIcons`}
            />
          </span>
          <p className={showBox ? "fadeIn" : "d-none"}>{menuTitle.title}</p>
          <i
            className={
              showBox
                ? "acc-btn-arrow aha-icon-arrow-down subMenuArrow"
                : "d-none"
            }
          />
        </button>
        <ul
          role="menu"
          id={`sidenavcollapse${lockNum}`}
          className={
            subMenuExpander(menuTitle.title)
              ? "aui-sidenav-acc-body show"
              : "aui-sidenav-acc-body collapse"
          }
          aria-label="sidenavHeadingOne"
          /*  data-parent={`#sidenav-accordion-dropdown${lockNum}`} */
          data-parent="#sideNavbar"
        >
          {subs.map((value, index) => (
            <li
              role="menuitem"
              key={`subs_${value.title}_${value.path ? value.path : value.url}`}
              aria-label={value.title}
            >
              <NavLink
                aria-label={
                  value.url
                    ? `${value.title} opens in a new tab window`
                    : value.title
                }
                to={() => getRedirectionRoute(value)}
                rel="noreferrer"
                className={showBox ? "nav-sidebar" : "d-none"}
                activeClassName={iconSelected ? "" : "subMenu-active"}
                onClick={(e) => {}}
                onTouchStart={(e) => {
                  if (value.path === "/") {
                    history.push("/location");
                  } else if (value.url) {
                    window.open(value.url);
                  } else {
                    history.push(value.path);
                  }
                }}
                target={value.url ? "_blank" : "_self"}
              >
                {value.title}
                <div className={newPageIconShower(value)}>
                  <img
                    src="/images/Icons-NewPage.svg"
                    alt="icons"
                    className="newPage"
                  />
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      </li>
    );
  };
  return (
    <WidthWrapperSec className="d-flex" buttonClick={buttonClick}>
      <SideMenuWrapper
        className="sideMenuMobWrapper"
        size={size}
        buttonClick={buttonClick}
      >
        <div className="aui-sidenav" role="group">
          <nav
            className="navbar-expand-lg overflow-hidden"
            aria-label="SideMenu"
          >
            {/* <button
              className="navbar-toggler float-right m-3 sideMenuMobHide"
              type="button"
              data-toggle="collapse"
              data-target="#sideNavbar"
              aria-controls="sideNavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="aha-icon-hamburger-round" />
            </button> */}
            {window.outerWidth > 991 && (
              <div className="d-flex">
                <span
                  className={`AHAlogo ${
                    buttonClick || hoverMenu ? "d-none" : "d-block"
                  }`}
                >
                  <img
                    aria-label={
                      buttonClick || hoverMenu ? "A D H logo" : "A H A logo"
                    }
                    src="/images/aha-100-years-logo.svg"
                    alt={buttonClick || hoverMenu ? "A D H Logo" : "A H A Logo"}
                  />
                </span>

                <span
                  className={`AHAlogo ADHlogo ${
                    buttonClick || hoverMenu ? "d-block" : "d-none"
                  }`}
                >
                  <img
                    aria-label={
                      buttonClick || hoverMenu ? "A D H logo" : "A H A logo"
                    }
                    src="/images/aha-logo-100-years-adh.svg"
                    alt={buttonClick || hoverMenu ? "A D H Logo" : "A H A Logo"}
                  />
                </span>
              </div>
            )}
            <div className="expandButton d-flex">
              <Button
                buttonClick={buttonClick}
                aria-label="Navigation Menu"
                aria-expanded={buttonClick ? "true" : "false"}
                type="button"
                className="sideMenuMobArrowBtn"
                onClick={() => {
                  setButtonClick(!buttonClick);
                  setSideMenuToggleStatus(!buttonClick);
                  setSize("100%");
                }}
                onMouseEnter={() => {
                  setHoverButton(true);
                }}
                onMouseLeave={() => {
                  setHoverButton(false);
                }}
                onFocus={() => undefined}
                onBlur={(event: any) => {
                  if (event.shiftKey) {
                    setButtonClick(true);
                    setSideMenuToggleStatus(true);
                    document
                      .querySelectorAll<any>(".navbar-toggler")[0]
                      ?.focus();
                  }
                }}
                tabIndex={0}
              >
                <i
                  className={
                    buttonClick
                      ? "acc-btn-arrow aha-icon-arrow-down directionRight"
                      : "acc-btn-arrow aha-icon-arrow-down directionLeft"
                  }
                />
              </Button>
              {/* <div className="tooltip-role">
                <span className="tooltiptext">expand the navigation</span>
              </div> */}
            </div>
            <div className="collapse navbar-collapse" id="sideNavbar">
              <ul
                className="w-100 p-0 ul-list"
                role="menu"
                onMouseEnter={() => {
                  setHoverMenu(true);
                }}
                onMouseLeave={() => {
                  setHoverMenu(false);
                  setHoverButton(false);
                }}
                onFocus={() => undefined}
                onBlur={() => undefined}
              >
                {menu.map((value, index) =>
                  value.child
                    ? subMenu(value, value.child, index)
                    : mainMenu(value, index)
                )}
              </ul>
              {+zoomedWindowWidth < 769 && (
                <ul className="w-100 p-0 ul-list profileMobMenu" role="menu">
                  <li role="menuitem">
                    <button
                      role="link"
                      type="button"
                      className="dropdown-item "
                      onClick={() => {
                        history.push("/profile");
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter" || e.key === "Spacebar") {
                          history.push("/profile");
                        }
                      }}
                    >
                      <i className="aha-icon-avatar-outline" /> Profile
                    </button>
                  </li>
                  <li role="menuitem">
                    <button
                      role="link"
                      type="button"
                      onClick={signOut}
                      className="dropdown-item signOutPadL"
                      onKeyUp={(e: any) => {
                        if (
                          e.keyCode === CONSTANTS.KEY_ENTER ||
                          e.keyCode === CONSTANTS.KEY_SPACEBAR
                        ) {
                          signOut();
                        }
                      }}
                      onKeyDown={(event: any) => {
                        if (!event.shiftKey) {
                          setButtonClick(false);
                          setSideMenuToggleStatus(false);
                        }
                      }}
                      tabIndex={0}
                      aria-label="Sign Out"
                    >
                      <i className="aha-icon-logout" /> <span>Sign Out</span>
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </nav>
        </div>
      </SideMenuWrapper>
    </WidthWrapperSec>
  );
};

export default AHAMenu;
