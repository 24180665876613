/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  closeModalPopup,
  getErrorMessage,
  getMainIcon,
  lowerCaseAllWordsExceptFirstLetters,
  rotateElipses,
  ssouserProfile,
  toggleModalStyles,
  toggleUserActionEllipses,
} from "common/utils";
import CONSTANTS from "common/constants";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import { Wrapper } from "styled";
import store from "app/store";
import { useAppDispatch } from "app/useAppDispatch";
import Modal from "components/Modal";
import {
  addRolePostService,
  getUserByID,
  userRoleDeleteService,
  userRoleStatusChangeService,
} from "services/api/user.api";
import {
  inviteUserService,
  reInviteUserService,
} from "services/api/inviteUser.api";
import { hideToast, showToast } from "components/Toast/toast.slice";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { getRoles } from "services/api/settings.api";
import PermissionContext from "services/PermissionManager/PermissionContext";
import { fetchPermission } from "services/PermissionManager/Types";
import { ClientListService } from "services/api/clients.api";
import { UserStatusChanger, userDelete } from "services/api/userDelete";
import FocusTrap from "focus-trap-react";
import {
  getStatusAction,
  updateModalContent,
} from "../../components/Modal/util";
import { ViewUserDetailsWrapperStyles } from "./Styled";

const ViewUserDetailsComp: FunctionComponent<any> = (props: any) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const userProfile = ssouserProfile();

  const { loggedInUserObjectContext } = useContext(PermissionContext);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [shouldShowAddRole, setShouldShowAddRole] = useState(true);
  const [isUserOrSuperUser, setIsUserOrSuperUser] = useState(false);
  const [isSuperAdminOrClientAdmin, setIsSuperAdminOrClientAdmin] =
    useState(false);

  const [selectedUserObject, setUserObject] = useState<any>({});
  const [showEllipse, setShowEllipse] = useState(true);

  // Aria Related variables
  const RefFocus = useRef<any>();

  useEffect(() => {
    if (loggedInUserObjectContext !== null) {
      if (
        loggedInUserObjectContext?.roleCode === CONSTANTS.USER_ROLES.SUPER_ADMIN
      ) {
        if (selectedUserObject.status === CONSTANTS.ROLE_STATUS.ACTIVE) {
          setShouldShowAddRole(true);
          setIsSuperAdminOrClientAdmin(true);
        } else if (
          selectedUserObject.status === CONSTANTS.ROLE_STATUS.INACTIVE ||
          selectedUserObject.status === CONSTANTS.ROLE_STATUS.PENDING
        ) {
          setIsSuperAdminOrClientAdmin(false);
          setShouldShowAddRole(false);
        }
      }
      if (
        loggedInUserObjectContext?.roleCode ===
        CONSTANTS.USER_ROLES.CLIENT_ADMIN
      ) {
        setShowEllipse(true);
        setShouldShowAddRole(false);
        if (selectedUserObject.status === CONSTANTS.ROLE_STATUS.ACTIVE) {
          setIsSuperAdminOrClientAdmin(true);
        } else if (
          selectedUserObject.status === CONSTANTS.ROLE_STATUS.INACTIVE ||
          selectedUserObject.status === CONSTANTS.ROLE_STATUS.PENDING
        ) {
          setIsSuperAdminOrClientAdmin(false);
        }
      }
      if (
        loggedInUserObjectContext?.roleCode ===
          CONSTANTS.USER_ROLES.CLIENT_USER ||
        loggedInUserObjectContext?.roleCode === CONSTANTS.USER_ROLES.SUPER_USER
      ) {
        setIsUserOrSuperUser(true);
      }
      if (
        loggedInUserObjectContext?.roleCode ===
          CONSTANTS.USER_ROLES.CLIENT_USER ||
        loggedInUserObjectContext?.roleCode === CONSTANTS.USER_ROLES.SUPER_USER
      ) {
        setShouldShowAddRole(false);
        setIsSuperAdminOrClientAdmin(false);
        setShowEllipse(false);
      }
    }
  }, [loggedInUserObjectContext, selectedUserObject]);

  const dispatch = useDispatch();
  const history = useHistory();

  const [modalApiId, setModalApiId] = useState<any>();

  const togglePopover = (e: any) => {
    Array.from(document.querySelectorAll(".connection-td-wrapper")).forEach(
      (el) => {
        if (e.currentTarget?.nextSibling.nextSibling !== el) {
          el.classList.remove("d-flex", "d-none");
          el.classList.add("d-none");
        }
      }
    );
    e.currentTarget?.nextSibling?.nextSibling?.classList.toggle("d-none");
    rotateElipses(".btnEllipses", e);

    e.currentTarget.nextSibling?.nextSibling.classList.contains("d-none")
      ? e.currentTarget.setAttribute("aria-expanded", "false")
      : e.currentTarget.setAttribute("aria-expanded", "true");
  };
  const UIschema = yup.object().shape({
    roleCode: yup.string().required("Role required"),
    clientId: yup.string(),
  });
  const [roleAdded, setRoleAdded] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [clientAdded, setClientAdded] = useState(false);
  const { register, handleSubmit, reset } = useForm({
    resolver: yupResolver(UIschema),
  });
  const dropDownToggler = (e: any) => {
    e.currentTarget.nextSibling.classList.toggle("rotate");
  };
  const dropDownDefaultState = (e: any) => {
    if (e.currentTarget.nextSibling.classList.contains("rotate") === true) {
      e.currentTarget.nextSibling.classList.remove("rotate");
    }
  };

  const closeActionPopover = (event: any) => {
    const popover = document.getElementById("popover");
    if (event.target.id !== "aha-setting-icon") {
      popover?.classList.remove("d-flex");
      popover?.classList.add("d-none");
    }
  };

  const [AddRoleClientData, setAddRoleClientData] = useState<any>([]);

  const getActiveClientData = () => {
    ClientListService({
      isActive: true,
      pageSize: CONSTANTS.MAX_RECORDS,
    })
      .then((response: any) => {
        if (response && response.data.clients === null) {
          setAddRoleClientData([]);
        } else {
          setAddRoleClientData(response.data.clients);
        }
        return { ...response._pagination };
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occured",
            message: getErrorMessage(error),
          })
        );
      })
      .finally(() => {
        setRoleAdded(true);
      });
  };

  const [roleCardStateSA, setRoleCardStateSA] = useState<any>([]);
  const [roleCardStateCL, setRoleCardStateCL] = useState<any>([]);
  const [viewClientSelect, setViewClientSelect] = useState(false);

  const [isShown, setIsShown] = useState<boolean>(false);

  const dropDownDecider = (e: any) => {
    const { value: roleSelected } = e.target;

    if (roleSelected === "CLIENT_USER" || roleSelected === "CLIENT_ADMIN") {
      document.querySelector<any>("#selectClient").value = "";
      getActiveClientData();
      setViewClientSelect(true);
    } else {
      setViewClientSelect(false);
    }
  };

  const resetAddRoleInitialView = () => {
    reset();

    setViewClientSelect(false);
    setRoleAdded(false);

    setClientAdded(false);
    document.querySelector<any>("#selectRole").value = "";

    document.querySelector<any>("#selectClient").value = "";
  };

  const setRoleCardStates = (rolesArrObj: any) => {
    if (rolesArrObj.length === 0) return null;
    // reset roles data - delete role scenario
    setRoleCardStateSA([]);
    setRoleCardStateCL([]);

    rolesArrObj.forEach((roleItem: any) => {
      if (roleItem.roleId === 1 || roleItem.roleId === 6) {
        setRoleCardStateSA((prevState: any) => [...prevState, roleItem]);
      } else if (roleItem.roleId === 4 || roleItem.roleId === 5) {
        setRoleCardStateCL((prevState: any) => [...prevState, roleItem]);
      }
    });

    return "";
  };

  const setRoleCardStatesInitial = (rolesArrObj: any) => {
    if (rolesArrObj.length === 0) return null;
    // Selected User Has got some roles

    rolesArrObj.forEach((roleItem: any) => {
      if (roleItem.roleId === 1 || roleItem.roleId === 6) {
        setRoleCardStateSA((prevState: any) => [...prevState, roleItem]);
      } else if (roleItem.roleId === 4 || roleItem.roleId === 5) {
        setRoleCardStateCL((prevState: any) => [...prevState, roleItem]);
      }
    });

    return "";
  };
  const imageUrl = "../images/Close.svg";
  const [imageLoading, setImageLoading] = useState(true);
  const imageLoaded = () => {
    setImageLoading(false);
  };

  const fetchUserDetailsAPI = (pageloadStatus: boolean) => {
    getUserByID(Number(id))
      .then((userObj) => {
        if (userObj?.data) {
          setUserObject(userObj.data?.user);
          pageloadStatus
            ? setRoleCardStatesInitial(userObj.data?.user?.roles)
            : setRoleCardStates(userObj.data?.user?.roles);
          dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            title: "Error Occured.", // change this
            message: getErrorMessage(error), // change this
          })
        );
        history.push("/users");
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      });
  };

  const [toggleAddRoleModal, setToggleAddRoleModal] = useState(false);
  const [toggleEditAccessModal, setToggleEditAccessModal] = useState(false);
  const submitFormAddRoleModal = (formdata: any) => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    formdata.clientId =
      formdata.clientId?.trim().length === 0 || !viewClientSelect
        ? "0"
        : formdata.clientId;
    addRolePostService({
      userId: id,
      postData: formdata,
    })
      .then(() => {
        store.dispatch(
          showToast({
            type: "success",
            title: "Success",
            message: "Roles added successfully",
          })
        );
      })
      .catch((error: any) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error",
            message: getErrorMessage(error),
          })
        );
      })
      .finally(() => {
        fetchUserDetailsAPI(false);
        toggleModalStyles();
        setToggleAddRoleModal(false);
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
        setImageLoading(true);
      });
  };

  const buttonStatusSetter = () => {
    if (roleAdded && (viewClientSelect ? clientAdded : true)) {
      return false;
    }
    return true;
  };
  const [mainIcon, setMainIcon] = useState<string>("Lock");
  const [message, setMessage] = useState<string>("");
  const [apiStatus, setApiStatus] = useState<string>("");
  const [roleStatusModal, setRoleStatusModal] = useState<string>("");
  const [roleCodeApi, setRoleCodeApi] = useState<string>("");
  const [apiReqClientId, setApiReqClientId] = useState<any>({});
  const [selectClientValue, setSelectClientValue] = useState<any>("");
  const toggle = () => {
    document.querySelector(".modal-backdrop")?.classList.toggle("show");
    document.querySelector("body")?.classList.toggle("modal-open");
    return setIsShown(!isShown);
  };

  const ApiCaller = (idapi: number, roleStatus: string) => {
    if (apiStatus === "roleSwitch") {
      userRoleStatusChangeService(
        idapi,
        roleStatus,
        roleCodeApi,
        apiReqClientId.clientId
      )
        .then((response) => {
          setToggleStatus(!toggleStatus);
          store.dispatch(
            showToast({
              type: "success",
              title: "Success",
              message: "User role has been switched successfully",
            })
          );
          fetchUserDetailsAPI(false);
          toggleModalStyles();
          setToggleEditAccessModal(false);
          setImageLoading(true);
        })
        .catch((errors) => {
          store.dispatch(
            showToast({
              title: "Error",
              message: getErrorMessage(errors),
            })
          );
          /* history.go(0); */
        });
    } else if (apiStatus === "status") {
      userRoleStatusChangeService(
        idapi,
        roleStatus,
        roleCodeApi,
        apiReqClientId.clientId
      )
        .then((response) => {
          setToggleStatus(!toggleStatus);
          if (roleStatus === "ACTIVE") {
            store.dispatch(
              showToast({
                type: "success",
                title: "Success",
                message: "User role activated successfully",
              })
            );

            fetchUserDetailsAPI(false);
          } else {
            store.dispatch(
              showToast({
                type: "success",
                title: "Success",
                message: "User role deactivated successfully",
              })
            );

            fetchUserDetailsAPI(false);
          }
        })
        .catch((error) => {
          store.dispatch(
            showToast({
              title: "Error Occurred.",
              message: getErrorMessage(error),
            })
          );
          /*  history.go(0); */
        });
    } else if (apiStatus === "delete") {
      userRoleDeleteService(idapi)
        .then((response) => {
          setToggleStatus(!toggleStatus);
          store.dispatch(
            showToast({
              type: "success",
              title: "Success",
              message: "User role deleted successfully",
            })
          );
          /* history.go(0); */
          if (roleCardStateSA.length + roleCardStateCL.length <= 1) {
            history.push("/users");
          } else {
            fetchUserDetailsAPI(false);
          }
        })
        .catch((errors) => {
          store.dispatch(
            showToast({
              title: "Error",
              message: getErrorMessage(errors),
            })
          );
          /* history.go(0); */
        });
    } else if (apiStatus === "addRole") {
      userRoleDeleteService(idapi)
        .then((response) => {
          setToggleStatus(!toggleStatus);
          store.dispatch(
            showToast({
              type: "success",
              title: "Success",
              message: "User role deleted successfully",
            })
          );
          /* history.go(0); */
          fetchUserDetailsAPI(false);
        })
        .catch((errors) => {
          store.dispatch(
            showToast({
              title: "Error",
              message: errors.response.data.error.message,
            })
          );
          /* history.go(0); */
        });
    }
  };
  const [modalContent, setModalContent] = useState("");

  const getRoleCodeFromRoleId = (roleId: number) => {
    let roleCode = "";
    switch (roleId) {
      case 1:
        roleCode = "SUPER_ADMIN";
        break;
      case 2:
        roleCode = "ADMIN";
        break;
      case 3:
        roleCode = "ADMIN_VIEW";
        break;
      case 4:
        roleCode = "CLIENT_ADMIN";
        break;
      case 5:
        roleCode = "CLIENT_USER";
        break;
      case 6:
        roleCode = "SUPER_USER";
        break;
      default:
        roleCode = "";
    }

    return roleCode;
  };

  let prevScrollposBtnSec = window.pageYOffset;

  const makeBtnSectionStickyMobile = () => {
    const elBtn = document.getElementsByClassName("btn-background");
    window.addEventListener("scroll", () => {
      const currentScrollPosBtnSec = window.pageYOffset;
      if (prevScrollposBtnSec > currentScrollPosBtnSec) {
        Array.from(elBtn).forEach((item) => {
          item.classList.remove("bottom-70");
          item.classList.add("bottom-0");
        });
      } else {
        Array.from(elBtn).forEach((item) => {
          item.classList.remove("bottom-0");
          item.classList.add("bottom-70");
        });
      }
      prevScrollposBtnSec = currentScrollPosBtnSec;
    });
  };

  const hideModalBackDrop = () => {
    document.querySelector(".modal-backdrop")?.classList.remove("show");
    document.querySelector<any>("body").classList.remove("modal-open");
    document.querySelector<any>("body").setAttribute("style", "");
  };

  useEffect(() => {
    window.innerWidth < 576 && makeBtnSectionStickyMobile();
  }, [prevScrollposBtnSec]);

  // Accessibility Useeffect for focus
  useEffect(() => {
    if (toggleAddRoleModal && RefFocus.current && !imageLoading)
      RefFocus.current.focus();
  }, [toggleAddRoleModal, imageLoading]);
  useEffect(() => {
    if (toggleEditAccessModal && RefFocus.current && !imageLoading)
      RefFocus.current.focus();
  }, [toggleEditAccessModal, imageLoading]);

  useEffect(() => {
    document.body.addEventListener("click", closeActionPopover);

    // api call
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    fetchUserDetailsAPI(true);

    store.dispatch(hideToast());

    hideModalBackDrop();

    return () => {
      document.body.removeEventListener("click", closeActionPopover);
    };
  }, []);
  const [btnDisable, setBtnDisable] = useState(true);

  const getColorCodenCatName = (roleObj: any) => {
    const roleDetails = { roleName: "", roleStyleCls: "", containerCls: "" };
    let combinedCls = "";
    if (roleObj.userRoleStatus === "INACTIVE") {
      combinedCls = "roleColorCodeINACTIVE";
    }
    switch (roleObj.roleId) {
      case 1:
        roleDetails.roleName = "Super Admin";
        roleDetails.roleStyleCls = `roleColorCodeSA ${combinedCls}`;
        roleDetails.containerCls =
          roleObj.userRoleStatus.trim().toLowerCase() === "active"
            ? "containerSA"
            : "containerTN";
        return roleDetails;
      case 4:
        roleDetails.roleName = "Vendors";
        roleDetails.roleStyleCls = `roleColorCodeCL ${combinedCls}`;
        roleDetails.containerCls =
          roleObj.userRoleStatus.trim().toLowerCase() === "active"
            ? "containerSA"
            : "containerTN";
        return roleDetails;
      case 5:
        roleDetails.roleName = "Client User";
        roleDetails.roleStyleCls = `roleColorCodeEU ${combinedCls}`;
        roleDetails.containerCls =
          roleObj.userRoleStatus.trim().toLowerCase() === "active"
            ? "containerSA"
            : "containerTN";
        return roleDetails;
      case 6:
        roleDetails.roleName = "Super User";
        roleDetails.roleStyleCls = `roleColorCodeSA ${combinedCls}`;
        roleDetails.containerCls =
          roleObj.userRoleStatus.trim().toLowerCase() === "active"
            ? "containerSA"
            : "containerTN";
        return roleDetails;
      default:
        return roleDetails;
    }
  };

  const generateHelpIcons = (roleObj: any) => {
    // super admin
    if (roleObj.roleId === 1) {
      return (
        <span className="Img-tooltip">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserSetting.svg"
            alt="admin"
          />
          <span className="Img-tooltiptext">Super Admin</span>
        </span>
      );
    }
    // super user
    if (roleObj.roleId === 6) {
      return (
        <span className="Img-tooltip">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserShield.svg"
            alt="View Only"
          />
          <span className="Img-tooltiptext">View Only</span>
        </span>
      );
    }
    // client admin
    if (roleObj.roleId === 4) {
      return (
        <span className="Img-tooltip">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserPen.svg"
            alt="View & Edit"
          />
          <span className="Img-tooltiptext">View & Edit</span>
        </span>
      );
    }
    // client user
    if (roleObj.roleId === 5) {
      return (
        <span className="Img-tooltip">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserPen-Slash.svg"
            alt="View Only"
          />
          <span className="Img-tooltiptext">View Only</span>
        </span>
      );
    }

    return "";
  };
  const roleInactive = (roleObj: any) => {
    if (roleObj.userRoleStatus === "INACTIVE") {
      return (
        <span className="tooltip-role">
          <i className="helpTextStyles aha-icon-stop" />
          <span className="tooltiptext">Inactive</span>
        </span>
      );
    }
    return "";
  };
  const generateModalContent = (roleDetailsObj: any) => {
    let roleModalContent: any = "";
    // check for rolename and client Name mapping
    if (roleDetailsObj.clientId === null) {
      roleModalContent = roleDetailsObj.roleName;
    } else if (roleDetailsObj.clientId !== null)
      roleModalContent = `${roleDetailsObj.clientName}-${roleDetailsObj.roleName}`;

    return roleModalContent.trim().length > 0 ? roleModalContent : null;
  };
  const generateBadgeContent = (roleDetailsObj: any) => {
    const returnJsx = (clientName: string, roleName: string) => {
      return (
        <>
          <span className="roleClientName">{roleName}</span> (
          <span>{clientName}</span>)
        </>
      );
    };
    const returnRoleName = (roleName: string) => {
      return <span className="roleName">{roleName}</span>;
    };
    let roleBadgeContent: any = "";
    // check for rolename and client Name mapping
    if (roleDetailsObj.clientId === null) {
      roleBadgeContent = returnRoleName(roleDetailsObj.roleName);
    } else if (roleDetailsObj.clientId !== null)
      roleBadgeContent = returnJsx(
        roleDetailsObj.clientName,
        roleDetailsObj.roleName
      );

    return roleBadgeContent;
  };

  const getMlStyles = (indexVal: any) => {
    return indexVal === 0 && "";
  };
  const appDispatch = useAppDispatch() as any;

  const toggleRoleStatus = (roleID: any, actionParam: string) => {
    let newRoleArr = [];
    if (actionParam === "delete") {
      if (roleID === 1 || roleID === 6) {
        newRoleArr = roleCardStateSA.filter(
          (item: any) => item.roleId !== roleID
        );
      } else if (roleID === 4 || roleID === 5) {
        newRoleArr = roleCardStateCL.filter(
          (item: any) => item.roleId !== roleID
        );
      }
      /* appDispatch(
        setUserDetails({
          selectedUserObject: { ...selectedUserObject, roles: newRoleArr },
        })
      );
      setStoreRefreshData(!storeRefreshData); */
    }
  };
  const getMessage = (action: string) => {
    let msg = ``;
    action = action.toLocaleLowerCase();
    if (action === "activate") {
      msg = `Activating user will associate following roles`;
    } else if (action === "deactivate") {
      msg = `Deactivating user will disassociate following roles`;
    } else if (action === "delete") {
      msg = `Deleting user will disassociate following roles \n You won’t be able to revert this`;
    } else {
      msg = ``;
    }
    setMessage(msg);
    setMainIcon(getMainIcon(action));
  };

  const statusButtonHandler = (status: string) => {
    setRoleStatusModal(
      status === "ACTIVE"
        ? CONSTANTS.CLIENT_STATUS.INACTIVE
        : CONSTANTS.CLIENT_STATUS.ACTIVE
    );
  };

  const restrictAccessForStatusChange = () => {
    // checking for logged in user
    return userProfile.email !== selectedUserObject.email;
  };

  const generateLevelsFromRoleId = (roleId: any) => {
    if (roleId === 1 || roleId === 6) return 0;
    if (roleId === 2 || roleId === 3) return 1;
    if (roleId === 4 || roleId === 5) return 2;
    return null;
  };
  const [levelDropDownData, setLevelDropdownData] = useState<any>([]);
  const getSameLevelRolesAPI = (selectedUserRoleId: any) => {
    getRoles({
      isActive: true,
      level: "eq".concat(
        ",",
        `${generateLevelsFromRoleId(selectedUserRoleId)}`
      ),
    })
      .then((response: any) => {
        if (response && response.data.roles === null) {
          setLevelDropdownData([]);
        } else {
          setLevelDropdownData(response.data.roles);
        }
        return { ...response._pagination };
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occured",
            message: getErrorMessage(error),
          })
        );
      })
      .finally(() => {});
  };

  const [addRoleDropDownData, setAddRoleDropDownData] = useState<any>([]);
  const getAddRolesDataAPI = () => {
    getRoles({
      isActive: true,
      level: "gte".concat(",", `${loggedInUserObjectContext.roleLevel}`),
    })
      .then((response: any) => {
        if (response && response.data.roles === null) {
          setAddRoleDropDownData([]);
        } else setAddRoleDropDownData(response.data.roles);
        return { ...response._pagination };
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occured",
            message: getErrorMessage(error),
          })
        );
      })
      .finally(() => {});
  };
  const [roleObjSwitchAccess, setRoleObjSwitchAccess] = useState<any>({});
  const [userEllipsesPopover, setUserEllipsesPopover] = useState(false);

  const generateGroupedRoleCards = (groupRoleArr: any) => {
    return (
      groupRoleArr &&
      groupRoleArr.map((item: any, index: any) => {
        return (
          <div
            key={`${item.roleId}_${item.userRoleId}_${item.clientName}`}
            className={`colStyles cardWidth col-sm-6 col-md-6 col-lg-4 ${getMlStyles(
              index
            )}`}
          >
            <div
              className={`containerSection ${
                getColorCodenCatName(item).containerCls
              }`}
            >
              {isSuperAdminOrClientAdmin ? (
                <div className="helpTextWrapper">
                  <div className="ml-3 text-right mb-1 pr-0 role-ellipse tooltip-role">
                    {restrictAccessForStatusChange() && (
                      <button
                        type="button"
                        aria-label="View More Option"
                        className="noBtnStyle btnEllipses rotate-90-anti p-0"
                        tabIndex={0}
                        aria-expanded={false}
                        onClick={(e) => {
                          togglePopover(e);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Spacebar") togglePopover(e);
                        }}
                      >
                        <i className="aha-icon-meat-balls" />
                      </button>
                    )}

                    <span className="tooltiptext">View More</span>

                    <div className="d-none connection-td-wrapper">
                      <div className="popoverWrapper">
                        {item.userRoleStatus ===
                          CONSTANTS.ROLE_STATUS.ACTIVE && (
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#org10"
                            className="noBtnStyle mb-2"
                            onClick={(e) => {
                              setApiStatus("roleSwitch");
                              setEditRole(false);
                              setModalApiId(id);
                              setRoleStatusModal(item.userRoleStatus);
                              setRoleObjSwitchAccess(item);
                              setApiReqClientId({
                                clientId:
                                  item.clientId === null
                                    ? `${0}`
                                    : `${item.clientId}`,
                              });
                              getSameLevelRolesAPI(item.roleId);
                              setRoleAdded(false);
                              if (toggleEditAccessModal) {
                                document.querySelector<any>("#editRole").value =
                                  "selected";
                              }
                              toggleModalStyles();
                              setToggleEditAccessModal(true);
                            }}
                          >
                            Edit Access
                          </button>
                        )}
                        {restrictAccessForStatusChange() && (
                          <>
                            <button
                              type="button"
                              className="noBtnStyle mb-2"
                              onClick={() => {
                                setApiStatus("status");
                                setUserEllipsesPopover(false);
                                setRoleCodeApi(
                                  getRoleCodeFromRoleId(item.roleId)
                                );
                                statusButtonHandler(item.userRoleStatus);
                                setModalContent(
                                  updateModalContent(
                                    getStatusAction(item.userRoleStatus),
                                    `role ${generateModalContent(item)}`
                                  )
                                );
                                setModalApiId(id);
                                getMessage(
                                  getStatusAction(item.userRoleStatus)
                                );
                                setApiReqClientId({
                                  clientId:
                                    item.clientId === null
                                      ? `${0}`
                                      : `${item.clientId}`,
                                });
                                toggle();
                              }}
                            >
                              {item.userRoleStatus.trim().toLowerCase() ===
                              "active"
                                ? "Deactivate"
                                : "Activate"}
                            </button>
                            <button
                              type="button"
                              className="noBtnStyle mb-2"
                              onClick={() => {
                                setUserEllipsesPopover(false);
                                setApiStatus("delete");
                                setModalContent(
                                  updateModalContent(
                                    getStatusAction(""),
                                    `role ${generateModalContent(item)}`
                                  )
                                );
                                setModalApiId(item.userRoleId);
                                getMessage(getStatusAction(""));
                                toggle();
                              }}
                            >
                              Delete
                            </button>{" "}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="col-md-12 no-gutters pl-0 pr-0 mb-2 roleContainer">
                <div className="col-3 d-flex align-items-end">
                  <span className="roleText">Role</span>
                </div>
                <div className="col-9 roleSec">
                  <span>:</span>
                  <div className="ml-2 groupedRoleStyle">
                    <p
                      className={`roleStylesMin pl-0 ${
                        getColorCodenCatName(item).roleStyleCls
                      }`}
                    >
                      {generateHelpIcons(item)}
                      {generateBadgeContent(item)}
                      {roleInactive(item)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  const checkBoxShadow = () => {
    if (roleCardStateSA?.length === 1 || roleCardStateCL?.length === 1) {
      return "noBoxShadow";
    }
    return "";
  };
  const [userStatus, setUserStatus] = useState("");
  const [userId, setUserId] = useState(0);
  const [roleName, setRoleName] = useState<any>([]);
  const getClassNameUserStatus = () => {
    if (selectedUserObject.status === "ACTIVE") return "user-status-activate";
    if (selectedUserObject.status === "INACTIVE")
      return "user-status-inactivate";
    if (selectedUserObject.status === "PENDING") return "user-status-pending";
    return "";
  };
  const reInviteUserAction = async (
    userId: string,
    toggler: any,
    event: any
  ) => {
    event.persist();
    reInviteUserService(userId)
      .then(() => {
        store.dispatch(
          showToast({
            type: "success",
            title: "Success",
            message: "User has been re-invited successfully",
          })
        );
        toggler(event);
      })
      .catch((err: any) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error occured while re-inviting user",
            message: getErrorMessage(err),
          })
        );
        toggler(event);
      });
  };

  const rotateMeatBalls = (e: any) => {
    Array.from(document.querySelectorAll(".btnEllipses")).forEach((el) => {
      if (e.currentTarget === el) {
        if (el.classList.contains("rotate-90")) {
          el.classList.remove("rotate-90");
          el.classList.toggle("rotate-90-anti");
        } else if (el.classList.contains("rotate-90-anti")) {
          el.classList.remove("rotate-90-anti");
          el.classList.toggle("rotate-90");
        } else {
          el.classList.toggle("rotate-90");
        }
      } else {
        el.classList.remove("rotate-90");
      }
    });
  };
  const togglePopverOnWrapperClick = (e: any) => {
    e.target.parentNode.parentNode.classList.toggle("d-none");
    rotateMeatBalls(e);
  };
  const userStatusButtonHandler = (
    status: string,
    id: number,
    name: string
  ) => {
    setUserStatus(
      status === CONSTANTS.USER_STATUS.INACTIVE
        ? CONSTANTS.USER_STATUS.ACTIVE
        : CONSTANTS.USER_STATUS.INACTIVE
    );
    setUserId(id);
    setModalContent(
      updateModalContent(getStatusAction(status ? "active" : "inactive"), name)
    );
    toggle();
  };
  const userRestrictAccessForStatusChange = (userEmail: any) => {
    if (typeof userEmail === "undefined") return false;
    return userProfile.email !== userEmail;
  };

  const UserApiCaller = (id: number, status: string) => {
    if (apiStatus === "status") {
      UserStatusChanger(id, status)
        .then((response) => {
          if (response?.data?.message) {
            store.dispatch(
              showToast({
                type: "success",
                title: "Success",
                message: "Duplicate action - User role was already deactivated",
              })
            );
            setUserEllipsesPopover(false);
          } else {
            setToggleStatus(!toggleStatus);
            if (status === "ACTIVE") {
              store.dispatch(
                showToast({
                  type: "success",
                  title: "Success",
                  message: "User activated successfully",
                })
              );
              /* history.go(0); */
              fetchUserDetailsAPI(false);
            } else {
              store.dispatch(
                showToast({
                  type: "success",
                  title: "Success",
                  message: "User deactivated successfully",
                })
              );
              /* history.go(0); */
              fetchUserDetailsAPI(false);
            }
            setUserEllipsesPopover(false);
          }
        })
        .catch((error) => {
          store.dispatch(
            showToast({
              title: "Error Occurred.",
              message: getErrorMessage(error),
            })
          );
          /*  history.go(0); */
        });

      toggleUserActionEllipses();
    } else if (apiStatus === "delete") {
      userDelete(id)
        .then((response) => {
          setToggleStatus(!toggleStatus);
          store.dispatch(
            showToast({
              type: "success",
              title: "Success",
              message: "User deleted successfully",
            })
          );
          history.push("/users");
        })
        .catch((errors) => {
          store.dispatch(
            showToast({
              title: "Error",
              message: getErrorMessage(errors),
            })
          );
          /* history.go(0); */
        });
    }
  };

  return (
    <Wrapper className="d-flex flex-column w-100">
      <ViewUserDetailsWrapperStyles className="respStylesWrapper">
        <div className="d-lg-flex mt-md-4 m-sm-2 pl-lg-3 p-sm-2 respStylesHeadingWrapper">
          <div className="container flex-grow-1 pl-4">
            <div className="mt-lg-1 row ml-gutter-row-bkp">
              <div className="col-md-1 hideColumn" />
              <div className="col-lg-10 col-md-12 user-details-block">
                <h1
                  className="user-heading"
                  aria-label={`Users ${selectedUserObject.firstName} ${selectedUserObject.lastName}`}
                  data-testid="test-viewUser"
                >
                  Users <div className="user-hyphen">-</div>
                  <span className="primary-color header-title pl-1">
                    {selectedUserObject.firstName}&nbsp;
                    {selectedUserObject.lastName}
                  </span>
                </h1>
                <div className=" esa-block">
                  <div className="user-email">
                    <i className="aha-icon-email" />
                    <div>{selectedUserObject.email}</div>
                  </div>

                  <div className="user-status d-flex">
                    <div
                      className={`user-status-adp ${getClassNameUserStatus()}`}
                    >
                      {selectedUserObject.status &&
                        lowerCaseAllWordsExceptFirstLetters(
                          selectedUserObject.status
                        )}
                    </div>

                    <div className="user-action ">
                      <div className="ml-3 text-right pr-0 tooltip-role">
                        {userRestrictAccessForStatusChange(
                          selectedUserObject.email
                        ) && showEllipse ? (
                          <>
                            <button
                              type="button"
                              data-testid="View More Option"
                              aria-label="View More Option"
                              className="noBtnStyle btnEllipses rotate-90-anti p-0"
                              tabIndex={0}
                              aria-expanded={false}
                              onClick={(e) => {
                                togglePopover(e);
                              }}
                              onKeyUp={(e) => {
                                if (e.key === "Spacebar") togglePopover(e);
                              }}
                            >
                              <i className="aha-icon-meat-balls" />
                            </button>
                            <span className="tooltiptext">View More</span>
                            <div className="d-none connection-td-wrapper">
                              <div className="popoverWrapper">
                                <button
                                  type="button"
                                  className="noBtnStyle mb-2"
                                  onClick={async (e) => {
                                    setUserEllipsesPopover(true);
                                    if (
                                      selectedUserObject.status?.toLocaleLowerCase() !==
                                      "pending"
                                    ) {
                                      userStatusButtonHandler(
                                        selectedUserObject.status,
                                        selectedUserObject.id,
                                        selectedUserObject.name
                                      );
                                      setModalContent(
                                        updateModalContent(
                                          getStatusAction(
                                            selectedUserObject.status
                                          ),
                                          selectedUserObject.name
                                        )
                                      );
                                    }
                                    setApiStatus("status");
                                    setUserId(selectedUserObject.id);
                                    setRoleName(selectedUserObject.roles);
                                    setMainIcon(
                                      getMainIcon(
                                        getStatusAction(
                                          selectedUserObject.status
                                        )
                                      )
                                    );

                                    getMessage(
                                      getStatusAction(selectedUserObject.status)
                                    );
                                    if (
                                      selectedUserObject.status?.toLocaleLowerCase() ===
                                      "pending"
                                    ) {
                                      await reInviteUserAction(
                                        `${selectedUserObject.id}`,
                                        togglePopverOnWrapperClick,
                                        e
                                      );
                                    }
                                  }}
                                >
                                  {getStatusAction(selectedUserObject.status)}
                                </button>
                                <button
                                  type="button"
                                  className="noBtnStyle mb-2"
                                  onClick={() => {
                                    setUserEllipsesPopover(true);
                                    setApiStatus("delete");
                                    setMainIcon(
                                      getMainIcon(getStatusAction(""))
                                    );
                                    setUserId(selectedUserObject.id);
                                    setRoleName(selectedUserObject.roles);
                                    setModalContent(
                                      updateModalContent(
                                        getStatusAction(""),
                                        selectedUserObject.name
                                      )
                                    );
                                    getMessage(getStatusAction(""));
                                    toggle();
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>

            <div className="row offset-2-bkp ">
              <div className="col-md-1 hideColumn" />
              <div className="col-md-10">
                <div className="row ml-gutter-row-bkp">
                  <div className="col-lg-4 col-md-7 pr-sm-0 col-sm-6 cardWidth">
                    <h2
                      className="roleHeading offset-2-bkp mb-lg-3"
                      aria-label="Roles"
                      data-testid="Roles"
                    >
                      Roles
                    </h2>
                    {shouldShowAddRole &&
                      userRestrictAccessForStatusChange(
                        selectedUserObject.email
                      ) && (
                        <div
                          role="button"
                          tabIndex={0}
                          className="addRoleBox addRoleWrapper"
                          data-testid="addRoleBox"
                          onClick={() => {
                            toggleAddRoleModal && resetAddRoleInitialView();
                            getAddRolesDataAPI();
                            toggleModalStyles();
                            setToggleAddRoleModal(true);
                          }}
                          onKeyDown={(e: any) => {
                            if (e.key === "Enter" || e.key === "Spacebar") {
                              toggleAddRoleModal && resetAddRoleInitialView();
                              getAddRolesDataAPI();
                              toggleModalStyles();
                              setToggleAddRoleModal(true);
                            }
                          }}
                        >
                          <div className="editbtn" data-testid="add-role-btn">
                            <img alt="" src="../images/IconPlusCircle.svg" />
                          </div>
                          <p className="addRoleHeading">Add Role</p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {roleCardStateSA.length > 0 && (
              <div className="row mt-5 no-gutters cardSecWrapper">
                <div className="col-md-1 hideColumn" />
                <div className="col-md-10">
                  <div className="col-md-12 responsiveBadgeStyle">
                    <p className="roleStylesMin roleColorCodeSA badgeHeading">
                      Super Admin
                    </p>
                  </div>
                </div>
                <div className="col-lg-1" />
                <div className="offset-1-bkp col-lg-10 col-md-12 d-flex-bkp flex-wrap-bkp">
                  <div className="row ml-gutter-row">
                    {generateGroupedRoleCards(roleCardStateSA)}
                  </div>
                </div>
              </div>
            )}

            {roleCardStateCL.length > 0 && (
              <div className="row mt-5 no-gutters cardSecWrapper">
                <div className="col-md-1 hideColumn" />
                <div className="col-md-10">
                  <div className="col-md-12 responsiveBadgeStyle">
                    <p className="roleStylesMin roleColorCodeCL badgeHeading">
                      Clients
                    </p>
                  </div>
                </div>
                <div className="col-lg-1" />
                <div className="offset-1-bkp col-lg-10 col-md-12 d-flex-bkp flex-wrap-bkp">
                  <div className="row ml-gutter-row">
                    {generateGroupedRoleCards(roleCardStateCL)}
                  </div>
                </div>
              </div>
            )}

            {userRestrictAccessForStatusChange(selectedUserObject.email) &&
            shouldShowAddRole ? (
              <div className="btn-background d-block d-sm-none ">
                <div
                  className={`btnbottom d-flex btnGrp-addTanant ${checkBoxShadow()}`}
                >
                  <button
                    type="button"
                    aria-label="Add Role"
                    className="btn btn-round btn-block btn-primary btnwidth addRoleMobBtn ml-3"
                    onClick={() => {
                      toggleAddRoleModal && resetAddRoleInitialView();
                      getAddRolesDataAPI();
                      toggleModalStyles();
                      setToggleAddRoleModal(true);
                    }}
                  >
                    Add Role
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {toggleAddRoleModal ? (
          <FocusTrap
            focusTrapOptions={{
              initialFocus: false,
              fallbackFocus: `.modal`,
              escapeDeactivates: false,
              clickOutsideDeactivates: false,
            }}
          >
            <div
              className="modal addRoleModal show aui-org-modal aui-new-org aui-modal"
              id="org9"
              tabIndex={-1}
              aria-modal="true"
              aria-label="Add new role details modal window"
              role="dialog"
            >
              <div className="modal-dialog modal-md editaccess-modal modalwidth">
                <div className="modal-content">
                  <div
                    className={`aui-block-loader ${
                      imageLoading ? "d-flex" : "d-none"
                    }`}
                    role="alert"
                    aria-live="assertive"
                    aria-label="Modal Content Loading"
                  />
                  <div
                    className={`modal-content-wrapper ${
                      imageLoading ? "d-none" : "d-block"
                    }`}
                  >
                    <button
                      ref={RefFocus}
                      type="button"
                      className="close d-none d-sm-block"
                      onClick={() => {
                        toggleModalStyles();
                        resetAddRoleInitialView();
                        setToggleAddRoleModal(false);
                        setImageLoading(true);
                      }}
                      aria-label="Close"
                    >
                      <img
                        src={imageUrl}
                        data-testid="crossbtn"
                        onLoad={imageLoaded}
                        alt=""
                        className="position-relative closestyle"
                      />
                    </button>
                    <div className="modal-header">
                      <h2
                        className="add-heading font-600 d-flex"
                        aria-label="Add Role"
                        data-testid="test-addrole"
                      >
                        <span
                          className="d-block d-sm-none rotateInverse mr-2"
                          onClick={() => {
                            toggleModalStyles();
                            setToggleAddRoleModal(false);
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Spacebar") {
                              toggleModalStyles();
                              setToggleAddRoleModal(false);
                            }
                          }}
                        >
                          <i className="aha-icon-right-arrow-thick" />
                        </span>
                        Add Role
                      </h2>
                    </div>
                    <p className="instruction">
                      {CONSTANTS.MODAL_INSTRUCTION_TEXT}
                    </p>
                    <form onSubmit={handleSubmit(submitFormAddRoleModal)}>
                      <div className="modal-body add-role pt-3">
                        <div className="form-group row required mb-4">
                          <label
                            htmlFor="selectRole"
                            className="col-sm-4 col-form-label"
                            aria-label="Select Role"
                          >
                            Select Role
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control dropdown "
                              defaultValue=""
                              onChange={(e) => {
                                dropDownDecider(e);
                                setRoleAdded(true);
                              }}
                              id="selectRole"
                              name="roleCode"
                              aria-required="true"
                              onClick={(e) => {
                                dropDownToggler(e);
                              }}
                              onBlur={(e) => {
                                dropDownDefaultState(e);
                              }}
                              ref={register}
                            >
                              <option value="" disabled hidden selected>
                                Select
                              </option>

                              {addRoleDropDownData.map((item: any) => {
                                return (
                                  <option key={item.id} value={item.code}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                            <i className="aha-icon-arrow-down forModal" />
                          </div>
                        </div>

                        <div
                          className={
                            viewClientSelect
                              ? "form-group row required mb-4 select-client-section"
                              : "d-none"
                          }
                        >
                          <label
                            htmlFor="selectClient"
                            className="col-sm-4 col-form-label"
                            aria-label="Select Client"
                          >
                            Select Client
                          </label>
                          <div className="col-sm-8 tooltip-role">
                            <div className="select-client" id="Client-tooltip">
                              <select
                                className="form-control dropdown "
                                defaultValue=""
                                onChange={(e) => {
                                  setClientAdded(true);
                                  setSelectClientValue(
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  );
                                }}
                                name="clientId"
                                aria-label="Select Client"
                                aria-required="true"
                                id="selectClient"
                                onClick={(e) => {
                                  dropDownToggler(e);
                                }}
                                onBlur={(e) => {
                                  dropDownDefaultState(e);
                                }}
                                ref={register}
                              >
                                <option value="" disabled hidden selected>
                                  Select
                                </option>
                                {AddRoleClientData.map((item: any) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <i className="aha-icon-arrow-down forModal" />
                              <span className="tooltiptext">
                                {selectClientValue === ""
                                  ? "select client"
                                  : selectClientValue}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-none d-sm-block mt-0 pt-0">
                        <div className="justify-content-end d-flex btnGrp-add">
                          <button
                            type="button"
                            aria-label="cancel"
                            className="btn btn-round btn-secondary mr-4 btnwidth"
                            onClick={() => {
                              toggleModalStyles();
                              resetAddRoleInitialView();
                              setToggleAddRoleModal(false);
                              setImageLoading(true);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            aria-label="Add Role"
                            className="btn btn-round  btn-primary btnwidth"
                            disabled={buttonStatusSetter()}
                            onClick={() => {
                              setBtnDisable(false);
                            }}
                          >
                            Add Role
                          </button>
                        </div>
                      </div>
                      <div className="modal-footer d-block d-sm-none mt-0 pt-0">
                        <div className="btn-block btnbottom position-absolute btnGrp-add">
                          <button
                            type="submit"
                            aria-label="save"
                            className="btn btn-round btn-block btn-primary btnwidth"
                            disabled={buttonStatusSetter()}
                            onClick={() => {
                              setBtnDisable(false);
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </FocusTrap>
        ) : null}

        {toggleEditAccessModal && (
          <FocusTrap
            focusTrapOptions={{
              initialFocus: false,
              fallbackFocus: `#org10`,
              escapeDeactivates: false,
              clickOutsideDeactivates: false,
            }}
          >
            <div
              className="modal show editRoleModal aui-org-modal aui-new-org aui-modal"
              id="org10"
              tabIndex={-1}
              aria-modal="true"
              aria-labelledby="edit-access-title"
              role="dialog"
            >
              <div className="modal-dialog modal-md editaccess-modal modalwidth">
                <div className="modal-content">
                  <div
                    className={`aui-block-loader ${
                      imageLoading ? "d-flex" : "d-none"
                    }`}
                    role="alert"
                    aria-live="assertive"
                    aria-label="Modal Content Loading"
                  />
                  <div
                    className={`modal-content-wrapper ${
                      imageLoading ? "d-none" : "d-block"
                    }`}
                  >
                    <button
                      ref={RefFocus}
                      type="button"
                      className="close d-none d-sm-block"
                      onClick={() => {
                        toggleModalStyles();
                        setToggleEditAccessModal(false);
                        setImageLoading(true);
                      }}
                      aria-label="Close"
                    >
                      <img
                        src={imageUrl}
                        data-testid="crossbtn"
                        onLoad={imageLoaded}
                        alt=""
                        className="position-relative closestyle"
                      />
                    </button>
                    <div className="modal-header">
                      <h2
                        className="add-heading font-600 d-flex"
                        id="edit-access-title"
                        aria-label="Edit Access"
                        data-testid="test-addrole"
                      >
                        <span
                          className="d-block d-sm-none rotateInverse mr-2"
                          onClick={() => {
                            toggleModalStyles();
                            setToggleEditAccessModal(false);
                            document.querySelector<any>("#editRole").value =
                              "selected";
                          }}
                          onKeyUp={() => {
                            toggleModalStyles();
                            setToggleEditAccessModal(false);
                          }}
                        >
                          <i
                            className="aha-icon-right-arrow-thick"
                            data-dismiss="modal"
                          />
                        </span>
                        Edit Access
                      </h2>
                      <div className="ml-2 groupedRoleStyle">
                        <p
                          className={`roleStylesMin ${
                            getColorCodenCatName(roleObjSwitchAccess)
                              .roleStyleCls
                          }`}
                        >
                          {roleObjSwitchAccess.roleName}
                        </p>
                      </div>
                    </div>
                    <p className="instruction">
                      {CONSTANTS.MODAL_INSTRUCTION_TEXT}
                    </p>
                    <form onSubmit={() => {}}>
                      <div className="modal-body pt-3">
                        <div className="form-group row mb-4">
                          <label
                            htmlFor="editRole"
                            className="col-sm-9 col-form-label"
                          >
                            Are you sure you want to change access?
                          </label>
                        </div>
                        <div className="form-group row required mb-4">
                          <label
                            htmlFor="editRole"
                            aria-label="Update role"
                            className="col-sm-4 col-form-label"
                          >
                            Update Role
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control dropdown "
                              defaultValue="selected"
                              aria-required="true"
                              onChange={(e) => {
                                if (e.target.value === "selected") {
                                  setEditRole(false);
                                  setRoleAdded(false);
                                } else {
                                  setRoleAdded(true);
                                  setEditRole(true);
                                  setRoleCodeApi(e.target.value);
                                }
                              }}
                              name="role"
                              id="editRole"
                              onClick={(e) => {
                                dropDownToggler(e);
                              }}
                              onBlur={(e) => {
                                dropDownDefaultState(e);
                              }}
                            >
                              <option value="selected" disabled hidden selected>
                                Select
                              </option>
                              {levelDropDownData
                                .filter(
                                  (filteredObj: any) =>
                                    filteredObj.name !==
                                    roleObjSwitchAccess.roleName
                                )
                                .map((item: any) => {
                                  return (
                                    <option key={item.id} value={item.code}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                            <i className="aha-icon-arrow-down forModal" />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-none d-sm-block mt-0 pt-0">
                        <div className="justify-content-end d-flex btnGrp-add">
                          <button
                            type="button"
                            aria-label="cancel"
                            className="btn btn-round btn-secondary mr-4 btnwidth"
                            data-dismiss="modal"
                            onClick={() => {
                              toggleModalStyles();
                              setToggleEditAccessModal(false);
                              document.querySelector<any>("#editRole").value =
                                "selected";
                              setImageLoading(true);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            aria-label="save"
                            className="btn btn-round btn-primary btnwidth"
                            disabled={!editRole}
                            data-dismiss="modal"
                            onClick={() => {
                              setBtnDisable(false);
                              ApiCaller(modalApiId, roleStatusModal);
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="modal-footer d-block d-sm-none mt-0 pt-0">
                        <div className="btn-block btnbottom position-absolute btnGrp-add">
                          <button
                            type="button"
                            aria-label="save"
                            className="btn btn-round btn-block btn-primary btnwidth"
                            disabled={!roleAdded}
                            data-dismiss="modal"
                            onClick={() => {
                              setBtnDisable(false);
                              ApiCaller(modalApiId, roleStatusModal);
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </FocusTrap>
        )}
        <Modal
          isShown={isShown}
          hide={toggle}
          id={userEllipsesPopover ? userId : modalApiId}
          action={userEllipsesPopover ? UserApiCaller : ApiCaller}
          status={userEllipsesPopover ? userStatus : roleStatusModal}
          modalContent={modalContent}
          roles={userEllipsesPopover ? roleName : []}
          message={userEllipsesPopover ? message : ""}
          mainIcon={mainIcon}
        />
      </ViewUserDetailsWrapperStyles>
    </Wrapper>
  );
};

export default ViewUserDetailsComp;
