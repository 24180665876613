/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {
  useEffect,
  useState,
  FunctionComponent,
  useContext,
  useRef,
} from "react";
import { Wrapper } from "styled";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CONSTANTS from "common/constants";
import Modal from "components/Modal";
import { getStatusAction, updateModalContent } from "pages/Users/util";
import Multiselect from "multiselect-react-dropdown";
import {
  getMainIcon,
  lowerCaseAllWordsExceptFirstLetters,
  getXYPosition,
  stripHtml,
  ssouserProfile,
  getXYPosRelativeToParent,
  toggleModalStyles,
  getErrorMessage,
  toggleUserActionEllipses,
} from "common/utils";
import store from "app/store";
import { setHeaderTitleText } from "components/Header/header.slice";
import { getUsers } from "services/api/user.api";
import Pagination from "components/Pagination/index";
import { hideToast, showToast } from "components/Toast/toast.slice";
import { ClientListService } from "services/api/clients.api";
import { UserStatusChanger, userDelete } from "services/api/userDelete";
import { getRoles } from "services/api/settings.api";
import Restricted from "services/PermissionManager/Restricted";
import PermissionContext from "services/PermissionManager/PermissionContext";
import { useSelector } from "react-redux";
import {
  inviteUserService,
  reInviteUserService,
} from "services/api/inviteUser.api";
import { fetchPermission } from "services/PermissionManager/Types";
import AutoSuggestComp from "components/AutoSuggest";
import NoDataComp from "components/NoData";
import FocusTrap from "focus-trap-react";
import { logger } from "common/utils/logger.utils";
import { UserWrapperStyles } from "./Styled";

interface UserListProps {
  id: number;
  email: string;
  firstName: string;
  isActive: boolean;
  lastName: string;
  name: string;
  roles: Array<Roles>;
  status: string;
  createdAt: string;
  updatedAt: string;
}

interface Roles {
  clientId: number;
  clientName: string;
  roleId: number;
  roleName: string;
  userRoleStatus: string;
  status: string;
  updatedAt: string;
}

interface IPagination {
  pageNumber: number;
  pageOffset: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  isFirst: number;
  isLast: number;
}

const defaultValues: IPagination = {
  pageNumber: 1,
  pageOffset: 0,
  pageSize: 20,
  totalCount: 0,
  totalPages: 0,
  isFirst: 0,
  isLast: 0,
};
export interface IUserInvite {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  roleCode: string;
  clientId: string;
}

const UsersComp: FunctionComponent = (props: any) => {
  const { loggedInUserObjectContext } = useContext(PermissionContext);
  const [roleSelected, setRoleSelected] = useState("");
  let UIschema: any = "";
  if (loggedInUserObjectContext !== null) {
    UIschema = yup.object().shape({
      firstName: yup
        .string()
        .required("User First Name is Required")
        .matches(
          CONSTANTS.REGEX.ATLEAST_ONE_ALPHANUMERIC,
          "User First Name should contain atleast one alpha numeric character"
        )
        .max(255, "Maximum 255 characters allowed"),
      lastName: yup
        .string()
        .required("User Last Name is Required")
        .matches(
          CONSTANTS.REGEX.ATLEAST_ONE_ALPHANUMERIC,
          "User Last Name should contain atleast one alpha numeric character"
        )
        .max(255, "Maximum 255 characters allowed"),
      email: yup
        .string()
        .matches(CONSTANTS.REGEX.EMAIL, "Please enter a valid email address"),
      role: yup.string().required("Role is Required"),
      clientId:
        loggedInUserObjectContext?.roleCode ===
          CONSTANTS.USER_ROLES.SUPER_ADMIN &&
        ["CLIENT_USER", "CLIENT_ADMIN"].includes(roleSelected)
          ? yup.number().required("Client is Required")
          : yup.number().notRequired(),
    });
  }

  const imageUrl = "../images/Close.svg";
  const [imageLoading, setImageLoading] = useState(true);
  const imageLoaded = () => {
    setImageLoading(false);
  };

  const { register, handleSubmit, reset, errors, getValues } = useForm({
    resolver: yupResolver(UIschema),
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isError, setIsError] = useState(false);
  const [dataDismiss, setDataDismiss] = useState("");
  const [userInviteData, setUserInviteData] = useState<IUserInvite>({
    firstName: "",
    lastName: "",
    email: "",
    clientId: "0",
    role: "",
    roleCode: "",
  });

  const clientArr = ["CLIENT_USER", "CLIENT_ADMIN"];
  const [isShown, setIsShown] = useState<boolean>(false);
  const [showClientDropDown, setShowClientDropDown] = useState<boolean>(false);
  const [shouldShowPopover, setShouldShowPopover] = useState<boolean>(false);
  const [popover, setPopver] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState("");
  const [userData, setUserData] = useState<UserListProps[]>([]);
  const [multiSelectCountRoles, setMultiSelectCountRoles] = useState("");
  const [multiSelectCountClient, setMultiSelectCountClient] = useState("");
  const [searchFieldPlaceHolder, setSearchFieldPlaceHolder] =
    useState("Search Mail / Name");
  const [isTableLoading, setTableLoading] = useState<boolean>();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [pagination, setPagination] = useState(defaultValues);
  const [pageSize, setPageSize] = useState<number>(20);
  const [rangeArr] = useState<any[]>([20, 50, 100]);
  const [message, setMessage] = useState("");
  const [mainIcon, setMainIcon] = useState("Lock");
  const [toggleStatus, setToggleStatus] = useState(false);
  const [apiStatus, setApiStatus] = useState("");
  const [userId, setUserId] = useState(0);
  const [roleName, setRoleName] = useState<any>([]);
  const [userStatus, setUserStatus] = useState("");
  const [selectClientValue, setSelectClientValue] = useState<any>("");

  const [toggleModal, setToggleModal] = useState(false);
  // For Accessibility
  const RefFocus = useRef<any>();
  useEffect(() => {
    if (toggleModal && RefFocus.current && !imageLoading)
      RefFocus.current.focus();
  }, [toggleModal, imageLoading]);

  const getRoleCode = (roleNameStr: string) => {
    if (["SUPER_ADMIN", "SUPER_USER"].includes(roleNameStr)) {
      setShowClientDropDown(false);
    }
    return roleNameStr;
  };

  // To handle if user invites super admin / super user after selecting client from dropdown
  useEffect(() => {
    if (
      roleSelected === CONSTANTS.USER_ROLES.SUPER_ADMIN ||
      roleSelected === CONSTANTS.USER_ROLES.SUPER_USER
    )
      setUserInviteData({ ...userInviteData, clientId: "0" }); // reset cliet id to 0
  }, [roleSelected]);

  /* Auto Suggest State changes  - start */
  const [autoSuggestValue, setAutoSuggestValue] = useState<any>("");
  const [selectedUserLookup, setSelectedUserLookup] = useState<any>("");
  const [isSuperAdminOrClientAdmin, setIsSuperAdminOrClientAdmin] =
    useState(false);

  // handler for onchange invite form
  const onChangeHandler = (event: any) => {
    const { name, value } = event.target;
    if (name === "clientId") {
      setDefaultClientId(value);
    }

    setUserInviteData({ ...userInviteData, ...getValues() });
    if (name === "role") {
      setRoleSelected(value);
      !showClientDropDown && setDefaultClientId("");
      setUserInviteData({ ...userInviteData, roleCode: getRoleCode(value) });
    }
  };

  useEffect(() => {
    if (
      loggedInUserObjectContext?.roleCode ===
        CONSTANTS.USER_ROLES.SUPER_ADMIN ||
      loggedInUserObjectContext?.roleCode === CONSTANTS.USER_ROLES.CLIENT_ADMIN
    ) {
      setIsSuperAdminOrClientAdmin(true);
    }
  }, [loggedInUserObjectContext]);
  /* Auto Suggest State changes  - end */

  let prevScrollposBtnSec = window.pageYOffset;

  const makeBtnSectionStickyMobile = () => {
    const elBtn = document.getElementsByClassName("btn-background");
    window.addEventListener("scroll", () => {
      const currentScrollPosBtnSec = window.pageYOffset;

      if (prevScrollposBtnSec > currentScrollPosBtnSec) {
        // showing the ui element - scroll up
        Array.from(elBtn).forEach((item) => {
          item.classList.remove("bottom-70");
          item.classList.add("bottom-0");
        });
      } else {
        // hiding the fixed element - scroll down
        Array.from(elBtn).forEach((item) => {
          item.classList.remove("bottom-0");
          item.classList.add("bottom-70");
        });
      }

      const { x, y: yPos } = getXYPosition(
        document.querySelector<any>("footer")
      );

      // if (currentScrollPosBtnSec > yPos - 500) {
      //   Array.from(elBtn).map((item) => {
      //     item.classList.remove("bottom-70");
      //     return item.classList.add("bottom-0");
      //   });
      // }
      prevScrollposBtnSec = currentScrollPosBtnSec;
    });
  };

  useEffect(() => {
    window.innerWidth < 576 && makeBtnSectionStickyMobile();
  }, [prevScrollposBtnSec]);

  const stateObject = useSelector((state: any) => {
    let role;
    let clientId;
    let clientName;
    if (state.user.user) {
      role = state.user.user.roleName;
      clientId = state.user.user.clientId;
      clientName = state.user.user.clientName;
    }
    return { role, clientId, clientName };
  });
  const toggle = () => {
    document.querySelector(".modal-backdrop")?.classList.toggle("show");
    document.querySelector("body")?.classList.toggle("modal-open");
    return setIsShown(!isShown);
  };

  const dropDownToggler = (e: any) => {
    e.currentTarget.nextSibling.classList.toggle("rotate");
  };

  const dropDownDefaultState = (e: any) => {
    if (e.currentTarget.nextSibling.classList.contains("rotate") === true) {
      e.currentTarget.nextSibling.classList.remove("rotate");
    }
  };

  const rolesFilterRef: any = React.createRef();
  const clientRef: any = React.createRef();

  const [rolesFilterData, setRolesFilterData] = useState([]);
  const [clientsFilterData, setClientsFilterData] = useState([]);

  const [recordArrRoles, setRecordRoles] = useState<string[]>([]);
  const [recordArrClients, setRecordClients] = useState<string[]>([]);
  const [defaultClientId, setDefaultClientId] = useState<any>();
  const [defaultParams] = useState<any>({
    pageSize: 20,
    pageNumber: 1,
  });
  const [togglePopover, setTogglePopover] = useState<boolean>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [searchField, setSearchField] = useState<string>("");
  const [togglePopoverAria, setTogglePopoverAria] = useState<boolean>();

  const columnHeaders = [
    { name: "Name", id: 1 },
    { name: "Email ", id: 2 },
    { name: "Role", id: 3 },
    { name: "Status", id: 4 },
    { name: "Action", id: 5 },
  ];
  const getMessage = (action: string) => {
    let msg = ``;

    action = action.toLocaleLowerCase();

    if (action === "activate") {
      msg = `Activating user will associate following roles`;
    } else if (action === "deactivate") {
      msg = `Deactivating user will disassociate following roles`;
    } else if (action === "delete") {
      msg = `Deleting user will disassociate following roles.
       You won’t be able to revert this`;
    } else {
      msg = ``;
    }

    setMessage(msg);
  };

  const rotateMeatBalls = (e: any) => {
    Array.from(document.querySelectorAll(".btnEllipses")).forEach((el) => {
      if (e.currentTarget === el) {
        if (el.classList.contains("rotate-90")) {
          el.classList.remove("rotate-90");
          el.classList.toggle("rotate-90-anti");
        } else if (el.classList.contains("rotate-90-anti")) {
          el.classList.remove("rotate-90-anti");
          el.classList.toggle("rotate-90");
        } else {
          el.classList.toggle("rotate-90");
        }
      } else {
        el.classList.remove("rotate-90");
      }
    });
  };
  const togglePopver = (e: any) => {
    Array.from(document.querySelectorAll(".connection-td-wrapper")).forEach(
      (el) => {
        if (e.currentTarget?.nextSibling !== el) {
          el.classList.remove("d-flex", "d-none");
          el.classList.add("d-none");
        }
      }
    );
    setTogglePopoverAria(!togglePopoverAria);
    e.currentTarget.nextSibling?.classList.toggle("d-none");
    rotateMeatBalls(e);
  };
  const togglePopverOnWrapperClick = (e: any) => {
    e.target.parentNode.parentNode.classList.toggle("d-none");
    rotateMeatBalls(e);
  };
  // here add the api request for user(Activate, deactivate)
  const ApiCaller = (id: number, status: string) => {
    if (apiStatus === "status") {
      UserStatusChanger(id, status)
        .then((response) => {
          if (response?.data?.message) {
            store.dispatch(
              showToast({
                type: "success",
                title: "Success",
                message: "Duplicate action - User role was already deactivated",
              })
            );
            toggleUserActionEllipses();
            /* setUserEllipsesPopover(false); */
          } else {
            setToggleStatus(!toggleStatus);
            if (status === "ACTIVE") {
              store.dispatch(
                showToast({
                  type: "success",
                  title: "Success",
                  message: "User activated successfully",
                })
              );
            } else {
              store.dispatch(
                showToast({
                  type: "success",
                  title: "Success",
                  message: "User deactivated successfully",
                })
              );
            }
          }
        })
        .catch((error) => {
          store.dispatch(
            showToast({
              title: "Error Occurred.",
              message: getErrorMessage(error),
            })
          );
        });
    } else if (apiStatus === "delete") {
      userDelete(id)
        .then((response) => {
          setToggleStatus(!toggleStatus);
          store.dispatch(
            showToast({
              type: "success",
              title: "Success",
              message: "User deleted successfully",
            })
          );
        })
        .catch((errors) => {
          store.dispatch(
            showToast({
              title: "Error",
              message: getErrorMessage(errors),
            })
          );
        });
    }
  };
  const closeActionPopover = (event: any) => {
    if (
      (event.target.closest(".connection-td-wrapper") !== null &&
        !event.target.closest(".connection-td-wrapper")) ||
      !event.target.closest(".btnEllipses")
    ) {
      Array.from(document.querySelectorAll(".connection-td-wrapper")).forEach(
        (el) => {
          el.classList.remove("d-flex", "d-none");
          el.classList.add("d-none");
        }
      );
    }
  };

  const getUserList = (paramsObj: any) => {
    setTableLoading(true);
    getUsers({ paramsData: paramsObj })
      .then((response) => {
        if (response.data.users === null) {
          setPagination(defaultValues);
          setUserData([]);
          setShowSearch(false);
        } else {
          const paginationRes = { ...response._pagination };
          setUserData(response.data.users);
          setPagination(paginationRes);
          if (response.data.users.length === 0) {
            paramsObj.q &&
              paramsObj.q.trim().length > 0 &&
              setShouldShowPopover(true);
          }
          setShowSearch(true);
        }
        return { ...response._pagination };
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occurred",
            message: getErrorMessage(error),
          })
        );
        setShowSearch(false);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };
  const statusButtonHandler = (status: string, id: number, name: string) => {
    setUserStatus(
      status === CONSTANTS.USER_STATUS.INACTIVE
        ? CONSTANTS.USER_STATUS.ACTIVE
        : CONSTANTS.USER_STATUS.INACTIVE
    );
    setUserId(id);
    setModalContent(
      updateModalContent(getStatusAction(status ? "active" : "inactive"), name)
    );
    toggle();
  };
  const history = useHistory();

  const keydownToggleNoOfRecordTableFilter = (e: any) => {
    if (
      e.keyCode === CONSTANTS.KEY_ENTER ||
      e.keyCode === CONSTANTS.KEY_SPACEBAR
    ) {
      e.stopPropagation();
      e.preventDefault();
      e.currentTarget.nextSibling.classList.toggle("visible_cls");
      e.currentTarget.nextSibling.classList.contains("visible_cls") === true
        ? e.currentTarget.setAttribute("aria-expanded", "true")
        : e.currentTarget.setAttribute("aria-expanded", "false");
    }
  };

  const toggleNoOfRecordTableFilter = (e: any) => {
    e.currentTarget.nextSibling.classList.toggle("visible_cls");
    e.currentTarget.classList.toggle("rotateArrow");
  };

  const checkStatus = (statusFlg: any) => {
    switch (statusFlg) {
      case CONSTANTS.ROLE_STATUS.ACTIVE:
        return "aui-table-status-success";
      case CONSTANTS.ROLE_STATUS.INACTIVE:
        return "aui-table-status-deactivated";
      case CONSTANTS.ROLE_STATUS.PENDING:
        return "aui-table-status-pending";
      default:
        return "aui-table-status-success";
    }
  };

  const toggleRolePopover = (event: any) => {
    // get Coordinates dynamically
    const { x, y } =
      window.outerWidth > 991
        ? getXYPosRelativeToParent(
            event.currentTarget,
            event.currentTarget.parentNode.parentNode
          )
        : getXYPosition(event.currentTarget);

    if (window.outerWidth > 319 && window.outerWidth < 376) {
      event.currentTarget.nextSibling.style.right = `${2 * x}px`;
      event.currentTarget.nextSibling.style.top = `${y - 10}px`;
    } else if (window.outerWidth > 375 && window.outerWidth < 576) {
      event.currentTarget.nextSibling.style.left = `${x - 10}px`;
      event.currentTarget.nextSibling.style.top = `${y - 10}px`;
    } else if (window.outerWidth > 575 && window.outerWidth < 768) {
      event.currentTarget.nextSibling.style.right = `${x - 470}px`;
      event.currentTarget.nextSibling.style.top = `${y - 10}px`;
    } else if (window.outerWidth > 767 && window.outerWidth < 992) {
      event.currentTarget.nextSibling.style.left = `${x - 10}px`;
      event.currentTarget.nextSibling.style.top = `${y - 15}px`;
    } else if (window.outerWidth > 991 && window.outerWidth < 1600) {
      event.currentTarget.nextSibling.style.left = `${x - 10}px`;
      event.currentTarget.nextSibling.style.top = `${y - 10}px`;
    }
    event.currentTarget.classList?.toggle("popover-open");
    event.currentTarget.nextSibling.classList?.toggle("d-none");
  };

  const getColorCode = (roleObjColor: any) => {
    let combinedCls = "";
    if (roleObjColor.userRoleStatus === "INACTIVE") {
      combinedCls = "roleColorCodeINACTIVE";
    }
    switch (roleObjColor.roleId) {
      case 1:
        return `roleColorCodeSA ${combinedCls}`;
      case 4:
        return `roleColorCodeCPU ${combinedCls}`;
      case 5:
        return `roleColorCodeEU ${combinedCls}`;
      case 6:
        return `roleColorCodeSA ${combinedCls}`;
      default:
        return "";
    }
  };
  const generateHelpIcons = (roleObj: any) => {
    // super admin
    if (roleObj.roleId === 1) {
      return (
        <span className="tooltip-role">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserSetting.svg"
            alt="admin"
          />
          <span className="tooltiptext">Admin</span>
        </span>
      );
    }
    // super user
    if (roleObj.roleId === 6) {
      return (
        <span className="tooltip-role">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserShield.svg"
            alt="View Only"
          />
          <span className="tooltiptext">View Only</span>
        </span>
      );
    }
    // client admin
    if (roleObj.roleId === 4) {
      return (
        <span className="tooltip-role">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserPen.svg"
            alt="View and Edit"
          />
          <span className="tooltiptext">View & Edit</span>
        </span>
      );
    }
    // client user
    if (roleObj.roleId === 5) {
      return (
        <span className="tooltip-role">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserPen-Slash.svg"
            alt="View Only"
          />
          <span className="tooltiptext">View Only</span>
        </span>
      );
    }

    return "";
  };
  const roleInactive = (roleObj: any) => {
    if (roleObj.userRoleStatus === "INACTIVE") {
      return (
        <span className="tooltip-role">
          <i className="helpTextStyles aha-icon-stop" />
          <span className="tooltiptext">Inactive</span>
        </span>
      );
    }
    return "";
  };
  const generateBadgeContent = (roleDetailsObj: any) => {
    const returnJsx = (clntName: string, roleName: string) => {
      return (
        <>
          <span className="roleClientName">{roleName}</span>(
          <span>{clntName}</span>)
        </>
      );
    };
    const returnRoleName = (roleName: string) => {
      return <span className="roleName">{roleName}</span>;
    };
    // check for rolename and client Name mapping
    let roleBadgeContent: any;
    if (roleDetailsObj.clientId === null) {
      roleBadgeContent = returnRoleName(roleDetailsObj.roleName);
    } else if (roleDetailsObj.clientId !== null)
      roleBadgeContent = returnJsx(
        roleDetailsObj.clientName,
        roleDetailsObj.roleName
      );

    return (
      <div className={`roleStylesMin ${getColorCode(roleDetailsObj)}`}>
        <p>
          {generateHelpIcons(roleDetailsObj)}
          {roleBadgeContent}
          {roleInactive(roleDetailsObj)}
        </p>
      </div>
    );
  };

  const generateRoleContent = (userRoleArr: any) => {
    if (userRoleArr.length > 0) {
      return userRoleArr
        .slice(0, CONSTANTS.ROLE_STATUS.SLICE_COUNT)
        .map((roleItem: any, index: any) => {
          return generateBadgeContent(roleItem);
        });
    }
    return null;
  };

  const resetMultiSelectRoles = () => {
    rolesFilterRef?.current?.resetSelectedValues();
    setMultiSelectCountRoles("");
    setRecordRoles([]);
  };

  const resetMultiSelectClient = () => {
    clientRef?.current?.resetSelectedValues();
    setMultiSelectCountClient("");
    setRecordClients([]);
  };

  const getMultiSelectFilteredRecords = (
    selectedListObj: any,
    filterCode: any
  ) => {
    if (filterCode === "roleFilter") {
      setRecordRoles(
        selectedListObj.map((item: any) => {
          return item.code;
        })
      );
    }
    if (filterCode === "clientFilter") {
      setRecordClients(
        selectedListObj.map((item: any) => {
          return item.id;
        })
      );
    }

    // Reset Search Field Text on change of filter Values
    document.querySelector<any>(".searchField").value = "";
    setSearchFieldPlaceHolder("Search Mail / Name");
    setPageNumber(1);
  };
  const reInviteUserAction = async (
    userId: string,
    toggler: any,
    event: any
  ) => {
    event.persist();
    reInviteUserService(userId)
      .then(() => {
        store.dispatch(
          showToast({
            type: "success",
            title: "Success",
            message: "User has been re-invited successfully",
          })
        );
        toggler(event);
      })
      .catch((err: any) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error",
            message: getErrorMessage(err),
          })
        );
        toggler(event);
      });
  };

  useEffect(() => {
    // api call

    getUserList({
      ...defaultParams,
      roleCode: recordArrRoles.join(","),
      clientId: recordArrClients.join(","),
      pageSize,
      pageNumber,
      q: document?.querySelector<any>(".searchField")?.value,
    });
    document.querySelector(".visible_cls")?.classList.toggle("visible_cls");
    document.querySelector(".rotateArrow")?.classList.toggle("rotateArrow");
  }, [recordArrRoles, recordArrClients, pageSize, pageNumber, toggleStatus]);

  const multiSelectCallBackRoles = (selectedList: any, removedItem: any) => {
    if (selectedList.length === 0) {
      setMultiSelectCountRoles("");
    } else if (selectedList.length === 1) {
      setMultiSelectCountRoles(selectedList[0].name.trim());
    } else {
      setMultiSelectCountRoles(`${selectedList.length} Selected`);
    }

    getMultiSelectFilteredRecords(selectedList, "roleFilter");
  };

  const multiSelectCallBackClient = (selectedList: any, removedItem: any) => {
    if (selectedList.length === 0) {
      setMultiSelectCountClient("");
    } else if (selectedList.length === 1) {
      setMultiSelectCountClient(selectedList[0].name.trim());
    } else {
      setMultiSelectCountClient(`${selectedList.length} Selected`);
    }

    getMultiSelectFilteredRecords(selectedList, "clientFilter");
  };

  const toggleMultiSelectArrow = (event: any) => {
    if (
      event.currentTarget
        .querySelector(".optionListContainer")
        .classList.contains("displayBlock")
    ) {
      event.currentTarget.classList.add("rotateArrowMultiSelect");
    }
  };

  const handleOptionClick = (e: any) => {
    document
      .querySelector<any>(".pagecontent-filter")
      .classList.remove("visible_cls");
    document
      .querySelector<any>(".keydown-recordfilter")
      .setAttribute("aria-expanded", "false");
    document
      .querySelector<any>(".pagecontent-filter [role='option']")
      .classList.remove("active");
    e.currentTarget.classList.add("active");
    document
      .querySelector<any>(".keydown-recordfilter")
      .classList.toggle("rotateArrow");
  };

  const onChangePageNumber = (pageNumberParam: number) => {
    setPageNumber(pageNumberParam);
    window.scrollTo(0, 0);
  };

  const getClientFilterRecords = ({ pageSizeParam, pageNumParam }: any) => {
    ClientListService({
      pageSize: pageSizeParam,
      pageNumber: pageNumParam,
    })
      .then((response: any) => {
        if (response && response.data.clients === null) {
          setClientsFilterData([]);
        } else {
          setClientsFilterData(response.data.clients);
        }
        return { ...response._pagination };
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occured",
            message: getErrorMessage(error),
          })
        );
      })
      .finally(() => {});
  };

  document.addEventListener<any>("click", (event: any) => {
    if (
      event.target.closest("div") &&
      !event.target.closest("div")?.classList.contains("searchWrapper")
    ) {
      if (recordArrRoles.length === 0)
        rolesFilterRef?.current?.resetSelectedValues();
      if (recordArrClients?.length === 0)
        clientRef?.current?.resetSelectedValues();
    }
  });

  const getRoleFilterRecords = () => {
    getRoles({
      isActive: true,
      level: "gte".concat(",", `${loggedInUserObjectContext?.roleLevel}`),
    })
      .then((response: any) => {
        if (response && response.data.roles === null) {
          setRolesFilterData([]);
        } else {
          setRolesFilterData(response.data.roles);
        }
        return { ...response._pagination };
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occured",
            message: getErrorMessage(error),
          })
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    store.dispatch(
      setHeaderTitleText({
        headerTitle: "",
      })
    );

    getUserList(defaultParams);

    // load Filter Data

    getRoleFilterRecords();

    (loggedInUserObjectContext?.roleCode === CONSTANTS.USER_ROLES.SUPER_ADMIN ||
      loggedInUserObjectContext?.roleCode ===
        CONSTANTS.USER_ROLES.SUPER_USER) &&
      getClientFilterRecords({
        pageSizeParam: CONSTANTS.MAX_RECORDS,
        pageNumParam: 1,
      });
  }, []);

  const resetFilterSelectedOptions = () => {
    rolesFilterRef?.current?.resetSelectedValues();
    setMultiSelectCountRoles("");

    clientRef?.current?.resetSelectedValues();
    setMultiSelectCountClient("");
  };

  const getSearchRecords = (searchFieldVal: string) => {
    const { ishtmlTag, replacedValue } = stripHtml(searchFieldVal);
    resetMultiSelectRoles();
    resetMultiSelectClient();
    if (ishtmlTag) {
      document.querySelector<any>(".searchField").value = replacedValue;
      getUserList(defaultParams);
    } else {
      resetFilterSelectedOptions();
      getUserList({ ...defaultParams, q: searchFieldVal });
      document.querySelector<any>(".searchField").value = searchFieldVal;
    }
  };
  const isClientAdmin = (role: string) => role === "Client Admin";

  const checkBtnDisableForClientAdminUser = (roleCode: any) => {
    if (
      loggedInUserObjectContext?.roleCode ===
        CONSTANTS.USER_ROLES.CLIENT_USER ||
      loggedInUserObjectContext?.roleCode === CONSTANTS.USER_ROLES.CLIENT_ADMIN
    )
      return true;

    if (["CLIENT_USER", "CLIENT_ADMIN"].includes(roleCode)) {
      if (showClientDropDown) {
        return defaultClientId?.trim().length > 0;
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { firstName, lastName, email, roleCode } = userInviteData;

    const commonCheck =
      firstName &&
      lastName &&
      autoSuggestValue &&
      roleCode &&
      !isError &&
      checkBtnDisableForClientAdminUser(roleCode);

    if (commonCheck) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [userInviteData]);

  const loadUserDetails = (userObj: any) => {
    /* appDispatch(setUserDetails({ selectedUserObject: userObj })); */
    history.push(`${CONSTANTS.PAGE_ROUTES.USERS}/${userObj.id}`);
  };

  const userProfile = ssouserProfile();

  const restrictAccessForStatusChange = (userEmail: any) => {
    return userProfile.email !== userEmail;
  };

  const [activeClientsData, setActiveClientsData] = useState<any>([]);

  const getActiveClientsRecords = () => {
    ClientListService({
      pageSize: CONSTANTS.MAX_RECORDS,
      pageNumber: 1,
      isActive: true,
    })
      .then((response) => {
        if (response && response.data.clients === null) {
          setActiveClientsData([]);
        } else {
          setActiveClientsData(response.data.clients);
        }
        return { ...response._pagination };
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occurred",
            message: getErrorMessage(error),
          })
        );
      })
      .finally(() => {});
  };

  const getClientsDataBasedOnRole = () => {
    let clientsDropdownData: any = [];
    if (fetchPermission()("account.clients.get", "")) {
      // Super Admin
      clientsDropdownData = [...activeClientsData];
    } else if (stateObject.role === "Client Admin") {
      // Client Admin
      clientsDropdownData = [
        {
          id: stateObject.clientId,
          name: stateObject.clientName,
        },
      ];
    }

    return clientsDropdownData.map((item: any) => {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });
  };

  useEffect(() => {
    if (clientArr.includes(roleSelected)) {
      if (fetchPermission()("account.clients.get", "")) {
        // Super Admin
        getActiveClientsRecords();
        setShowClientDropDown(true);
      }
    }
  }, [roleSelected]);

  const [mobileDropDownToggle, setMobileDropdownToggle] =
    useState<boolean>(false);

  const submitForm = async () => {
    const { roleCode, firstName, lastName, email, clientId } = userInviteData;
    const clntId = isClientAdmin(stateObject.role)
      ? stateObject.clientId.toString()
      : clientId;
    inviteUserService(firstName, lastName, autoSuggestValue, roleCode, clntId)
      .then((response: any) => {
        setIsError(false);
        store.dispatch(
          showToast({
            type: "success",
            title: "Success",
            message: "User has been invited successfully!",
          })
        );
        setUserInviteData({
          firstName,
          lastName,
          email: "",
          clientId: "0",
          role: "",
          roleCode: "",
        });
        getUserList(defaultParams);
        toggleModalStyles();
        setToggleModal(false);
        setImageLoading(true);
      })
      .catch((error) => {
        if (
          error.response.data.error.message ===
          "Email-id already exists under the tenant"
        ) {
          setIsError(true);
          setDataDismiss("");
        } else {
          setDataDismiss("modal");
          store.dispatch(
            showToast({
              title: "Error",
              message: getErrorMessage(error),
            })
          );
        }
      });
  };

  const [inviteUserRoleData, setInviteUserRoleData] = useState<any>([]);
  const inviteUserRolesDataAPI = () => {
    getRoles({
      isActive: true,
      level: "gte".concat(",", `${loggedInUserObjectContext?.roleLevel}`),
    })
      .then((response: any) => {
        if (response && response.data.roles === null) {
          setInviteUserRoleData([]);
        } else setInviteUserRoleData(response.data.roles);
        return { ...response._pagination };
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occurred",
            message: getErrorMessage(error),
          })
        );
      })
      .finally(() => {});
  };
  useEffect(() => {
    if (shouldShowPopover) {
      getUserList({
        ...defaultParams,
        roleCode: recordArrRoles.join(","),
        clientId: recordArrClients.join(","),
        pageSize,
        pageNumber,
        q: "",
      });
      setPopver(true);
      setTimeout(() => {
        setPopver(false);
        setShouldShowPopover(false);
      }, 6000);
    }
  }, [shouldShowPopover]);
  return (
    <Wrapper className="d-flex flex-column w-100">
      <UserWrapperStyles>
        <div className="d-lg-flex mt-4 m-sm-2 pl-lg-3 p-sm-2">
          <div
            className={`container flex-grow-1 pl-4 roleStyles-${
              loggedInUserObjectContext?.roleId
            } ${
              !isTableLoading && userData.length > 0 ? "" : "no-data-spacing"
            } `}
          >
            <div className="d-flex flex-grow-1 justify-content-between mt-3">
              <h1
                className="proj-heading"
                aria-label="Users"
                data-testid="test-users"
              >
                {loggedInUserObjectContext?.roleCode ===
                  CONSTANTS.USER_ROLES.SUPER_ADMIN ||
                loggedInUserObjectContext?.roleCode ===
                  CONSTANTS.USER_ROLES.SUPER_USER ? (
                  "Users"
                ) : (
                  <span className="clientText">
                    <span className="UsersText">
                      {stateObject.clientName ? stateObject.clientName : ""}
                    </span>
                    &nbsp;Users
                  </span>
                )}
              </h1>
            </div>
            <div
              className={`d-none d-md-flex row mt-3 mb-4 align-items-center ${
                userData.length > 0 ||
                searchField !== "" ||
                recordArrRoles.length !== 0 ||
                recordArrClients.length !== 0
                  ? ""
                  : "justify-content-end"
              }`}
            >
              <div
                className={`col-md-4 mr-auto page-number filterHeaderChildElement ${
                  userData.length > 0 ||
                  searchField !== "" ||
                  recordArrRoles.length !== 0 ||
                  recordArrClients.length !== 0
                    ? ""
                    : "d-none"
                }`}
              >
                Show &nbsp;<span className="boldCount">{pageSize}</span>
                <div className="svg-parent position-relative">
                  <label
                    htmlFor="pagenumberitems"
                    className="position-absolute invisible-cls"
                  >
                    pagenumberitems
                    <input
                      type="text"
                      id="pagenumberitems"
                      className="position-absolute invisible-cls"
                      data-testid="pagenumberitems"
                      tabIndex={-1}
                    />
                  </label>
                  <div
                    className="keydown-recordfilter d-inline-block"
                    onKeyDown={keydownToggleNoOfRecordTableFilter}
                    tabIndex={0}
                    onClick={toggleNoOfRecordTableFilter}
                    aria-label={`${pageSize} number of items is displayed in one page`}
                    role="combobox"
                    aria-controls=""
                    aria-owns="pagelistitems"
                    aria-expanded="false"
                    aria-haspopup="listbox"
                  >
                    <i className="aha-icon-down-arrow-thin pageNumber" />
                  </div>
                  <div
                    className="page-sort position-absolute m-0 d-none pagecontent-filter"
                    id="pagelistitems"
                    role="listbox"
                    aria-label="filter"
                  >
                    {rangeArr.map((item, index) => {
                      const key = `${index}_${item}`;
                      return (
                        <div
                          key={key}
                          role="option"
                          tabIndex={0}
                          aria-selected="true"
                          className={pageSize === item ? "active" : ""}
                          onKeyUp={(e: any) => {
                            if (
                              e.keyCode === CONSTANTS.KEY_ENTER ||
                              e.keyCode === CONSTANTS.KEY_SPACEBAR
                            ) {
                              setPageSize(item);
                              setPageNumber(1);
                              e.currentTarget.classList.toggle("active");
                            }
                          }}
                          onClick={(e) => {
                            setPageSize(item);
                            setPageNumber(1);
                            e.currentTarget.classList.toggle("active");
                          }}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </div>
                items of {pagination.totalCount}
              </div>
              <div className="col-md-8 justify-content-end d-flex search-option">
                <Restricted to="account.users.post">
                  <button
                    type="button"
                    className="btn btn-sm btn-round btn-primary inviteUserBtn"
                    data-testid="testInviteUserSmallDevice"
                    onClick={() => {
                      reset({
                        errors: 0,
                        roleCode: "",
                        clientId: "0",
                        email: "",
                        firstName: "",
                        lastName: "",
                      });
                      setAutoSuggestValue("");
                      inviteUserRolesDataAPI();
                      setIsError(false);
                      setToggleModal(true);
                      toggleModalStyles();
                      setRoleSelected("");
                      setShowClientDropDown(false);
                    }}
                  >
                    Invite User
                  </button>
                </Restricted>
              </div>
            </div>
            <div className="d-sm-block d-md-none flex-grow-1 justify-content-between mt-3 mb-3">
              <div className="d-flex justify-content-between pb-3">
                {(userData.length > 0 ||
                  searchField !== "" ||
                  recordArrRoles.length !== 0 ||
                  recordArrClients.length !== 0) && (
                  <div>
                    <div className="page-number pb-3 pb-sm-0 filterHeaderChildElement">
                      Show &nbsp;<span className="boldCount">{pageSize}</span>
                      <div className="svg-parent position-relative">
                        <label
                          htmlFor="pagenumberitems-sm"
                          className="position-absolute invisible-cls"
                        >
                          pagenumberitems
                          <input
                            type="text"
                            id="pagenumberitems-sm"
                            className="position-absolute invisible-cls"
                            data-testid="pagenumberitems"
                            tabIndex={-1}
                          />
                        </label>
                        <div
                          className="keydown-recordfilter d-inline-block"
                          onKeyDown={keydownToggleNoOfRecordTableFilter}
                          tabIndex={0}
                          onClick={toggleNoOfRecordTableFilter}
                          aria-label={`${pageSize} number of items is displayed in one page`}
                          role="combobox"
                          aria-controls="pagelistitems-sm"
                          aria-owns="pagelistitems-sm"
                          aria-expanded="true"
                          aria-haspopup="listbox"
                        >
                          <i className="aha-icon-down-arrow-thin" />
                        </div>
                        <div
                          className="page-sort position-absolute m-0 d-none pagecontent-filter"
                          id="pagelistitems-sm"
                          role="listbox"
                          aria-label="filter"
                        >
                          {rangeArr.map((item, index) => {
                            const key = `${index}_${item}`;
                            return (
                              <div
                                key={key}
                                role="option"
                                tabIndex={0}
                                aria-selected="true"
                                className={pageSize === item ? "active" : ""}
                                onKeyUp={(e) => {
                                  setPageSize(item);
                                  setPageNumber(1);
                                  e.currentTarget.classList.toggle("active");
                                }}
                                onClick={(e) => {
                                  setPageSize(item);
                                  setPageNumber(1);
                                  e.currentTarget.classList.toggle("active");
                                }}
                              >
                                {item}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      items of {pagination.totalCount}
                    </div>
                  </div>
                )}
                <button
                  type="button"
                  className="btn btn-sm btn-round d-none d-sm-block btn-primary inviteUserBtn"
                  data-testid="testInviteUser"
                  onClick={() => {
                    reset({
                      errors: 0,
                      roleCode: "",
                      clientId: "0",
                      email: "",
                      name: "",
                      firstName: "",
                      lastName: "",
                    });
                    setAutoSuggestValue("");
                    inviteUserRolesDataAPI();
                    setIsError(false);
                    setToggleModal(true);
                    toggleModalStyles();
                    setRoleSelected("");
                    setShowClientDropDown(false);
                  }}
                >
                  Invite User
                </button>
                {(userData.length > 0 ||
                  searchField !== "" ||
                  recordArrRoles.length !== 0 ||
                  recordArrClients.length !== 0) && (
                  <button
                    type="button"
                    aria-label="Filter"
                    className={`noBtnStyle d-onlyMobile ${
                      mobileDropDownToggle ? "redColorIcon" : ""
                    }`}
                    onClick={(e) => {
                      e.currentTarget.focus();
                      setMobileDropdownToggle(!mobileDropDownToggle);
                    }}
                  >
                    <i
                      tabIndex={0}
                      className={
                        mobileDropDownToggle
                          ? "aha-icon-filter-outline redColorIcon"
                          : "aha-icon-filter-outline"
                      }
                    />
                    Filter
                  </button>
                )}
              </div>
            </div>

            <div
              className={`row row-gap justify-content-start mb-4 roleBasedStyle-dropdown ${
                mobileDropDownToggle ? "toggleOpen" : ""
              }`}
            >
              {showSearch && (
                <div className={`no-data-popup col-sm-6 col-lg-4 `}>
                  <label className="search-label" htmlFor="search">
                    Search
                  </label>
                  <div className="d-flex searchBox searchWrapper">
                    <input
                      type="text"
                      id="search"
                      className={`form-control searchField ${
                        userData.length > 0 ||
                        searchField !== "" ||
                        recordArrRoles.length !== 0 ||
                        recordArrClients.length !== 0
                          ? ""
                          : "d-none"
                      }`}
                      aria-label="Search Mail / Name"
                      placeholder={searchFieldPlaceHolder}
                      onFocus={(event) => {
                        document
                          .querySelector<any>(".searchField")
                          .setAttribute("placeholder", "Search Mail / Name");
                        setShouldShowPopover(false);
                      }}
                      onBlur={(event) => {
                        document
                          .querySelector<any>(".searchField")
                          .setAttribute("placeholder", "Search Mail / Name");
                      }}
                      defaultValue={searchField}
                      onKeyUp={(e: any) => {
                        setShouldShowPopover(false);
                        setPopver(false);
                        if (e.keyCode === CONSTANTS.KEY_ENTER) {
                          setSearchField(
                            document.querySelector<any>(".searchField").value
                          );
                          getSearchRecords(
                            document.querySelector<any>(".searchField").value
                          );

                          !shouldShowPopover && setPageNumber(1);
                        }
                      }}
                    />
                    <button
                      type="button"
                      className={`noBtnStyle searchIcon ${
                        userData.length > 0 ||
                        searchField !== "" ||
                        recordArrRoles.length !== 0 ||
                        recordArrClients.length !== 0
                          ? ""
                          : "d-none"
                      }`}
                      onClick={(event) => {
                        document.querySelector<any>(".searchField").focus();
                        setSearchFieldPlaceHolder("Search Mail / Name");
                        getSearchRecords(
                          document.querySelector<any>(".searchField").value
                        );
                        !shouldShowPopover && setPageNumber(1);
                      }}
                      aria-label="Search by Mail/Name"
                    >
                      <img
                        src="../images/search.svg"
                        alt=""
                        className="serch-img"
                      />
                    </button>
                  </div>
                  {popover && !isTableLoading ? (
                    <div
                      className="search-popover"
                      role="alert"
                      aria-live="assertive"
                    >
                      <div className="popoverWrapper">
                        <i className="aha-icon-warning" />
                        User / Email id not found
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {(userData.length > 0 ||
                searchField !== "" ||
                recordArrRoles.length !== 0 ||
                recordArrClients.length !== 0) && (
                <div
                  className={`roleFilterWrapper multiSelectContainer col-sm-6 col-lg-4 ${
                    multiSelectCountRoles === "" ? "multiCntNone" : ""
                  }`}
                >
                  <label htmlFor="rolesFilter_input">
                    {/* <span className="superAdminCircle-bkp" id="Select Role" /> */}
                    Select Role
                  </label>
                  <p className="multiSelectCntWrapper">
                    <span className="multiSelectCnt">
                      {multiSelectCountRoles}
                    </span>
                  </p>
                  <p className="clearMultiSelectWrapper">
                    <button
                      type="button"
                      className="clearMultiSelect noBtnStyle"
                      onClick={resetMultiSelectRoles}
                      aria-label="ClearButtonForSelectRole"
                    >
                      <i className="aha-icon-cancel-icon" />
                    </button>
                  </p>
                  <Multiselect
                    options={rolesFilterData}
                    displayValue="name"
                    showCheckbox
                    placeholder="Select"
                    emptyRecordMsg="No Records"
                    id="rolesFilter"
                    showArrow
                    avoidHighlightFirstOption
                    closeIcon="cancel"
                    onSelect={multiSelectCallBackRoles}
                    onRemove={multiSelectCallBackRoles}
                    ref={rolesFilterRef as React.LegacyRef<Multiselect>}
                    customArrow={<i className="aha-icon-arrow-down" />}
                    closeOnSelect={false}
                  />
                </div>
              )}
              {(userData.length > 0 ||
                searchField !== "" ||
                recordArrRoles.length !== 0 ||
                recordArrClients.length !== 0) && (
                <Restricted to="account.clients.get">
                  <div
                    className={`clientFilterWrapper multiSelectContainer col-sm-6 col-lg-4  pt-lg-0 ${
                      multiSelectCountClient === "" ? "multiCntNone" : ""
                    }`}
                  >
                    <label htmlFor="client_input">
                      <span className="clientCircle" /> Select Client
                    </label>
                    <p className="multiSelectCntWrapper">
                      <span className="multiSelectCnt">
                        {multiSelectCountClient}
                      </span>
                    </p>
                    <p className="clearMultiSelectWrapper">
                      <button
                        type="button"
                        className="clearMultiSelect noBtnStyle"
                        onClick={resetMultiSelectClient}
                        aria-label="ClearButtonForClient"
                      >
                        <i className="aha-icon-cancel-icon" />
                      </button>
                    </p>
                    <Multiselect
                      options={clientsFilterData}
                      displayValue="name"
                      showCheckbox
                      placeholder="Select"
                      emptyRecordMsg="No Records"
                      id="client"
                      showArrow
                      avoidHighlightFirstOption
                      closeIcon="cancel"
                      onSelect={multiSelectCallBackClient}
                      onRemove={multiSelectCallBackClient}
                      ref={clientRef as React.LegacyRef<Multiselect>}
                      customArrow={<i className="aha-icon-arrow-down" />}
                    />
                  </div>
                </Restricted>
              )}
            </div>

            {userData.length === 0 &&
              !isTableLoading &&
              searchField === "" &&
              !shouldShowPopover &&
              recordArrRoles.length === 0 &&
              recordArrClients.length === 0 && (
                <NoDataComp DataList={userData} />
              )}
            <div
              className={`project-table mt-2 ${
                mobileDropDownToggle ? "mobTableSec" : ""
              }`}
            >
              {isTableLoading && (
                <table
                  className="aui-responsive-table aui-table-cols aui-table-loader w-100"
                  role="alert"
                  aria-live="assertive"
                  aria-label="Table is Loading"
                />
              )}

              {!isTableLoading &&
              (userData.length > 0 ||
                searchField !== "" ||
                recordArrRoles.length !== 0 ||
                recordArrClients.length !== 0) ? (
                <table className="aui-responsive-status-table">
                  <thead>
                    <tr>
                      {columnHeaders &&
                        columnHeaders.map((colHeader) => {
                          return (
                            <th key={colHeader.id} scope="col">
                              <div className="aui-th">{colHeader.name}</div>
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {userData &&
                      userData.map((user, index) => {
                        return (
                          <tr
                            key={user.id}
                            className={`roleStyles  ${checkStatus(
                              user.status
                            )}`}
                          >
                            <td data-title="Name">
                              <div className="aui-td userNameColumn">
                                <button
                                  data-testid="userdetail"
                                  type="button"
                                  className="adh-anchorStyle mb-0 noBtnStyle pl-0"
                                  onClick={() => loadUserDetails(user)}
                                >
                                  {user.firstName.length > 0
                                    ? `${user.firstName} ${user.lastName}`
                                    : `${user.name}`}
                                </button>
                              </div>
                            </td>
                            <td data-title="Email">
                              <div className="aui-td">{user.email}</div>
                            </td>
                            <td data-title="Role">
                              <div className="aui-td roleColumnWrapper d-flex">
                                {generateRoleContent(user.roles)}
                                {user.roles.length >
                                CONSTANTS.ROLE_STATUS.SLICE_COUNT ? (
                                  <div className="circleSec tooltip-role-bkp">
                                    <button
                                      type="button"
                                      onClick={(e) => toggleRolePopover(e)}
                                      className="circleWrapper"
                                    >
                                      <span className="circleCount">
                                        +
                                        {user.roles.length -
                                          CONSTANTS.ROLE_STATUS.SLICE_COUNT}
                                      </span>
                                    </button>
                                    <div className="roleSec d-none">
                                      {user.roles
                                        .slice(
                                          CONSTANTS.ROLE_STATUS.SLICE_COUNT,
                                          user.roles.length + 1
                                        )
                                        .map((roleData, ind) => {
                                          return generateBadgeContent(roleData);
                                        })}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                            <td data-title="Status">
                              <div className="aui-td">
                                {lowerCaseAllWordsExceptFirstLetters(
                                  user.status
                                )}
                              </div>
                            </td>

                            <td data-title="Action">
                              <div className="aui-td actions-column">
                                <div className="tooltip-role">
                                  <button
                                    data-testid="View more"
                                    type="button"
                                    aria-label={`View More for ${user.name}`}
                                    className="noBtnStyle btnEllipses rotate-90-anti"
                                    tabIndex={0}
                                    aria-expanded={togglePopoverAria}
                                    onClick={(e) => togglePopver(e)}
                                  >
                                    <i className="aha-icon-meat-balls" />
                                  </button>
                                  <div className="d-none connection-td-wrapper">
                                    <div className="popoverWrapper">
                                      <button
                                        type="button"
                                        className="noBtnStyle mb-2"
                                        onClick={() => loadUserDetails(user)}
                                      >
                                        View
                                      </button>
                                      {restrictAccessForStatusChange(
                                        user.email
                                      ) && isSuperAdminOrClientAdmin ? (
                                        <>
                                          <button
                                            type="button"
                                            className="noBtnStyle mb-2"
                                            onClick={async (e) => {
                                              if (
                                                user.status.toLocaleLowerCase() !==
                                                "pending"
                                              ) {
                                                statusButtonHandler(
                                                  user.status,
                                                  user.id,
                                                  user.name
                                                );
                                                setModalContent(
                                                  updateModalContent(
                                                    getStatusAction(
                                                      user.status
                                                    ),
                                                    user.name
                                                  )
                                                );
                                              }
                                              setApiStatus("status");
                                              setUserId(user.id);
                                              setRoleName(user.roles);
                                              setMainIcon(
                                                getMainIcon(
                                                  getStatusAction(user.status)
                                                )
                                              );

                                              getMessage(
                                                getStatusAction(user.status)
                                              );
                                              if (
                                                user.status.toLocaleLowerCase() ===
                                                "pending"
                                              ) {
                                                await reInviteUserAction(
                                                  `${user.id}`,
                                                  togglePopverOnWrapperClick,
                                                  e
                                                );
                                              }
                                            }}
                                          >
                                            {getStatusAction(user.status)}
                                          </button>
                                          <button
                                            type="button"
                                            className="noBtnStyle mb-2"
                                            onClick={() => {
                                              setApiStatus("delete");
                                              setMainIcon(
                                                getMainIcon(getStatusAction(""))
                                              );
                                              setUserId(user.id);
                                              setRoleName(user.roles);
                                              setModalContent(
                                                updateModalContent(
                                                  getStatusAction(""),
                                                  user.name
                                                )
                                              );
                                              getMessage(getStatusAction(""));
                                              toggle();
                                            }}
                                          >
                                            Delete
                                          </button>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                  <span className="tooltiptext viewMoreKebab">
                                    View More
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                ""
              )}
              {userData.length === 0 &&
                !isTableLoading &&
                (recordArrRoles.length !== 0 ||
                  recordArrClients.length !== 0) && (
                  <>
                    <NoDataComp DataList={userData} Filter />
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <p>Change the filter options or</p>
                      <button
                        aria-label="Reset the filters"
                        type="button"
                        className="reload-btn"
                        onClick={() => {
                          setShowSearch(false);
                          resetMultiSelectRoles();
                          resetMultiSelectClient();
                          getUserList(defaultParams);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter" || e.key === "Spacebar") {
                            setShowSearch(false);
                            resetMultiSelectRoles();
                            resetMultiSelectClient();
                            getUserList(defaultParams);
                          }
                        }}
                      >
                        <i className="aha-icon-reload" />
                        Reload
                      </button>
                    </div>
                  </>
                )}
              {!isTableLoading &&
              (userData.length > 0 ||
                searchField !== "" ||
                recordArrRoles.length !== 0 ||
                recordArrClients.length !== 0) ? (
                <div className="row mt-3 mt-sm-5 justify-content-end">
                  {pagination.totalPages > 0 && (
                    <Pagination
                      {...pagination}
                      setPageNumber={onChangePageNumber}
                      paginationSetLimit={CONSTANTS.PAGINATION_LIMIT}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
              <Modal
                isShown={isShown}
                hide={toggle}
                id={userId}
                action={ApiCaller}
                status={userStatus}
                modalContent={modalContent}
                roles={roleName}
                message={message}
                mainIcon={mainIcon}
              />
            </div>
          </div>
        </div>
        {toggleModal ? (
          <FocusTrap
            focusTrapOptions={{
              initialFocus: false,
              fallbackFocus: `.modal`,
              escapeDeactivates: false,
              clickOutsideDeactivates: false,
            }}
          >
            <div
              className="modal fade show aui-org-modal aui-new-org aui-modal"
              id="InviteUser"
              tabIndex={-1}
              aria-label="Invite User Modal Window"
              aria-modal="true"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-md modal-md modal-dialog-centered inviteuser-modal modal-popup-inviteUser margin-sm modalwidth">
                <div className="modal-content">
                  <div
                    className={`aui-block-loader ${
                      imageLoading ? "d-flex" : "d-none"
                    }`}
                    role="alert"
                    aria-live="assertive"
                    aria-label="Modal Content Loading"
                  />
                  <div
                    className={`modal-content-wrapper ${
                      imageLoading ? "d-none" : "d-block"
                    }`}
                  >
                    <button
                      ref={RefFocus}
                      type="button"
                      className="close d-none d-sm-block"
                      onClick={() => {
                        toggleModalStyles();
                        setToggleModal(false);
                        setImageLoading(true);
                        setSelectClientValue("select client");
                      }}
                      aria-label="Close"
                    >
                      <img
                        src={imageUrl}
                        data-testid="crossbtn"
                        onLoad={imageLoaded}
                        alt=""
                        className="position-relative closestyle"
                      />
                    </button>

                    <div className="modal-header">
                      <h2
                        className="modal-title font-600 d-flex"
                        aria-label="Invite User"
                        data-testid="test-addtenant"
                        id="modal-heading"
                      >
                        <span
                          className="d-block d-sm-none rotateInverse mr-2"
                          onClick={() => {
                            toggleModalStyles();
                            setToggleModal(false);
                            setShowClientDropDown(false);
                            setSelectClientValue("select client");
                          }}
                          role="button"
                        >
                          <i className="aha-icon-right-arrow-thick" />
                        </span>
                        {stateObject.role === "Super Admin" ? (
                          "Invite User"
                        ) : (
                          <p className="m-0 invite-text">
                            Invite User
                            <span className="tenantText font-600 modal-title">
                              {stateObject.clientName
                                ? ` - ${stateObject.clientName}`
                                : ""}
                            </span>
                          </p>
                        )}
                      </h2>
                    </div>
                    <p className="instruction">
                      {CONSTANTS.MODAL_INSTRUCTION_TEXT}
                    </p>
                    <form onSubmit={handleSubmit(submitForm)}>
                      <div className="modal-body p-0 pt-3">
                        <div className="form-group row required mb-4">
                          <label
                            htmlFor="emailid"
                            className="col-sm-4 col-form-label"
                            aria-label="Email"
                          >
                            Email ID
                          </label>
                          <div className="col-sm-8">
                            <AutoSuggestComp
                              onChangeHandler={onChangeHandler}
                              setIsError={setIsError}
                              setSelectedUserLookup={setSelectedUserLookup}
                              autoSuggestValue={autoSuggestValue}
                              setAutoSuggestValue={setAutoSuggestValue}
                              register={register}
                            />
                            {errors.email?.message && (
                              <p role="alert" className="form-error-msg mb-0">
                                {errors.email?.message}
                              </p>
                            )}
                          </div>
                        </div>
                        {isError ? (
                          <div className="form-group row required mb-3 marginAlign-errorMsg">
                            <div className="col-sm-4" />

                            <div className="col-sm-8">
                              <div className="error-block d-flex align-items-center ">
                                <i className="aha-icon-warning warning-icon p-2" />
                                <div>
                                  <h4
                                    role="alert"
                                    className="error-message m-0 pt-2 pb-2 pr-3"
                                  >
                                    Email ID already exists, Please choose a
                                    different one
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {!isError ? (
                          <>
                            <div className="form-group row required mb-4">
                              <label
                                htmlFor="firstName"
                                className="col-sm-4 col-form-label"
                                aria-label="First name"
                              >
                                First Name
                              </label>
                              <div className="col-sm-8">
                                <input
                                  type="text"
                                  className="form-control "
                                  id="firstName"
                                  required
                                  name="firstName"
                                  aria-required="true"
                                  ref={register}
                                  onChange={onChangeHandler}
                                />
                                {errors.firstName?.message && (
                                  <p
                                    role="alert"
                                    className="form-error-msg mb-0"
                                  >
                                    {errors.firstName?.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="form-group row required mb-4">
                              <label
                                htmlFor="lastName"
                                className="col-sm-4 col-form-label"
                                aria-label="Last name"
                              >
                                Last Name
                              </label>
                              <div className="col-sm-8">
                                <input
                                  type="text"
                                  required
                                  className="form-control "
                                  id="lastName"
                                  name="lastName"
                                  aria-required="true"
                                  ref={register}
                                  onChange={onChangeHandler}
                                />
                                {errors.lastName?.message && (
                                  <p
                                    role="alert"
                                    className="form-error-msg mb-0"
                                  >
                                    {errors.lastName?.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="form-group row required mb-4">
                              <label
                                htmlFor="selectRole"
                                className="col-sm-4 col-form-label"
                                aria-label="Select role"
                              >
                                Select Role
                              </label>
                              <div className="col-sm-8">
                                <select
                                  className="form-control dropdown"
                                  defaultValue=""
                                  ref={register}
                                  onChange={onChangeHandler}
                                  name="role"
                                  id="selectRole"
                                  required
                                  aria-required="true"
                                  onClick={(e) => {
                                    dropDownToggler(e);
                                  }}
                                  onBlur={(e) => {
                                    dropDownDefaultState(e);
                                  }}
                                >
                                  <option value="" disabled hidden selected>
                                    Select
                                  </option>
                                  {inviteUserRoleData.map((item: any) => {
                                    return (
                                      <option key={item.id} value={item.code}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <i className="aha-icon-arrow-down forModal" />
                              </div>
                            </div>{" "}
                            {showClientDropDown ? (
                              <Restricted to="account.clients.get">
                                <div className="form-group row required mb-3">
                                  <label
                                    htmlFor="selectClient"
                                    className="col-sm-4 col-form-label"
                                    aria-label="select-client"
                                  >
                                    Select Client
                                  </label>
                                  <div className="col-sm-8 tooltip-role">
                                    <div
                                      className="select-client"
                                      id="Client-tooltip"
                                    >
                                      <select
                                        className="form-control dropdown"
                                        ref={register}
                                        defaultValue={defaultClientId}
                                        name="clientId"
                                        id="selectClient"
                                        data-testid="selectClient"
                                        aria-required="true"
                                        onClick={(e) => {
                                          dropDownToggler(e);
                                        }}
                                        onBlur={(e) => {
                                          dropDownDefaultState(e);
                                        }}
                                        onChange={(e) => {
                                          onChangeHandler(e);
                                          setSelectClientValue(
                                            e.target.options[
                                              e.target.selectedIndex
                                            ].text
                                          );
                                        }}
                                      >
                                        <option
                                          value="0"
                                          disabled
                                          hidden
                                          selected
                                        >
                                          Select
                                        </option>
                                        {getClientsDataBasedOnRole()}
                                      </select>
                                      <i className="aha-icon-arrow-down forModal" />
                                      <span className="tooltiptext">
                                        {selectClientValue === ""
                                          ? "select client"
                                          : selectClientValue}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Restricted>
                            ) : (
                              ""
                            )}
                          </>
                        ) : null}

                        <div className="modal-footer pt-1 d-flex btnGrp-addTanant justify-content-end">
                          <button
                            type="button"
                            aria-label="cancel"
                            className="btn btn-round btn-secondary mr-4 btnwidth cancel-btn"
                            onClick={() => {
                              reset({
                                errors: 0,
                                roleCode: "",
                                clientId: "0",
                                email: "",
                                firstName: "",
                                lastName: "",
                              });
                              setRoleSelected("");
                              setShowClientDropDown(false);
                              setSelectClientValue("select client");
                              setUserInviteData({
                                firstName: "",
                                lastName: "",
                                email: "",
                                clientId: "0",
                                role: "",
                                roleCode: "",
                              });
                              setIsError(false);
                              toggleModalStyles();
                              setToggleModal(false);
                              setImageLoading(true);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={isError ? false : buttonDisabled}
                            type={isError ? "button" : "submit"}
                            aria-label={isError ? "EditUser" : "save"}
                            className="btn btn-round btn-primary btnwidth invite-btn"
                            data-dismiss={isError ? "modal" : null}
                            onClick={
                              isError
                                ? () => {
                                    history.push(
                                      `${CONSTANTS.PAGE_ROUTES.USERS}/${selectedUserLookup}`
                                    );
                                  }
                                : () => {}
                            }
                          >
                            {isError ? "Edit User" : "Invite"}
                          </button>
                        </div>

                        <div className="modal-footer d-block d-sm-none mt-0 pt-0">
                          <div className="btn-block btnbottom btnGrp-add">
                            <button
                              disabled={isError ? false : buttonDisabled}
                              data-testid="submitbtn"
                              type={isError ? "button" : "submit"}
                              aria-label={isError ? "Edit User" : "save"}
                              className="btn btn-round btn-primary btnwidth invite-sm-btn"
                              data-dismiss={isError ? "modal" : null}
                              onClick={
                                isError
                                  ? () => {
                                      history.push(
                                        `${CONSTANTS.PAGE_ROUTES.USERS}/${selectedUserLookup}`
                                      );
                                    }
                                  : () => {}
                              }
                            >
                              {isError ? "Edit User" : "Invite"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </FocusTrap>
        ) : null}
        <div className="btn-background d-block d-sm-none ">
          <button
            type="button"
            className="btn btn-sm btn-round btn-block btn-primary d-block d-sm-none fxdbtn"
            onClick={() => {
              reset({
                errors: 0,
                roleCode: "",
                clientId: "0",
                email: "",
                firstName: "",
                lastName: "",
              });
              setAutoSuggestValue("");
              inviteUserRolesDataAPI();
              setIsError(false);
              setToggleModal(true);
              toggleModalStyles();
              setRoleSelected("");
              setShowClientDropDown(false);
            }}
          >
            Invite User
          </button>
        </div>
      </UserWrapperStyles>
    </Wrapper>
  );
};

export default UsersComp;
