/* eslint-disable jsx-a11y/label-has-associated-control */
import store from "app/store";
import { ssouserProfile } from "common/utils";
import { setHeaderTitleText } from "components/Header/header.slice";
import { hideToast } from "components/Toast/toast.slice";
import React, { useEffect } from "react";
import { Wrapper } from "styled";
import useUsersList from "./profileHook";
import ProfileWrapper from "./styled";

const Profile = () => {
  useEffect(() => {
    store.dispatch(
      setHeaderTitleText({
        headerTitle: "My Profile",
      })
    );
  });
  const userProfile = ssouserProfile();

  const { users } = useUsersList(userProfile.email);

  function showPencil(roleName: string) {
    const roles = ["super user", "admin", "shopcpr - user", "care plan -user"];
    return roles.includes(roleName.toLocaleLowerCase());
  }
  const getColorCode = (roleId: any) => {
    switch (roleId) {
      case 1:
        return "roleColorCodeSA";
      case 6:
        return "roleColorCodeSA";
      case 4:
        return "roleColorCodeCPU";
      case 5:
        return "roleColorCodeEU";
      default:
        return "";
    }
  };
  let roleCards: any;
  const generateHelpIcons = (roleId: any) => {
    // super admin
    if (roleId === 1) {
      return (
        <span className="tooltip-role">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserSetting.svg"
            alt="admin"
          />
          <span className="tooltiptext">Super Admin</span>
        </span>
      );
    }
    // super user
    if (roleId === 6) {
      return (
        <span className="tooltip-role">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserShield.svg"
            alt="View Only"
          />
          <span className="tooltiptext">View Only</span>
        </span>
      );
    }
    // client admin
    if (roleId === 4) {
      return (
        <span className="tooltip-role">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserPen.svg"
            alt="View & Edit"
          />
          <span className="tooltiptext">View & Edit</span>
        </span>
      );
    }
    // client user
    if (roleId === 5) {
      return (
        <span className="tooltip-role">
          <img
            className="helpTextStyles helpTextStylesImg"
            src="../images/UserPen-Slash.svg"
            alt="View Only"
          />
          <span className="tooltiptext">View Only</span>
        </span>
      );
    }

    return "";
  };

  const getCardContent = (roleName: string, clientName: string) => {
    const returnJSX = () => {
      return (
        <>
          <span className="roleClientName">{roleName}</span> (
          <span>{clientName}</span>)
        </>
      );
    };
    const returnRoleName = () => {
      return <span className="roleName">{roleName}</span>;
    };
    let content: any = "";
    if (clientName) {
      content = returnJSX();
    } else {
      content = returnRoleName();
    }
    return content;
  };
  return (
    <Wrapper className="d-flex flex-column w-100">
      <ProfileWrapper>
        <div className="d-lg-flex mt-4 m-sm-2 pl-lg-3 p-sm-2">
          <div className="container flex-grow-1 pl-3">
            <div className="d-sm-flex user-mob">
              <div className="user-style">
                <div className="d-flex justify-content-evenly align-items-center">
                  <img
                    src={userProfile.photoURL}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/images/avatar.jpg";
                    }}
                    alt="User"
                  />
                  <h1
                    className="font-600 font18 pt-2 d-block d-sm-none"
                    data-testid="test-heading"
                  >
                    {`${userProfile.firstName} ${userProfile.lastName}`}
                  </h1>
                </div>
              </div>
              <div className="pl-4">
                <h1
                  className="font-600 font18 pt-2 d-none d-sm-block"
                  data-testid="test-heading-sm"
                >
                  {`${userProfile.firstName} ${userProfile.lastName}`}
                </h1>
                <p className="pt-3 pt-sm-4 pt-md-5">
                  <img src="/images/locations.svg" alt="Location" />
                  <span className="pl-3 font14">
                    {userProfile.countryofResidence}
                  </span>
                </p>
                <div className="d-lg-flex d-sm-block">
                  <p>
                    <img src="/images/phone.svg" alt="Phone" />
                    <span className="pl-2 font14">
                      {userProfile.phoneNumber}
                    </span>
                  </p>
                  <p className="pl-lg-5">
                    <img src="/images/email.svg" alt="Email" />
                    <span className="pl-3 font14">{userProfile.email}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex mt-5 justify-content-between">
              <h2
                className="font-600 mb-0 font18"
                data-testid="test-roleheading"
              >
                Roles
              </h2>
            </div>
            <div className="role-card-wrapper">
              {users && users.length > 0 && users[0].roles ? (
                users[0].roles.map((role: any) => {
                  const { roleName, clientName, roleId, userRoleStatus } = role;
                  roleCards = (
                    <div
                      key={roleId + clientName}
                      className="col-lg-auto col-md-6 mt-3 role-box h-auto pl-0"
                    >
                      <div
                        className={`d-flex flex-column addRoleBox changeWidth ${
                          userRoleStatus === "ACTIVE"
                            ? "containerSA"
                            : "containerTN"
                        }`}
                      >
                        <div className="helpTextWrapper">
                          <div className="tooltip-role viewOnly">
                            {showPencil(roleName) ? (
                              <>
                                <img
                                  className="helpTextStyles"
                                  alt="ViewOnlyIcon"
                                  src="../images/roleIconViewOnly.svg"
                                />
                                <span className="tooltiptext">View Only</span>
                              </>
                            ) : (
                              <div className="mt-4" />
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 no-gutters pl-0 pr-0 mb-2 roleContainer">
                          <div className="col-2 col-sm-2 col-md-3">
                            <span className="roleText">Role</span>
                          </div>
                          <div className="col-10 col-sm-10 col-md-9 roleSec">
                            <span>:</span>
                            <div
                              className={`${getColorCode(
                                roleId
                              )} roleStylesMin ml-2`}
                            >
                              <p className="cardcontent">
                                {generateHelpIcons(roleId)}
                                {getCardContent(roleName, clientName)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                  return roleCards;
                })
              ) : (
                <div
                  className="aui-block-loader"
                  role="alert"
                  aria-live="assertive"
                  aria-label="Page is Loading"
                />
              )}
            </div>
          </div>
        </div>
      </ProfileWrapper>
    </Wrapper>
  );
};
export default Profile;
