/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react";
import "./styled-datepicker.css";
import "./styled-datepicker-custom.scss";
import DateRangePicker from "rsuite/esm/DateRangePicker";
import { setHoursMinutesToZero } from "common/utils";
import store from "app/store";
import { setDateRangeFilter } from "pages/TransactionalLogs/Component/common/TransactionalLogs.slice";

const CustDateRangePicker = ({ className }: any) => {
  const { afterToday, allowedMaxDays, combine } = DateRangePicker;

  useEffect(() => {
    // Accessibility fix
    document.querySelector<any>(".rs-picker-toggle-placeholder") &&
      document
        .querySelector<any>(".rs-picker-toggle-placeholder")
        .removeAttribute("aria-placeholder");
  }, []);

  return (
    <>
      <label
        className="mb-0 date-range-label"
        htmlFor="datetimerangepicker-input"
      >
        Date Range
      </label>
      <DateRangePicker
        id="datetimerangepicker-input"
        appearance="default"
        placeholder="Select Date Range"
        format="yyyy-MM-dd"
        /* showMeridian */
        data-testid="daterange"
        className={className || ""}
        style={{ color: "Red", display: "block" }}
        onChange={(dates) => {
          if (dates !== null) {
            store.dispatch(
              setDateRangeFilter({
                /* Converting to ISO time from local time which user selects in date range picker 
                Start date - user will select from calendar
                Start time : reset to midnight 12 of that selected date
                End date - user will select from calendar
                End time - current time during selection
                */
                startDateRange: new Date(
                  setHoursMinutesToZero(dates[0])
                ).toISOString(),
                endDateRange: new Date(
                  dates[1].setMilliseconds(0)
                ).toISOString(),
              })
            );
          }
        }}
        onClean={() => {
          store.dispatch(
            setDateRangeFilter({
              startDateRange: "",
              endDateRange: "",
            })
          );
        }}
        onOpen={() => {
          /* Accessibility fix */
          document.querySelector<any>(
            ".rs-picker-daterange-menu.rs-picker-menu"
          ) &&
            document
              .querySelector<any>(".rs-picker-daterange-menu.rs-picker-menu")
              .setAttribute("aria-label", "Datepicker Dialog");
        }}
        /* disabled={props.disabled ?? false}
      defaultValue={props.defaultValue || []} */
        /* shouldDisableDate={afterToday && afterToday()} */
        shouldDisableDate={
          combine &&
          allowedMaxDays &&
          afterToday &&
          combine(allowedMaxDays(90), afterToday())
        }
        editable={false}
        /* defaultValue={[
          new Date(new Date().setDate(new Date().getDate() - 60)),
          new Date(),
        ]} */
      />
    </>
  );
};

export default CustDateRangePicker;
