import styled from "styled-components";

export const BarGraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 900px;
  padding-left: 10px;
  padding-bottom: 20px;
  background-color: rgba(73, 144, 38, 0.05);
  .font-change {
    color: "#222328";
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    font-family: Montserrat;
  }
  .user-bar {
    height: 400px !important;
  }
`;
