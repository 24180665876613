/* eslint-disable no-debugger */
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  TransactionalLogFilter,
  TransactionalLogList,
} from "services/api/transactionalLog.api";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { getErrorMessage, getParamsFromURL } from "common/utils";
import { useDispatch, useSelector } from "react-redux";
import TransactionalLogsFilter from "./Component/TransactionalLogsFilter";
import TransactionalLogsTable from "./Component/TransactionalLogsTable";
import {
  setClearFilter,
  setPagination,
  setSortByFilter,
  setTransLogDataLength,
  setUrlParamsFilter,
} from "./Component/common/TransactionalLogs.slice";
import { TransactionalLogsStyled } from "./styled";
import TransactionalLogsFilterModal from "./Component/TransactionalLogsModal/TransactionalLogsFilterModal";

export interface TransactionalLogData {
  id: number;
  transactionId: string;
  consumer: string;
  eventType: string;
  entityName: string;
  status: string;
  source: string;
  clientId: string;
  errorCode: string;
  createdAt: string;
  checked?: boolean;
  isPassThrough?: boolean;
}

interface IcolumnHeaders {
  name?: string;
  id?: number;
  sortable?: boolean;
  clsName?: string;
  filterSupport?: boolean;
  filterCode?: string;
  optionalColumn?: boolean;
  defaultSort?: string;
  optionalColumnClass?: string;
  isChecked?: boolean;
}

const TransactionalLogsFinal = () => {
  //   Table Data Use state values
  const [transactionalData, setTransactionalData] = useState<
    TransactionalLogData[]
  >([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [resentCount, setResentCount] = useState<number>(0);
  const [isTableLoading, setTableLoading] = useState<boolean>(false);

  const transactionalLogsFilter: any = useSelector(
    (state: any) => state?.transactionalLog?.transactionalLogFilter
  );

  // Select All Functionality
  const [selectAll, setSelectAll] = useState<boolean>(false);

  // REDUX Declarations
  const dispatch = useDispatch();
  const paginationDataStore = useSelector((state: any) => {
    return state?.transactionalLog?.pagination;
  });

  const pageSizeCursorDataStore = useSelector((state: any) => {
    return state.transactionalLog?.cursorpagination?.pageSize;
  });
  const checkfilter = useSelector(
    (state: any) => state?.transactionalLog?.checkfilter
  );

  // Toggle checkbox
  const toggleSelectAll = useCallback(
    (select: any) => {
      const updatedRowData = transactionalData.map(
        (row: TransactionalLogData) => ({
          ...row,
          checked: select,
        })
      );
      if (select) {
        setResentCount(updatedRowData.length);
      } else {
        setResentCount(0);
      }
      setTransactionalData(updatedRowData);
    },
    [transactionalData]
  );

  const filterSecRef = useRef<any>();

  const toggleRow = useCallback(
    (id: any) => {
      for (let i = 0; i < transactionalData.length; i += 1) {
        if (transactionalData[i].id === id) {
          if (!transactionalData[i].checked === false) {
            setResentCount(resentCount - 1);
          } else {
            setResentCount(resentCount + 1);
          }
          setTransactionalData([
            ...transactionalData.slice(0, i),
            {
              ...transactionalData[i],
              checked: !transactionalData[i].checked,
            },
            ...transactionalData.slice(i + 1),
          ]);
          break;
        }
      }
    },
    [transactionalData, resentCount]
  );

  // Transactional Filter API Call
  const [entityNameArr, setEntityNameArr] = useState<any>([]);
  const [sourceArr, setSourceArr] = useState<any>([]);
  const [statusArr, setStatusArr] = useState<any>([]);
  const [consumerArr, setConsumerArr] = useState<any>([]);
  const getDistinctFilterList = () => {
    const p1 = TransactionalLogFilter("entityName");
    const p2 = TransactionalLogFilter("source");
    const p3 = TransactionalLogFilter("status");
    const p4 = TransactionalLogFilter("consumer");

    Promise.all([p1, p2, p3, p4])
      .then((response) => {
        setEntityNameArr(
          (response[0]?.data?.transactionLogFilters?.entityName).filter(
            (item: any) => item !== "UNMARSHALLING ERROR"
          )
        );
        setSourceArr(
          (response[1]?.data?.transactionLogFilters?.source).filter(
            (item: any) => item
          )
        );
        setStatusArr(
          (response[2]?.data?.transactionLogFilters?.status).filter(
            (item: any) => item
          )
        );

        setConsumerArr(
          (response[3]?.data?.transactionLogFilters?.consumer).filter(
            (item: any) => item
          )
        );
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occurred",
            message: getErrorMessage(error),
          })
        );
      });
  };

  useEffect(() => {
    getDistinctFilterList();
  }, []);

  // Transactional Logs API Call
  const getTransactionalLogs = async (filterParams?: any) => {
    setTableLoading(true);
    let resData: any = {};

    await TransactionalLogList(filterParams)
      .then((response) => {
        resData = response;

        const paginationRes = { ...response._pagination };
        setTransactionalData(response.data.transactionLogs);
        store.dispatch(
          setTransLogDataLength(response.data?.transactionLogs?.length)
        );
        dispatch(setPagination(paginationRes));
        setResentCount(0);
        setSelectAll(false);
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occurred",
            message: getErrorMessage(error),
          })
        );
      })
      .finally(() => {
        setTableLoading(false);
        return resData;
      });
  };

  // Customize Table Columns
  const [columnHeaders, setColumnHeaders] = useState<IcolumnHeaders[]>([
    {
      name: "Entity Name",
      id: 1,
      clsName: "entityNameTableHeader",
      sortable: false,
      filterSupport: true,
      filterCode: "entityName",
      optionalColumn: false,
      isChecked: true,
    },
    {
      name: "Transaction ID",
      id: 7,
      clsName: "transIDTableHeader",
      sortable: false,
      filterSupport: false,
      filterCode: "id",
      optionalColumn: false,
      optionalColumnClass: "optionalColumnTransactionID",
      isChecked: true,
    },
    {
      name: "Source",
      id: 2,
      clsName: "sourceTableHeader",
      sortable: false,
      filterSupport: true,
      filterCode: "source",
      optionalColumn: false,
      isChecked: true,
    },
    {
      name: "Status",
      id: 3,
      clsName: "statusTableHeader",
      sortable: false,
      filterSupport: true,
      filterCode: "status",
      optionalColumn: false,
      isChecked: true,
    },
    {
      name: "Consumer",
      id: 4,
      clsName: "consumerTableHeader",
      sortable: false,
      filterSupport: true,
      filterCode: "consumer",
      optionalColumn: false,
      isChecked: true,
    },
    {
      name: "Created Date",
      id: 6,
      clsName: "createdAtTableHeader",
      sortable: true,
      filterSupport: false,
      filterCode: "createdAt",
      optionalColumn: false,
      defaultSort: "DESC",
      isChecked: true,
      optionalColumnClass: "optionalColumnCreatedDate",
    },
    {
      name: "Error Code",
      id: 8,
      clsName: "",
      sortable: false,
      filterSupport: false,
      filterCode: "errorCode",
      optionalColumn: true,
      optionalColumnClass: "optionalColumnErrorCode",
      isChecked: false,
    },
    {
      name: "Event Type",
      id: 9,
      clsName: "",
      sortable: false,
      filterSupport: false,
      filterCode: "eventType",
      optionalColumn: true,
      optionalColumnClass: "optionalColumnEventType",
      isChecked: false,
    },
  ]);
  const toggleOptionalColumns = (index: any, checkedStatus: any) => {
    const clonedColumns = [...columnHeaders];
    clonedColumns[index].isChecked = !clonedColumns[index].isChecked;
    setColumnHeaders(clonedColumns);
  };

  const toggleCustContentPopover = useCallback((event: any) => {
    event.currentTarget.nextSibling?.classList.toggle("d-none");
    event.currentTarget.classList.toggle("filterSecOpen");
    if (event.currentTarget.nextSibling.classList.contains("d-none")) {
      event.currentTarget.setAttribute("aria-expanded", "false");
    } else {
      event.currentTarget.setAttribute("aria-expanded", "true");
    }
  }, []);

  const closeFilterSecOnESC = (evt: any) => {
    if (evt.code === "Escape") {
      filterSecRef?.current?.classList.add("d-none");
      filterSecRef?.current.previousSibling.focus();
    }
  };

  const generateCustomizeTableContent = useCallback(
    (columnHeaderObj: any, mobile: any) => {
      return columnHeaderObj?.map((item: any, index: any) => {
        return item.name.length > 0 ? (
          <div
            key={`custTable_${item.name.replace(" ", "_")}_${
              mobile ? "mob" : "des"
            }`}
            className="form-group form-check mb-0"
          >
            <input
              type="checkbox"
              value={item.filterCode}
              id={`custTable_${item.name.replace(" ", "_")}_${index}_${
                mobile ? "mob" : "des"
              }_${index}`}
              defaultChecked={item.isChecked}
              disabled={!item.optionalColumn}
              onChange={(e) => {
                toggleOptionalColumns(index, e.currentTarget.checked);
              }}
            />
            <label
              className="labelHeading"
              htmlFor={`custTable_${item.name.replace(" ", "_")}_${index}_${
                mobile ? "mob" : "des"
              }_${index}`}
            >
              {item.name}
            </label>
          </div>
        ) : null;
      });
    },
    [columnHeaders]
  );

  // All Use Effect below this.
  // Sort Functionality
  const pageOrder = useSelector((state: any) => {
    return state.transactionalLog.pagination.pageOrder;
  });
  const onChangePageOrder = useCallback(() => {
    if (pageOrder === "DESC") {
      store.dispatch(setSortByFilter("ASC"));
    } else {
      store.dispatch(setSortByFilter("DESC"));
    }
  }, [pageOrder]);

  // Get Params from LocalStorage for Automatic Email Task
  const [urlParams] = useState<any>(getParamsFromURL()?.params);

  useEffect(() => {
    if (urlParams) {
      store.dispatch(
        setUrlParamsFilter({
          ...urlParams,
          startDate: urlParams?.startDate,
          endDate: urlParams?.endDate,
        })
      );
    }
    return () => {
      localStorage.removeItem("TransLogAutoFillPath");
      localStorage.removeItem("TransLogAutoFillParams");
      localStorage.removeItem("RedirectUrlParams");

      store.dispatch(setClearFilter());
    };
  }, [urlParams]);

  useEffect(() => {
    // DO NOT ADD ANY STATE UPDATE AFTER THIS CALL
    getTransactionalLogs({
      ...transactionalLogsFilter,
      cursor: paginationDataStore.cursor,
      pageSize: pageSizeCursorDataStore || 50,
      pageOrder: paginationDataStore.pageOrder,
    });
  }, [
    transactionalLogsFilter,
    refresh,
    paginationDataStore.cursor,
    pageSizeCursorDataStore,
    paginationDataStore.pageOrder,
  ]);

  useEffect(() => {
    // add event listener- Accessibility fix
    document
      .querySelector<any>(".custTableWrapper")
      .addEventListener("keyup", closeFilterSecOnESC, false);
    return () =>
      /* cleanup */
      document
        .querySelector<any>(".custTableWrapper")
        .removeEventListener("keyup", closeFilterSecOnESC);
  }, []);

  // Mobile Filter
  const [toggleModal, setToggleModal] = useState(false);

  // Reset UseState
  const [reset, setReset] = useState<any>(false);

  return (
    <TransactionalLogsStyled className="d-lg-flex mt-4">
      <div className="container transactional-logs-main">
        <div className="d-flex flex-grow-1 justify-content-between mt-3 heading-border">
          <h1
            className="proj-heading font-400"
            aria-label="Transactional Logs"
            data-testid="test-projects"
          >
            Transactional Logs
          </h1>
        </div>
        <TransactionalLogsFilter
          transactionalData={transactionalData}
          resentCount={resentCount}
          refresh={refresh}
          setRefresh={setRefresh}
          toggleSelectAll={toggleSelectAll}
          setSelectAll={setSelectAll}
          selectAll={selectAll}
          onChangePageOrder={onChangePageOrder}
          columnHeaders={columnHeaders}
          generateCustomizeTableContent={generateCustomizeTableContent}
          toggleCustContentPopover={toggleCustContentPopover}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          reset={reset}
          setReset={setReset}
          ref={filterSecRef}
          entityNameArr={entityNameArr}
          sourceArr={sourceArr}
          statusArr={statusArr}
          consumerArr={consumerArr}
        />
        <TransactionalLogsTable
          isTableLoading={isTableLoading}
          transactionalData={transactionalData}
          transactionalHeaderData={columnHeaders}
          checkfilter={checkfilter}
          pagination={paginationDataStore}
          toggleSelectAll={toggleSelectAll}
          setSelectAll={setSelectAll}
          selectAll={selectAll}
          toggleRow={toggleRow}
          reset={reset}
          setReset={setReset}
        />
      </div>
      <TransactionalLogsFilterModal
        entityNameArr={entityNameArr}
        sourceArr={sourceArr}
        statusArr={statusArr}
      />
    </TransactionalLogsStyled>
  );
};

export default TransactionalLogsFinal;
