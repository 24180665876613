import React, { ReactNode, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AdminFooter from "components/AdminFooter";
import { Wrapper } from "styled";
import Loader from "components/Loader";
import ScrollToTop from "components/scrollToTop";
import Toast from "components/Toast";
import { AHAMenu } from "components/SideMenu/AHASideMenu";
import Header from "components/Header";
import LoginHeader from "pages/Login/LoginHeader";
import { useSelector } from "react-redux";
import RouteLinks from "common/routePaths";
import { getSideMenuToggleStatus } from "common/utils";
import SnapshotHeader from "components/SnapshotHeader";
import { logger } from "common/utils/logger.utils";

interface Props {
  children: ReactNode;
}
const pathName = window.location.pathname;

export default function AdminLayout({ children }: Props) {
  const pathNameExpiry = useSelector((state: any) => {
    if (state.user.expiryPath) {
      return state.user.expiryPath;
    }

    return "";
  });

  const userStateAuthed = useSelector((state: any) => {
    return state?.authed?.userToken;
  });

  RouteLinks.routePaths.forEach((routeItem: any) => {
    if (window.location.pathname === routeItem.path) {
      document.title = `American Heart Association | Data Hub - ${routeItem.pageTitle} Page`;
    }
  });

  useEffect(() => {
    const removeOverlay = () => {
      document
        .querySelector<any>(".modal-backdrop")
        ?.classList.remove("d-none");

      document.querySelector<any>(".modal-backdrop")?.classList.remove("show");

      document.querySelector<any>("body").classList.remove("modal-open");
      document.querySelector<any>("body").setAttribute("style", "");
    };
    window.addEventListener("popstate", () => removeOverlay(), false);

    // cleanup
    return () => window.removeEventListener("popstate", () => removeOverlay());
  }, []);
  const [buttonClick, setButtonClick] = useState(false);

  useEffect(() => {
    try {
      if (window.outerWidth > 992) {
        if (getSideMenuToggleStatus() === "true") {
          setButtonClick(true);
        } else {
          setButtonClick(false);
        }
      }
    } catch (error) {
      logger("error response in admin Layout mount : ", error);
    }
  }, [buttonClick]);

  const snapshotDemoPageRoute = () => {
    if (window.location.pathname === "/snapshotdemo")
      return (
        <SnapshotHeader
          buttonClick={buttonClick}
          setButtonClick={setButtonClick}
        />
      );

    return userStateAuthed !== "" ? (
      <Header buttonClick={buttonClick} setButtonClick={setButtonClick} />
    ) : (
      <LoginHeader />
    );
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <a href="#maincontent" className="aui-skip-content">
        Skip to main content
      </a>
      <div className="d-flex flex-row sideMenuMobPosRelative flex-fill">
        {userStateAuthed !== "" ? (
          <AHAMenu buttonClick={buttonClick} setButtonClick={setButtonClick} />
        ) : null}
        <Wrapper
          buttonClick={buttonClick}
          notFoundWrapperFlag={userStateAuthed === ""}
          className="container d-flex flex-column padding-left-0"
        >
          {pathName === pathNameExpiry ? (
            <LoginHeader />
          ) : (
            snapshotDemoPageRoute()
          )}
          <ScrollToTop
            buttonClick={buttonClick}
            setButtonClick={setButtonClick}
          />
          <Toast />
          <main
            className="d-flex flex-column flex-grow-1 bgcolor"
            id="maincontent"
          >
            <div className="mt-4 m-sm-2 pl-lg-1 p-sm-2 maxscreen">
              {children}
            </div>
          </main>
          <Loader />
        </Wrapper>
      </div>
      <AdminFooter
        buttonClick={buttonClick}
        isLoginPage={userStateAuthed === ""}
      />
    </div>
  );
}
AdminLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
