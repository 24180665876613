import Login from "pages/Login";

import UsersComp from "pages/Users/Users";

import Profile from "pages/Profile/Profile";
import AccessDenied from "pages/AccessDenied";
import InviteUser from "pages/InviteUser/Invite";
import ViewUserDetailsComp from "pages/ViewUserDetails";
import ClientListComp from "pages/Clients/Clients";
import ViewClient from "pages/ViewClient";
import NotificationsSetting from "pages/Notifications/Settings";
import NotificationsList from "pages/Notifications/List";
import SnapshotPage from "pages/SnapshotDemo";
import ServiceProvider from "pages/Notifications/ServiceProvider";
import Dashboard from "../pages/Dashboard/Dashboard";
import Location from "../pages/Location/Location";

import UserRolesComp from "../pages/UserRoles/UserRoles";
import CredentialsComp from "../pages/Credentials/Credentials";
import TransactionalComp from "../pages/TransactionalLogs/TransactionalLogs";
import TransactionalDetailsComp from "../pages/TransactionalLogDetail/TransactionalLogDetail";
import Menu from "../pages/Menu/Menu";
import JsonSchema from "../pages/ViewJsonSchema/ViewJsonSchema";
import Invite from "../pages/Invite/Invite";
import pageNotFoundComp from "../pages/PageNotFound";
import CONSTANTS from "./constants";

/* roles option if :
 not added - then route will be available for all roles .
 added - then that route will be available only for roles mentioned in the array 
*/

const RouteLinks = {
  routePaths: [
    {
      path: "/",
      pageTitle: "Login",
      component: Login,
      adminRoute: false,
      hideHeaderAndFooter: false,
    },
    {
      path: "/dashboard",
      pageTitle: "Dashboard",
      component: Dashboard,
      adminRoute: true,
      hideHeaderAndFooter: false,
    },
    {
      path: "/location",
      pageTitle: "Location",
      component: Location,
      adminRoute: true,
      hideHeaderAndFooter: true,
    },

    {
      path: "/clients",
      pageTitle: "Clients",
      component: ClientListComp,
      adminRoute: true,
      hideHeaderAndFooter: false,
      roles: [
        CONSTANTS.USER_ROLES.SUPER_ADMIN,
        CONSTANTS.USER_ROLES.SUPER_USER,
      ],
    },

    {
      path: "/users",
      pageTitle: "Users",
      component: UsersComp,
      adminRoute: true,
      hideHeaderAndFooter: false,
    },
    {
      path: "/users/invite/:id",
      pageTitle: "Invite User",
      component: InviteUser,
      adminRoute: true,
      hideHeaderAndFooter: false,
    },
    {
      path: "/profile",
      pageTitle: "Profile",
      component: Profile,
      adminRoute: true,
      hideHeaderAndFooter: false,
    },
    {
      path: "/userRoles",
      pageTitle: "User Role",
      component: UserRolesComp,
      adminRoute: true,
      hideHeaderAndFooter: true,
    },
    {
      path: "/accessdenied",
      pageTitle: "Access Denied",
      component: AccessDenied,
      adminRoute: true,
      hideHeaderAndFooter: true,
    },
    {
      path: "/transactionLogs/:id/:type",
      pageTitle: "Transactional Log Details",
      component: TransactionalDetailsComp,
      adminRoute: true,
      hideHeaderAndFooter: false,
    },
    {
      path: "/transactionLogs",
      pageTitle: "Transactional Logs",
      component: TransactionalComp,
      adminRoute: true,
      hideHeaderAndFooter: false,
    },
    {
      path: "/credentials",
      pageTitle: "Credentials",
      component: CredentialsComp,
      adminRoute: true,
      hideHeaderAndFooter: false,
      roles: [
        CONSTANTS.USER_ROLES.SUPER_ADMIN,
        CONSTANTS.USER_ROLES.CLIENT_ADMIN,
      ],
    },
    {
      path: "/users/:id",
      pageTitle: "User Details",
      component: ViewUserDetailsComp,
      adminRoute: true,
      hideHeaderAndFooter: false,
    },
    {
      path: "/settings",
      pageTitle: "Menu Settings",
      component: Menu,
      adminRoute: true,
      hideHeaderAndFooter: false,
      roles: [CONSTANTS.USER_ROLES.SUPER_ADMIN],
    },
    {
      path: "/settings/:code",
      pageTitle: "Json Schema",
      component: JsonSchema,
      adminRoute: true,
      hideHeaderAndFooter: true,
      roles: [CONSTANTS.USER_ROLES.SUPER_ADMIN],
    },
    {
      path: "/clients/:id",
      pageTitle: "Client Details",
      component: ViewClient,
      adminRoute: true,
      hideHeaderAndFooter: false,
      roles: [
        CONSTANTS.USER_ROLES.SUPER_ADMIN,
        CONSTANTS.USER_ROLES.SUPER_USER,
      ],
    },
    {
      path: "/notifications/settings",
      pageTitle: "Notification Settings",
      component: NotificationsSetting,
      adminRoute: true,
      hideHeaderAndFooter: false,
      roles: [CONSTANTS.USER_ROLES.CLIENT_ADMIN],
    },
    {
      path: "/invite",
      pageTitle: "Invite",
      component: Invite,
      adminRoute: false,
      hideHeaderAndFooter: true,
    },
    {
      path: "/notifications",
      pageTitle: "Notification",
      component: NotificationsList,
      adminRoute: true,
      hideHeaderAndFooter: false,
      roles: [CONSTANTS.USER_ROLES.SUPER_ADMIN],
    },
    {
      path: "/serviceProviders",
      pageTitle: "Service Provider",
      component: ServiceProvider,
      adminRoute: true,
      hideHeaderAndFooter: false,
      roles: [CONSTANTS.USER_ROLES.SUPER_ADMIN],
    },
    /* {
      path: "/snapshotdemo",
      pageTitle: "Snapshot SDK",
      component: SnapshotPage,
      adminRoute: true,
      hideHeaderAndFooter: false,
    }, */

    /* Rollback to NotFound Page in case of any invalid url - post login */

    {
      path: "/NotFound",
      pageTitle: "Page Not Found",
      component: pageNotFoundComp,
      adminRoute: true,
      hideHeaderAndFooter: false,
    },
    {
      pageTitle: "Page Not Found",
      component: pageNotFoundComp,
      adminRoute: true,
      hideHeaderAndFooter: false,
    },
  ],
};
export default RouteLinks;
